@charset "UTF-8";
@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap);

@font-face {
    font-family: "Noto Sans Display";
    font-style: normal;
    font-weight: 400;
    src: url(https://unpkg.com/wmrar-design-system@latest/build/fonts/notosansdisplay-regular-webfont.woff2) format("woff2"), url(https://unpkg.com/wmrar-design-system@latest/build/fonts/notosansdisplay-regular-webfont.woff) format("woff")
}

@font-face {
    font-family: "Noto Sans Display";
    font-style: normal;
    font-weight: 700;
    src: url(https://unpkg.com/wmrar-design-system@latest/build/fonts/notosansdisplay-bold-webfont.woff2) format("woff2"), url(https://unpkg.com/wmrar-design-system@latest/build/fonts/notosansdisplay-bold-webfont.woff) format("woff")
}

@font-face {
    font-family: "Noto Sans Display";
    font-style: italic;
    font-weight: 400;
    src: url(https://unpkg.com/wmrar-design-system@latest/build/fonts/notosansdisplay-italic-webfont.woff2) format("woff2"), url(https://unpkg.com/wmrar-design-system@latest/build/fonts/notosansdisplay-italic-webfont.woff) format("woff")
}

@font-face {
    font-family: "Noto Sans Display";
    font-style: italic;
    font-weight: 700;
    src: url(https://unpkg.com/wmrar-design-system@latest/build/fonts/notosansdisplay-bolditalic-webfont.woff2) format("woff2"), url(https://unpkg.com/wmrar-design-system@latest/build/fonts/notosansdisplay-bolditalic-webfont.woff) format("woff")
}

.wmrards-html {
    min-width: 320px;
    margin: 0;
    padding: 0;
    overflow-y: auto;
    color: #080607;
    background: #f3f2f1;
    font-family: "Noto Sans Display", sans-serif;
    font-size: 1rem;
    line-height: 1.5rem;
    scroll-behavior: smooth
}

.wmrards-html .wmrards-n-icon__svg {
    width: .75rem;
    height: calc(.7855635925rem)
}

.wmrards-html body {
    margin: 0;
    padding: 0;
    color: #080607;
    font-family: "Noto Sans Display", sans-serif;
    font-size: 1rem;
    line-height: 1.5rem
}

.wmrards-html body .wmrards-n-icon__svg {
    width: .75rem;
    height: calc(.7855635925rem)
}

[class*=wmrards-] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #080607;
    font-family: "Noto Sans Display", sans-serif;
    font-size: 1rem;
    line-height: 1.5rem
}

[class*=wmrards-] .wmrards-n-icon__svg {
    width: .75rem;
    height: calc(.7855635925rem)
}

[class*=wmrards-] img {
    max-width: 100%;
    height: auto
}

[class*=wmrards-] ::-moz-selection {
    color: #fff;
    background: #841922
}

[class*=wmrards-] ::selection {
    color: #fff;
    background: #841922
}

[class*=wmrards-] :focus {
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px #fff, 0 0 0 4px #e5272d;
    box-shadow: 0 0 0 2px #fff, 0 0 0 4px #e5272d
}

[class*=wmrards-] ::-moz-focus-inner {
    border: 0
}

[class*=wmrards-] a,
[class*=wmrards-] div,
[class*=wmrards-] p,
[class*=wmrards-] span {
    font-size: 1rem;
    line-height: 1.5rem
}

[class*=wmrards-] a .wmrards-n-icon__svg,
[class*=wmrards-] div .wmrards-n-icon__svg,
[class*=wmrards-] p .wmrards-n-icon__svg,
[class*=wmrards-] span .wmrards-n-icon__svg {
    width: .75rem;
    height: calc(.7855635925rem)
}

[class*=wmrards-] blockquote,
[class*=wmrards-] p,
[class*=wmrards-] pre,
[class*=wmrards-] table {
    margin-top: 0;
    margin-bottom: 1.5rem
}

[class*=wmrards-] sub,
[class*=wmrards-] sup {
    position: relative;
    line-height: 0;
    vertical-align: baseline
}

[class*=wmrards-] sup {
    top: -.5em
}

[class*=wmrards-] sub {
    bottom: -.25em
}

[class*=wmrards-] h1,
[class*=wmrards-] h1[class*=wmrards-col],
[class*=wmrards-] h2,
[class*=wmrards-] h2[class*=wmrards-col],
[class*=wmrards-] h3,
[class*=wmrards-] h3[class*=wmrards-col],
[class*=wmrards-] h4,
[class*=wmrards-] h4[class*=wmrards-col] {
    font-family: "DM Sans", sans-serif
}

[class*=wmrards-] h1,
[class*=wmrards-].wmrards-h1 {
    color: #841922;
    margin-top: 2.55rem;
    margin-bottom: 1.7rem;
    font-size: 2.44375rem;
    line-height: 2.55rem
}

[class*=wmrards-] h1 .wmrards-n-icon__svg,
[class*=wmrards-].wmrards-h1 .wmrards-n-icon__svg {
    width: 1.8328125rem;
    height: calc(1.9197210292rem)
}

@media all and (min-width:768px) {

    [class*=wmrards-] h1,
    [class*=wmrards-].wmrards-h1 {
        margin-top: 3rem;
        margin-bottom: 2rem;
        font-size: 2.875rem;
        line-height: 3rem
    }

    [class*=wmrards-] h1 .wmrards-n-icon__svg,
    [class*=wmrards-].wmrards-h1 .wmrards-n-icon__svg {
        width: 2.15625rem;
        height: calc(2.2584953285rem)
    }
}

[class*=wmrards-] h2,
[class*=wmrards-].wmrards-h2 {
    color: #841922;
    margin-top: 2.55rem;
    margin-bottom: .85rem;
    font-size: 1.7rem;
    line-height: 1.89125rem;
    font-weight: 700
}

[class*=wmrards-] h2 .wmrards-n-icon__svg,
[class*=wmrards-].wmrards-h2 .wmrards-n-icon__svg {
    width: 1.275rem;
    height: calc(1.3354581073rem)
}

@media all and (min-width:768px) {

    [class*=wmrards-] h2,
    [class*=wmrards-].wmrards-h2 {
        margin-top: 3rem;
        margin-bottom: 1rem;
        font-size: 2rem;
        line-height: 2.225rem
    }

    [class*=wmrards-] h2 .wmrards-n-icon__svg,
    [class*=wmrards-].wmrards-h2 .wmrards-n-icon__svg {
        width: 1.5rem;
        height: calc(1.5711271851rem)
    }
}

[class*=wmrards-] h3,
[class*=wmrards-].wmrards-h3 {
    color: inherit;
    margin-top: 2rem;
    margin-bottom: .75rem;
    font-size: 1.4375rem;
    line-height: 1.5rem;
    font-weight: 700
}

[class*=wmrards-] h3 .wmrards-n-icon__svg,
[class*=wmrards-].wmrards-h3 .wmrards-n-icon__svg {
    width: 1.078125rem;
    height: calc(1.1292476643rem)
}

[class*=wmrards-] h4,
[class*=wmrards-].wmrards-h4 {
    color: inherit;
    margin-top: 1.5rem;
    margin-bottom: .5rem;
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: 700
}

[class*=wmrards-] h4 .wmrards-n-icon__svg,
[class*=wmrards-].wmrards-h4 .wmrards-n-icon__svg {
    width: .84375rem;
    height: calc(.8837590416rem)
}

[class*=wmrards-] h4.wmrards-weight-400,
[class*=wmrards-].wmrards-h4.wmrards-weight-400 {
    font-weight: 400
}

[class*=wmrards-] h5,
[class*=wmrards-].wmrards-h5 {
    margin-top: 1.5rem;
    margin-bottom: .5rem;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 700
}

[class*=wmrards-] h5 .wmrards-n-icon__svg,
[class*=wmrards-].wmrards-h5 .wmrards-n-icon__svg {
    width: .75rem;
    height: calc(.7855635925rem)
}

[class*=wmrards-] h6,
[class*=wmrards-].wmrards-h6 {
    margin-top: 1.5rem;
    margin-bottom: .5rem;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400
}

[class*=wmrards-] h6 .wmrards-n-icon__svg,
[class*=wmrards-].wmrards-h6 .wmrards-n-icon__svg {
    width: .75rem;
    height: calc(.7855635925rem)
}

[class*=wmrards-].wmrards-h2>*,
[class*=wmrards-].wmrards-h3>*,
[class*=wmrards-].wmrards-h4>*,
[class*=wmrards-].wmrards-h5>* {
    color: initial;
    font-weight: initial
}

.wmrards-h1[class*=wmrards-],
.wmrards-h2[class*=wmrards-],
.wmrards-h3[class*=wmrards-],
.wmrards-h4[class*=wmrards-] {
    font-family: "DM Sans", sans-serif
}

* h1:first-child,
* h1:first-child[class*=wmrards-],
* h2:first-child,
* h2:first-child[class*=wmrards-],
* h3:first-child,
* h3:first-child[class*=wmrards-],
* h4:first-child,
* h4:first-child[class*=wmrards-] {
    margin-top: 0
}

[class*=wmrards-] ol:not([class*=wmrards-]),
[class*=wmrards-].wmrards-ordered-list {
    position: relative;
    counter-reset: item;
    -webkit-padding-start: 2rem;
    padding-inline-start: 2rem
}

[class*=wmrards-] ol:not([class*=wmrards-]) .wmrards-link,
[class*=wmrards-] ol:not([class*=wmrards-]) a,
[class*=wmrards-].wmrards-ordered-list .wmrards-link,
[class*=wmrards-].wmrards-ordered-list a {
    display: inline
}

[class*=wmrards-] ol:not([class*=wmrards-]) ol,
[class*=wmrards-] ol:not([class*=wmrards-]) ul:not(.wmrards-unordered-list),
[class*=wmrards-].wmrards-ordered-list ol,
[class*=wmrards-].wmrards-ordered-list ul:not(.wmrards-unordered-list) {
    position: relative;
    margin-top: .5rem;
    counter-reset: item;
    -webkit-padding-start: 2rem;
    padding-inline-start: 2rem
}

[class*=wmrards-] ol:not([class*=wmrards-])>li:not(li[class]),
[class*=wmrards-].wmrards-ordered-list>li:not(li[class]) {
    font-family: "Noto Sans Display", sans-serif;
    font-size: 1rem;
    line-height: 1.5rem
}

[class*=wmrards-] ol:not([class*=wmrards-]) ol>li,
[class*=wmrards-] ol:not([class*=wmrards-])>li,
[class*=wmrards-].wmrards-ordered-list ol>li,
[class*=wmrards-].wmrards-ordered-list>li {
    display: block;
    position: relative;
    margin-bottom: .5rem
}

[class*=wmrards-] ol:not([class*=wmrards-]) ol>li:before,
[class*=wmrards-] ol:not([class*=wmrards-])>li:before,
[class*=wmrards-].wmrards-ordered-list ol>li:before,
[class*=wmrards-].wmrards-ordered-list>li:before {
    content: counters(item, ".") ".";
    position: absolute;
    left: -3rem;
    width: 2.75rem;
    padding-right: .25rem;
    text-align: right;
    counter-increment: item
}

[class*=wmrards-] ol:not([class*=wmrards-]) ol:not([class*=wmrards-])>li:before,
[class*=wmrards-].wmrards-ordered-list ol:not([class*=wmrards-])>li:before {
    left: -3.4rem;
    width: calc(3.4rem - .25rem)
}

[class*=wmrards-] ol:not([class*=wmrards-]) ol:not([class*=wmrards-])>li>ol:not([class*=wmrards-])>li:before,
[class*=wmrards-].wmrards-ordered-list ol:not([class*=wmrards-])>li>ol:not([class*=wmrards-])>li:before {
    left: -4.3rem;
    width: calc(4.3rem - .25rem)
}

[class*=wmrards-] ol:not([class*=wmrards-]) ol:not([class*=wmrards-])>li>ol:not([class*=wmrards-])>li>ol:not([class*=wmrards-])>li:before,
[class*=wmrards-].wmrards-ordered-list ol:not([class*=wmrards-])>li>ol:not([class*=wmrards-])>li>ol:not([class*=wmrards-])>li:before {
    left: -5.2rem;
    width: calc(5.2rem - .25rem)
}

[class*=wmrards-] ol:not([class*=wmrards-]) ol:not([class*=wmrards-])>li>ol:not([class*=wmrards-])>li>ol:not([class*=wmrards-])>li>ol:not([class*=wmrards-])>li:before,
[class*=wmrards-].wmrards-ordered-list ol:not([class*=wmrards-])>li>ol:not([class*=wmrards-])>li>ol:not([class*=wmrards-])>li>ol:not([class*=wmrards-])>li:before {
    left: -6.1rem;
    width: calc(6.1rem - .25rem)
}

[class*=wmrards-] ol:not([class*=wmrards-]) ol:not([class*=wmrards-])>li>ol:not([class*=wmrards-])>li>ol:not([class*=wmrards-])>li>ol:not([class*=wmrards-])>li>ol:not([class*=wmrards-])>li:before,
[class*=wmrards-].wmrards-ordered-list ol:not([class*=wmrards-])>li>ol:not([class*=wmrards-])>li>ol:not([class*=wmrards-])>li>ol:not([class*=wmrards-])>li>ol:not([class*=wmrards-])>li:before {
    left: -7rem;
    width: calc(7rem - .25rem)
}

[class*=wmrards-] ol:not([class*=wmrards-]) ol:not([class*=wmrards-])>li>ol:not([class*=wmrards-])>li>ol:not([class*=wmrards-])>li>ol:not([class*=wmrards-])>li>ol:not([class*=wmrards-])>li>ol:not([class*=wmrards-])>li:before,
[class*=wmrards-].wmrards-ordered-list ol:not([class*=wmrards-])>li>ol:not([class*=wmrards-])>li>ol:not([class*=wmrards-])>li>ol:not([class*=wmrards-])>li>ol:not([class*=wmrards-])>li>ol:not([class*=wmrards-])>li:before {
    left: -7.9rem;
    width: calc(7.9rem - .25rem)
}

[class*=wmrards-] ul:not([class*=wmrards-]),
[class*=wmrards-].wmrards-unordered-list {
    list-style: none;
    -webkit-padding-start: 2rem;
    padding-inline-start: 2rem
}

[class*=wmrards-] ul:not([class*=wmrards-]) .wmrards-link,
[class*=wmrards-] ul:not([class*=wmrards-]) a,
[class*=wmrards-].wmrards-unordered-list .wmrards-link,
[class*=wmrards-].wmrards-unordered-list a {
    display: inline
}

[class*=wmrards-] ul:not([class*=wmrards-]) ol:not(.wmrards-ordered-list),
[class*=wmrards-] ul:not([class*=wmrards-]) ul,
[class*=wmrards-].wmrards-unordered-list ol:not(.wmrards-ordered-list),
[class*=wmrards-].wmrards-unordered-list ul {
    position: relative;
    margin-top: .5rem;
    counter-reset: item;
    -webkit-padding-start: 2rem;
    padding-inline-start: 2rem
}

[class*=wmrards-] ul:not([class*=wmrards-])>li:not(li[class]),
[class*=wmrards-].wmrards-unordered-list>li:not(li[class]) {
    color: #080607;
    font-family: "Noto Sans Display", sans-serif;
    font-size: 1rem;
    line-height: 1.5rem
}

[class*=wmrards-] ul:not([class*=wmrards-])>li:not(li[class]) .wmrards-n-icon__svg,
[class*=wmrards-].wmrards-unordered-list>li:not(li[class]) .wmrards-n-icon__svg {
    width: .75rem;
    height: calc(.7855635925rem)
}

[class*=wmrards-] ul:not([class*=wmrards-]) ul>li,
[class*=wmrards-] ul:not([class*=wmrards-])>li,
[class*=wmrards-].wmrards-unordered-list ul>li,
[class*=wmrards-].wmrards-unordered-list>li {
    position: relative;
    margin-bottom: .5rem
}

[class*=wmrards-] ul:not([class*=wmrards-]) ul>li:before,
[class*=wmrards-] ul:not([class*=wmrards-])>li:before,
[class*=wmrards-].wmrards-unordered-list ul>li:before,
[class*=wmrards-].wmrards-unordered-list>li:before {
    content: "●";
    position: absolute;
    left: -1.5rem
}

[class*=wmrards-] ul:not([class*=wmrards-]) ul:not([class*=wmrards-]),
[class*=wmrards-].wmrards-unordered-list ul:not([class*=wmrards-]) {
    margin-top: .5rem;
    list-style: none;
    -webkit-padding-start: 2rem;
    padding-inline-start: 2rem
}

[class*=wmrards-] ul:not([class*=wmrards-]) ul:not([class*=wmrards-])>li:before,
[class*=wmrards-].wmrards-unordered-list ul:not([class*=wmrards-])>li:before {
    content: "–"
}

[class*=wmrards-] ul:not([class*=wmrards-]) ol,
[class*=wmrards-].wmrards-unordered-list ol {
    margin-top: .5rem;
    list-style: none;
    -webkit-padding-start: 2rem;
    padding-inline-start: 2rem;
    counter-reset: newitem
}

[class*=wmrards-] ul:not([class*=wmrards-]) ol>li,
[class*=wmrards-].wmrards-unordered-list ol>li {
    display: block;
    position: relative;
    margin-bottom: .5rem
}

[class*=wmrards-] ul:not([class*=wmrards-]) ol>li:before,
[class*=wmrards-].wmrards-unordered-list ol>li:before {
    content: counters(newitem, ".") ".";
    position: absolute;
    left: -3rem;
    width: 2.75rem;
    padding-right: .25rem;
    text-align: right;
    counter-increment: newitem
}

[class*=wmrards-].wmrards-bare-list {
    list-style: none;
    -webkit-padding-start: .25rem;
    padding-inline-start: .25rem
}

[class*=wmrards-].wmrards-bare-list .wmrards-link,
[class*=wmrards-].wmrards-bare-list a {
    display: inline
}

[class*=wmrards-].wmrards-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-height: 100vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch
}

[class*=wmrards-].wmrards-container {
    display: block;
    width: 100%;
    max-width: 992px;
    margin: auto;
    padding: 0 .75rem
}

@media screen and (min-width:992px) {
    [class*=wmrards-].wmrards-container {
        padding: 0 1rem
    }

    [class*=wmrards-].wmrards-footer__link.wmrards-link {
        float: right
    }
}

[class*=wmrards-].wmrards-container--main {
    margin-top: 1rem;
    margin-bottom: 2rem;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1
}

html body [class*=wmrards-].wmrards-grid>* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

html body [class*=wmrards-].wmrards-grid.wmrards-grid--justify-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

html body [class*=wmrards-].wmrards-grid.wmrards-grid--justify-around {
    -ms-flex-pack: distribute;
    justify-content: space-around
}

html body [class*=wmrards-].wmrards-grid.wmrards-grid--justify-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

html body [class*=wmrards-].wmrards-grid.wmrards-grid--align-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

html body [class*=wmrards-].wmrards-text-align-left {
    text-align: left
}

html body [class*=wmrards-].wmrards-text-align-center {
    text-align: center
}

html body [class*=wmrards-].wmrards-text-align-right {
    text-align: right
}

html body [class*=wmrards-].wmrards-float-left {
    float: left
}

html body [class*=wmrards-].wmrards-float-right {
    float: right
}

html body [class*=wmrards-].wmrards-m-xs {
    margin: .25rem
}

html body [class*=wmrards-].wmrards-m-xsm {
    margin: .5rem
}

html body [class*=wmrards-].wmrards-m-sm {
    margin: .75rem
}

html body [class*=wmrards-].wmrards-m-md {
    margin: 1rem
}

html body [class*=wmrards-].wmrards-m-lg {
    margin: 2rem
}

html body [class*=wmrards-].wmrards-m-xl {
    margin: 3rem
}

html body [class*=wmrards-].wmrards-m-none {
    margin: 0 !important
}

html body [class*=wmrards-].wmrards-m-t-xs {
    margin-top: .25rem
}

html body [class*=wmrards-].wmrards-m-t-xsm {
    margin-top: .5rem
}

html body [class*=wmrards-].wmrards-m-t-sm {
    margin-top: .75rem
}

html body [class*=wmrards-].wmrards-m-t-md {
    margin-top: 1rem
}

html body [class*=wmrards-].wmrards-m-t-lg {
    margin-top: 2rem
}

html body [class*=wmrards-].wmrards-m-t-xl {
    margin-top: 3rem
}

html body [class*=wmrards-].wmrards-m-t-none {
    margin-top: 0 !important
}

html body [class*=wmrards-].wmrards-m-r-xs {
    margin-right: .25rem
}

html body [class*=wmrards-].wmrards-m-r-xsm {
    margin-right: .5rem
}

html body [class*=wmrards-].wmrards-m-r-sm {
    margin-right: .75rem
}

html body [class*=wmrards-].wmrards-m-r-md {
    margin-right: 1rem
}

html body [class*=wmrards-].wmrards-m-r-lg {
    margin-right: 2rem
}

html body [class*=wmrards-].wmrards-m-r-xl {
    margin-right: 3rem
}

html body [class*=wmrards-].wmrards-m-r-none {
    margin-right: 0 !important
}

html body [class*=wmrards-].wmrards-m-b-xs {
    margin-bottom: .25rem
}

html body [class*=wmrards-].wmrards-m-b-xsm {
    margin-bottom: .5rem
}

html body [class*=wmrards-].wmrards-m-b-sm {
    margin-bottom: .75rem
}

html body [class*=wmrards-].wmrards-m-b-md {
    margin-bottom: 1rem
}

html body [class*=wmrards-].wmrards-m-b-lg {
    margin-bottom: 2rem
}

html body [class*=wmrards-].wmrards-m-b-xl {
    margin-bottom: 3rem
}

html body [class*=wmrards-].wmrards-m-b-none {
    margin-bottom: 0 !important
}

html body [class*=wmrards-].wmrards-m-l-xs {
    margin-left: .25rem
}

html body [class*=wmrards-].wmrards-m-l-xsm {
    margin-left: .5rem
}

html body [class*=wmrards-].wmrards-m-l-sm {
    margin-left: .75rem
}

html body [class*=wmrards-].wmrards-m-l-md {
    margin-left: 1rem
}

html body [class*=wmrards-].wmrards-m-l-lg {
    margin-left: 2rem
}

html body [class*=wmrards-].wmrards-m-l-xl {
    margin-left: 3rem
}

html body [class*=wmrards-].wmrards-m-l-none {
    margin-left: 0 !important
}

html body [class*=wmrards-].wmrards-p-xs {
    padding: .25rem
}

html body [class*=wmrards-].wmrards-p-xsm {
    padding: .5rem
}

html body [class*=wmrards-].wmrards-p-sm {
    padding: .75rem
}

html body [class*=wmrards-].wmrards-p-md {
    padding: 1rem
}

html body [class*=wmrards-].wmrards-p-lg {
    padding: 2rem
}

html body [class*=wmrards-].wmrards-p-xl {
    padding: 3rem
}

html body [class*=wmrards-].wmrards-p-none {
    padding: 0 !important
}

html body [class*=wmrards-].wmrards-p-t-xs {
    padding-top: .25rem
}

html body [class*=wmrards-].wmrards-p-t-xsm {
    padding-top: .5rem
}

html body [class*=wmrards-].wmrards-p-t-sm {
    padding-top: .75rem
}

html body [class*=wmrards-].wmrards-p-t-md {
    padding-top: 1rem
}

html body [class*=wmrards-].wmrards-p-t-lg {
    padding-top: 2rem
}

html body [class*=wmrards-].wmrards-p-t-xl {
    padding-top: 3rem
}

html body [class*=wmrards-].wmrards-p-t-none {
    padding-top: 0 !important
}

html body [class*=wmrards-].wmrards-p-r-xs {
    padding-right: .25rem
}

html body [class*=wmrards-].wmrards-p-r-xsm {
    padding-right: .5rem
}

html body [class*=wmrards-].wmrards-p-r-sm {
    padding-right: .75rem
}

html body [class*=wmrards-].wmrards-p-r-md {
    padding-right: 1rem
}

html body [class*=wmrards-].wmrards-p-r-lg {
    padding-right: 2rem
}

html body [class*=wmrards-].wmrards-p-r-xl {
    padding-right: 3rem
}

html body [class*=wmrards-].wmrards-p-r-none {
    padding-right: 0 !important
}

html body [class*=wmrards-].wmrards-p-b-xs {
    padding-bottom: .25rem
}

html body [class*=wmrards-].wmrards-p-b-xsm {
    padding-bottom: .5rem
}

html body [class*=wmrards-].wmrards-p-b-sm {
    padding-bottom: .75rem
}

html body [class*=wmrards-].wmrards-p-b-md {
    padding-bottom: 1rem
}

html body [class*=wmrards-].wmrards-p-b-lg {
    padding-bottom: 2rem
}

html body [class*=wmrards-].wmrards-p-b-xl {
    padding-bottom: 3rem
}

html body [class*=wmrards-].wmrards-p-b-none {
    padding-bottom: 0 !important
}

html body [class*=wmrards-].wmrards-p-l-xs {
    padding-left: .25rem
}

html body [class*=wmrards-].wmrards-p-l-xsm {
    padding-left: .5rem
}

html body [class*=wmrards-].wmrards-p-l-sm {
    padding-left: .75rem
}

html body [class*=wmrards-].wmrards-p-l-md {
    padding-left: 1rem
}

html body [class*=wmrards-].wmrards-p-l-lg {
    padding-left: 2rem
}

html body [class*=wmrards-].wmrards-p-l-xl {
    padding-left: 3rem
}

html body [class*=wmrards-].wmrards-p-l-none {
    padding-left: 0 !important
}

html body [class*=wmrards-].wmrards-screenreaders-only {
    position: absolute !important;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px)
}

html body [class*=wmrards-].wmrards-bdr-primary {
    border: 2px solid #841922
}

html body [class*=wmrards-].wmrards-bdr-primary-left {
    border-left: 2px solid #841922
}

html body [class*=wmrards-].wmrards-bdr-primary-right {
    border-right: 2px solid #841922
}

html body [class*=wmrards-].wmrards-bdr-primary-bottom {
    border-bottom: 2px solid #841922
}

html body [class*=wmrards-].wmrards-bdr-white {
    border: 2px solid #fff
}

html body [class*=wmrards-].wmrards-bdr-white-left {
    border-left: 2px solid #fff
}

html body [class*=wmrards-].wmrards-bdr-white-right {
    border-right: 2px solid #fff
}

html body [class*=wmrards-].wmrards-bdr-white-bottom {
    border-bottom: 2px solid #fff
}

html body [class*=wmrards-].wmrards-bdr-gray {
    border: 2px solid #e5272d
}

html body [class*=wmrards-].wmrards-bdr-gray-left {
    border-left: 2px solid #e5272d
}

html body [class*=wmrards-].wmrards-bdr-gray-right {
    border-right: 2px solid #e5272d
}

html body [class*=wmrards-].wmrards-bdr-gray-bottom {
    border-bottom: 2px solid #e5272d
}

html body [class*=wmrards-].wmrards-bdr-transparent {
    border: 2px solid transparent
}

html body [class*=wmrards-].wmrards-bdr-transparent-left {
    border-left: 2px solid transparent
}

html body [class*=wmrards-].wmrards-bdr-transparent-right {
    border-right: 2px solid transparent
}

html body [class*=wmrards-].wmrards-bdr-transparent-bottom {
    border-bottom: 2px solid transparent
}

html body [class*=wmrards-].wmrards-bg-default {
    background-color: #f3f2f1
}

html body [class*=wmrards-].wmrards-bg-white {
    background-color: #fff
}

html body [class*=wmrards-].wmrards-bg-planned-disruption {
    background-color: #fd0
}

html body [class*=wmrards-].wmrards-bg-disabled-elements {
    background-color: #676869
}

html body [class*=wmrards-].wmrards-bg-transparent {
    background-color: transparent
}

html body [class*=wmrards-].wmrards-swift-logo-inline {
    width: 3.6em;
    height: 1.3em;
    margin-top: -.175em;
    fill: currentColor;
    vertical-align: middle
}

[class*=wmrards-] * {
    font-family: inherit;
    -webkit-font-smoothing: antialiased
}

[class*=wmrards-].wmrards-grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    letter-spacing: -.31em;
    text-rendering: optimizespeed;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-line-pack: start;
    align-content: flex-start
}

[class*=wmrards-].wmrards-grid>[class*=wmrards-col-] {
    zoom: 1;
    letter-spacing: normal;
    word-spacing: normal;
    text-rendering: auto;
    vertical-align: top
}

[class*=wmrards-].wmrards-grid>[class*=wmrards-col-]:not(.wmrards-btn) {
    display: inline-block
}

.wmrards-grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    letter-spacing: -.31em;
    text-rendering: optimizespeed;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-line-pack: start;
    align-content: flex-start
}

.wmrards-col-auto {
    width: auto
}

.wmrards-col-1-24 {
    width: 4.1667%
}

.wmrards-col-1-12,
.wmrards-col-2-24 {
    width: 8.3333%
}

.wmrards-col-1-8,
.wmrards-col-3-24 {
    width: 12.5%
}

.wmrards-col-1-6,
.wmrards-col-4-24 {
    width: 16.6667%
}

.wmrards-col-1-5 {
    width: 20%
}

.wmrards-col-5-24 {
    width: 20.8333%
}

.wmrards-col-1-4,
.wmrards-col-6-24 {
    width: 25%
}

.wmrards-col-7-24 {
    width: 29.1667%
}

.wmrards-col-1-3,
.wmrards-col-8-24 {
    width: 33.3333%
}

.wmrards-col-3-8,
.wmrards-col-9-24 {
    width: 37.5%
}

.wmrards-col-2-5 {
    width: 40%
}

.wmrards-col-10-24,
.wmrards-col-5-12 {
    width: 41.6667%
}

.wmrards-col-11-24 {
    width: 45.8333%
}

.wmrards-col-1-2,
.wmrards-col-12-24 {
    width: 50%
}

.wmrards-col-13-24 {
    width: 54.1667%
}

.wmrards-col-14-24,
.wmrards-col-7-12 {
    width: 58.3333%
}

.wmrards-col-3-5 {
    width: 60%
}

.wmrards-col-15-24,
.wmrards-col-5-8 {
    width: 62.5%
}

.wmrards-col-16-24,
.wmrards-col-2-3 {
    width: 66.6667%
}

.wmrards-col-17-24 {
    width: 70.8333%
}

.wmrards-col-18-24,
.wmrards-col-3-4 {
    width: 75%
}

.wmrards-col-19-24 {
    width: 79.1667%
}

.wmrards-col-4-5 {
    width: 80%
}

.wmrards-col-20-24,
.wmrards-col-5-6 {
    width: 83.3333%
}

.wmrards-col-21-24,
.wmrards-col-7-8 {
    width: 87.5%
}

.wmrards-col-11-12,
.wmrards-col-22-24 {
    width: 91.6667%
}

.wmrards-col-23-24 {
    width: 95.8333%
}

.wmrards-col-1,
.wmrards-col-1-1,
.wmrards-col-24-24,
.wmrards-col-5-5 {
    width: 100%
}

.wmrards-col-1,
.wmrards-col-1-1,
.wmrards-col-1-12,
.wmrards-col-1-2,
.wmrards-col-1-24,
.wmrards-col-1-3,
.wmrards-col-1-4,
.wmrards-col-1-5,
.wmrards-col-1-6,
.wmrards-col-1-8,
.wmrards-col-10-24,
.wmrards-col-11-12,
.wmrards-col-11-24,
.wmrards-col-12-24,
.wmrards-col-13-24,
.wmrards-col-14-24,
.wmrards-col-15-24,
.wmrards-col-16-24,
.wmrards-col-17-24,
.wmrards-col-18-24,
.wmrards-col-19-24,
.wmrards-col-2-24,
.wmrards-col-2-3,
.wmrards-col-2-5,
.wmrards-col-20-24,
.wmrards-col-21-24,
.wmrards-col-22-24,
.wmrards-col-23-24,
.wmrards-col-24-24,
.wmrards-col-3-24,
.wmrards-col-3-4,
.wmrards-col-3-5,
.wmrards-col-3-8,
.wmrards-col-4-24,
.wmrards-col-4-5,
.wmrards-col-5-12,
.wmrards-col-5-24,
.wmrards-col-5-5,
.wmrards-col-5-6,
.wmrards-col-5-8,
.wmrards-col-6-24,
.wmrards-col-7-12,
.wmrards-col-7-24,
.wmrards-col-7-8,
.wmrards-col-8-24,
.wmrards-col-9-24,
.wmrards-col-auto {
    display: inline-block;
    zoom: 1;
    letter-spacing: normal;
    word-spacing: normal;
    text-rendering: auto;
    vertical-align: top
}

.wmrards-grid--spacing-2-xs>[class*=wmrards-col-]:first-child,
.wmrards-grid--spacing-2-xs>[class*=wmrards-col-]:nth-child(2n+1) {
    padding-right: .125rem;
    padding-left: 0
}

.wmrards-grid--spacing-2-xs>[class*=wmrards-col-]:nth-child(2n) {
    padding-right: 0;
    padding-left: .125rem
}

.wmrards-grid--spacing-2-xs>[class*=wmrards-col-].wmrards-col-1,
.wmrards-grid--spacing-2-xs>[class*=wmrards-col-].wmrards-col-1-1 {
    padding-right: 0;
    padding-left: 0
}

.wmrards-grid--spacing-3-xs>[class*=wmrards-col-]:first-child,
.wmrards-grid--spacing-3-xs>[class*=wmrards-col-]:nth-child(3n+1) {
    padding-right: .1666666667rem;
    padding-left: 0
}

.wmrards-grid--spacing-3-xs>[class*=wmrards-col-]:nth-child(3n) {
    padding-right: 0;
    padding-left: .1666666667rem
}

.wmrards-grid--spacing-3-xs>[class*=wmrards-col-]:nth-child(3n-1) {
    padding-right: .0833333333rem;
    padding-left: .0833333333rem
}

.wmrards-grid--spacing-3-xs>[class*=wmrards-col-].wmrards-col-1,
.wmrards-grid--spacing-3-xs>[class*=wmrards-col-].wmrards-col-1-1 {
    padding-right: 0;
    padding-left: 0
}

.wmrards-grid--spacing-4-xs>[class*=wmrards-col-]:first-child,
.wmrards-grid--spacing-4-xs>[class*=wmrards-col-]:nth-child(4n+1) {
    padding-right: .1875rem;
    padding-left: 0
}

.wmrards-grid--spacing-4-xs>[class*=wmrards-col-]:nth-child(4n) {
    padding-right: 0;
    padding-left: .1875rem
}

.wmrards-grid--spacing-4-xs>[class*=wmrards-col-]:nth-child(4n-1) {
    padding-right: .0625rem;
    padding-left: .125rem
}

.wmrards-grid--spacing-4-xs>[class*=wmrards-col-]:nth-child(4n-2) {
    padding-right: .125rem;
    padding-left: .0625rem
}

.wmrards-grid--spacing-4-xs>[class*=wmrards-col-].wmrards-col-1,
.wmrards-grid--spacing-4-xs>[class*=wmrards-col-].wmrards-col-1-1 {
    padding-right: 0;
    padding-left: 0
}

.wmrards-grid--spacing-5-xs>[class*=wmrards-col-]:first-child,
.wmrards-grid--spacing-5-xs>[class*=wmrards-col-]:nth-child(5n+1) {
    padding-right: .2rem;
    padding-left: 0
}

.wmrards-grid--spacing-5-xs>[class*=wmrards-col-]:nth-child(5n) {
    padding-right: 0;
    padding-left: .2rem
}

.wmrards-grid--spacing-5-xs>[class*=wmrards-col-]:nth-child(5n-1) {
    padding-right: .05rem;
    padding-left: .15rem
}

.wmrards-grid--spacing-5-xs>[class*=wmrards-col-]:nth-child(5n-2) {
    padding-right: .1rem;
    padding-left: .1rem
}

.wmrards-grid--spacing-5-xs>[class*=wmrards-col-]:nth-child(5n-3) {
    padding-right: .15rem;
    padding-left: .05rem
}

.wmrards-grid--spacing-5-xs>[class*=wmrards-col-].wmrards-col-1,
.wmrards-grid--spacing-5-xs>[class*=wmrards-col-].wmrards-col-1-1 {
    padding-right: 0;
    padding-left: 0
}

.wmrards-grid--spacing-2-xsm>[class*=wmrards-col-]:first-child,
.wmrards-grid--spacing-2-xsm>[class*=wmrards-col-]:nth-child(2n+1) {
    padding-right: .25rem;
    padding-left: 0
}

.wmrards-grid--spacing-2-xsm>[class*=wmrards-col-]:nth-child(2n) {
    padding-right: 0;
    padding-left: .25rem
}

.wmrards-grid--spacing-2-xsm>[class*=wmrards-col-].wmrards-col-1,
.wmrards-grid--spacing-2-xsm>[class*=wmrards-col-].wmrards-col-1-1 {
    padding-right: 0;
    padding-left: 0
}

.wmrards-grid--spacing-3-xsm>[class*=wmrards-col-]:first-child,
.wmrards-grid--spacing-3-xsm>[class*=wmrards-col-]:nth-child(3n+1) {
    padding-right: .3333333333rem;
    padding-left: 0
}

.wmrards-grid--spacing-3-xsm>[class*=wmrards-col-]:nth-child(3n) {
    padding-right: 0;
    padding-left: .3333333333rem
}

.wmrards-grid--spacing-3-xsm>[class*=wmrards-col-]:nth-child(3n-1) {
    padding-right: .1666666667rem;
    padding-left: .1666666667rem
}

.wmrards-grid--spacing-3-xsm>[class*=wmrards-col-].wmrards-col-1,
.wmrards-grid--spacing-3-xsm>[class*=wmrards-col-].wmrards-col-1-1 {
    padding-right: 0;
    padding-left: 0
}

.wmrards-grid--spacing-4-xsm>[class*=wmrards-col-]:first-child,
.wmrards-grid--spacing-4-xsm>[class*=wmrards-col-]:nth-child(4n+1) {
    padding-right: .375rem;
    padding-left: 0
}

.wmrards-grid--spacing-4-xsm>[class*=wmrards-col-]:nth-child(4n) {
    padding-right: 0;
    padding-left: .375rem
}

.wmrards-grid--spacing-4-xsm>[class*=wmrards-col-]:nth-child(4n-1) {
    padding-right: .125rem;
    padding-left: .25rem
}

.wmrards-grid--spacing-4-xsm>[class*=wmrards-col-]:nth-child(4n-2) {
    padding-right: .25rem;
    padding-left: .125rem
}

.wmrards-grid--spacing-4-xsm>[class*=wmrards-col-].wmrards-col-1,
.wmrards-grid--spacing-4-xsm>[class*=wmrards-col-].wmrards-col-1-1 {
    padding-right: 0;
    padding-left: 0
}

.wmrards-grid--spacing-5-xsm>[class*=wmrards-col-]:first-child,
.wmrards-grid--spacing-5-xsm>[class*=wmrards-col-]:nth-child(5n+1) {
    padding-right: .4rem;
    padding-left: 0
}

.wmrards-grid--spacing-5-xsm>[class*=wmrards-col-]:nth-child(5n) {
    padding-right: 0;
    padding-left: .4rem
}

.wmrards-grid--spacing-5-xsm>[class*=wmrards-col-]:nth-child(5n-1) {
    padding-right: .1rem;
    padding-left: .3rem
}

.wmrards-grid--spacing-5-xsm>[class*=wmrards-col-]:nth-child(5n-2) {
    padding-right: .2rem;
    padding-left: .2rem
}

.wmrards-grid--spacing-5-xsm>[class*=wmrards-col-]:nth-child(5n-3) {
    padding-right: .3rem;
    padding-left: .1rem
}

.wmrards-grid--spacing-5-xsm>[class*=wmrards-col-].wmrards-col-1,
.wmrards-grid--spacing-5-xsm>[class*=wmrards-col-].wmrards-col-1-1 {
    padding-right: 0;
    padding-left: 0
}

.wmrards-grid--spacing-2-sm>[class*=wmrards-col-]:first-child,
.wmrards-grid--spacing-2-sm>[class*=wmrards-col-]:nth-child(2n+1) {
    padding-right: .375rem;
    padding-left: 0
}

.wmrards-grid--spacing-2-sm>[class*=wmrards-col-]:nth-child(2n) {
    padding-right: 0;
    padding-left: .375rem
}

.wmrards-grid--spacing-2-sm>[class*=wmrards-col-].wmrards-col-1,
.wmrards-grid--spacing-2-sm>[class*=wmrards-col-].wmrards-col-1-1 {
    padding-right: 0;
    padding-left: 0
}

.wmrards-grid--spacing-3-sm>[class*=wmrards-col-]:first-child,
.wmrards-grid--spacing-3-sm>[class*=wmrards-col-]:nth-child(3n+1) {
    padding-right: .5rem;
    padding-left: 0
}

.wmrards-grid--spacing-3-sm>[class*=wmrards-col-]:nth-child(3n) {
    padding-right: 0;
    padding-left: .5rem
}

.wmrards-grid--spacing-3-sm>[class*=wmrards-col-]:nth-child(3n-1) {
    padding-right: .25rem;
    padding-left: .25rem
}

.wmrards-grid--spacing-3-sm>[class*=wmrards-col-].wmrards-col-1,
.wmrards-grid--spacing-3-sm>[class*=wmrards-col-].wmrards-col-1-1 {
    padding-right: 0;
    padding-left: 0
}

.wmrards-grid--spacing-4-sm>[class*=wmrards-col-]:first-child,
.wmrards-grid--spacing-4-sm>[class*=wmrards-col-]:nth-child(4n+1) {
    padding-right: .5625rem;
    padding-left: 0
}

.wmrards-grid--spacing-4-sm>[class*=wmrards-col-]:nth-child(4n) {
    padding-right: 0;
    padding-left: .5625rem
}

.wmrards-grid--spacing-4-sm>[class*=wmrards-col-]:nth-child(4n-1) {
    padding-right: .1875rem;
    padding-left: .375rem
}

.wmrards-grid--spacing-4-sm>[class*=wmrards-col-]:nth-child(4n-2) {
    padding-right: .375rem;
    padding-left: .1875rem
}

.wmrards-grid--spacing-4-sm>[class*=wmrards-col-].wmrards-col-1,
.wmrards-grid--spacing-4-sm>[class*=wmrards-col-].wmrards-col-1-1 {
    padding-right: 0;
    padding-left: 0
}

.wmrards-grid--spacing-5-sm>[class*=wmrards-col-]:first-child,
.wmrards-grid--spacing-5-sm>[class*=wmrards-col-]:nth-child(5n+1) {
    padding-right: .6rem;
    padding-left: 0
}

.wmrards-grid--spacing-5-sm>[class*=wmrards-col-]:nth-child(5n) {
    padding-right: 0;
    padding-left: .6rem
}

.wmrards-grid--spacing-5-sm>[class*=wmrards-col-]:nth-child(5n-1) {
    padding-right: .15rem;
    padding-left: .45rem
}

.wmrards-grid--spacing-5-sm>[class*=wmrards-col-]:nth-child(5n-2) {
    padding-right: .3rem;
    padding-left: .3rem
}

.wmrards-grid--spacing-5-sm>[class*=wmrards-col-]:nth-child(5n-3) {
    padding-right: .45rem;
    padding-left: .15rem
}

.wmrards-grid--spacing-5-sm>[class*=wmrards-col-].wmrards-col-1,
.wmrards-grid--spacing-5-sm>[class*=wmrards-col-].wmrards-col-1-1 {
    padding-right: 0;
    padding-left: 0
}

.wmrards-grid--spacing-2-md>[class*=wmrards-col-]:first-child,
.wmrards-grid--spacing-2-md>[class*=wmrards-col-]:nth-child(2n+1) {
    padding-right: .5rem;
    padding-left: 0
}

.wmrards-grid--spacing-2-md>[class*=wmrards-col-]:nth-child(2n) {
    padding-right: 0;
    padding-left: .5rem
}

.wmrards-grid--spacing-2-md>[class*=wmrards-col-].wmrards-col-1,
.wmrards-grid--spacing-2-md>[class*=wmrards-col-].wmrards-col-1-1 {
    padding-right: 0;
    padding-left: 0
}

.wmrards-grid--spacing-3-md>[class*=wmrards-col-]:first-child,
.wmrards-grid--spacing-3-md>[class*=wmrards-col-]:nth-child(3n+1) {
    padding-right: .6666666667rem;
    padding-left: 0
}

.wmrards-grid--spacing-3-md>[class*=wmrards-col-]:nth-child(3n) {
    padding-right: 0;
    padding-left: .6666666667rem
}

.wmrards-grid--spacing-3-md>[class*=wmrards-col-]:nth-child(3n-1) {
    padding-right: .3333333333rem;
    padding-left: .3333333333rem
}

.wmrards-grid--spacing-3-md>[class*=wmrards-col-].wmrards-col-1,
.wmrards-grid--spacing-3-md>[class*=wmrards-col-].wmrards-col-1-1 {
    padding-right: 0;
    padding-left: 0
}

.wmrards-grid--spacing-4-md>[class*=wmrards-col-]:first-child,
.wmrards-grid--spacing-4-md>[class*=wmrards-col-]:nth-child(4n+1) {
    padding-right: .75rem;
    padding-left: 0
}

.wmrards-grid--spacing-4-md>[class*=wmrards-col-]:nth-child(4n) {
    padding-right: 0;
    padding-left: .75rem
}

.wmrards-grid--spacing-4-md>[class*=wmrards-col-]:nth-child(4n-1) {
    padding-right: .25rem;
    padding-left: .5rem
}

.wmrards-grid--spacing-4-md>[class*=wmrards-col-]:nth-child(4n-2) {
    padding-right: .5rem;
    padding-left: .25rem
}

.wmrards-grid--spacing-4-md>[class*=wmrards-col-].wmrards-col-1,
.wmrards-grid--spacing-4-md>[class*=wmrards-col-].wmrards-col-1-1 {
    padding-right: 0;
    padding-left: 0
}

.wmrards-grid--spacing-5-md>[class*=wmrards-col-]:first-child,
.wmrards-grid--spacing-5-md>[class*=wmrards-col-]:nth-child(5n+1) {
    padding-right: .8rem;
    padding-left: 0
}

.wmrards-grid--spacing-5-md>[class*=wmrards-col-]:nth-child(5n) {
    padding-right: 0;
    padding-left: .8rem
}

.wmrards-grid--spacing-5-md>[class*=wmrards-col-]:nth-child(5n-1) {
    padding-right: .2rem;
    padding-left: .6rem
}

.wmrards-grid--spacing-5-md>[class*=wmrards-col-]:nth-child(5n-2) {
    padding-right: .4rem;
    padding-left: .4rem
}

.wmrards-grid--spacing-5-md>[class*=wmrards-col-]:nth-child(5n-3) {
    padding-right: .6rem;
    padding-left: .2rem
}

.wmrards-grid--spacing-5-md>[class*=wmrards-col-].wmrards-col-1,
.wmrards-grid--spacing-5-md>[class*=wmrards-col-].wmrards-col-1-1 {
    padding-right: 0;
    padding-left: 0
}

.wmrards-grid--spacing-2-lg>[class*=wmrards-col-]:first-child,
.wmrards-grid--spacing-2-lg>[class*=wmrards-col-]:nth-child(2n+1) {
    padding-right: 1rem;
    padding-left: 0
}

.wmrards-grid--spacing-2-lg>[class*=wmrards-col-]:nth-child(2n) {
    padding-right: 0;
    padding-left: 1rem
}

.wmrards-grid--spacing-2-lg>[class*=wmrards-col-].wmrards-col-1,
.wmrards-grid--spacing-2-lg>[class*=wmrards-col-].wmrards-col-1-1 {
    padding-right: 0;
    padding-left: 0
}

.wmrards-grid--spacing-3-lg>[class*=wmrards-col-]:first-child,
.wmrards-grid--spacing-3-lg>[class*=wmrards-col-]:nth-child(3n+1) {
    padding-right: 1.3333333333rem;
    padding-left: 0
}

.wmrards-grid--spacing-3-lg>[class*=wmrards-col-]:nth-child(3n) {
    padding-right: 0;
    padding-left: 1.3333333333rem
}

.wmrards-grid--spacing-3-lg>[class*=wmrards-col-]:nth-child(3n-1) {
    padding-right: .6666666667rem;
    padding-left: .6666666667rem
}

.wmrards-grid--spacing-3-lg>[class*=wmrards-col-].wmrards-col-1,
.wmrards-grid--spacing-3-lg>[class*=wmrards-col-].wmrards-col-1-1 {
    padding-right: 0;
    padding-left: 0
}

.wmrards-grid--spacing-4-lg>[class*=wmrards-col-]:first-child,
.wmrards-grid--spacing-4-lg>[class*=wmrards-col-]:nth-child(4n+1) {
    padding-right: 1.5rem;
    padding-left: 0
}

.wmrards-grid--spacing-4-lg>[class*=wmrards-col-]:nth-child(4n) {
    padding-right: 0;
    padding-left: 1.5rem
}

.wmrards-grid--spacing-4-lg>[class*=wmrards-col-]:nth-child(4n-1) {
    padding-right: .5rem;
    padding-left: 1rem
}

.wmrards-grid--spacing-4-lg>[class*=wmrards-col-]:nth-child(4n-2) {
    padding-right: 1rem;
    padding-left: .5rem
}

.wmrards-grid--spacing-4-lg>[class*=wmrards-col-].wmrards-col-1,
.wmrards-grid--spacing-4-lg>[class*=wmrards-col-].wmrards-col-1-1 {
    padding-right: 0;
    padding-left: 0
}

.wmrards-grid--spacing-5-lg>[class*=wmrards-col-]:first-child,
.wmrards-grid--spacing-5-lg>[class*=wmrards-col-]:nth-child(5n+1) {
    padding-right: 1.6rem;
    padding-left: 0
}

.wmrards-grid--spacing-5-lg>[class*=wmrards-col-]:nth-child(5n) {
    padding-right: 0;
    padding-left: 1.6rem
}

.wmrards-grid--spacing-5-lg>[class*=wmrards-col-]:nth-child(5n-1) {
    padding-right: .4rem;
    padding-left: 1.2rem
}

.wmrards-grid--spacing-5-lg>[class*=wmrards-col-]:nth-child(5n-2) {
    padding-right: .8rem;
    padding-left: .8rem
}

.wmrards-grid--spacing-5-lg>[class*=wmrards-col-]:nth-child(5n-3) {
    padding-right: 1.2rem;
    padding-left: .4rem
}

.wmrards-grid--spacing-5-lg>[class*=wmrards-col-].wmrards-col-1,
.wmrards-grid--spacing-5-lg>[class*=wmrards-col-].wmrards-col-1-1 {
    padding-right: 0;
    padding-left: 0
}

.wmrards-grid--spacing-2-xl>[class*=wmrards-col-]:first-child,
.wmrards-grid--spacing-2-xl>[class*=wmrards-col-]:nth-child(2n+1) {
    padding-right: 1.5rem;
    padding-left: 0
}

.wmrards-grid--spacing-2-xl>[class*=wmrards-col-]:nth-child(2n) {
    padding-right: 0;
    padding-left: 1.5rem
}

.wmrards-grid--spacing-2-xl>[class*=wmrards-col-].wmrards-col-1,
.wmrards-grid--spacing-2-xl>[class*=wmrards-col-].wmrards-col-1-1 {
    padding-right: 0;
    padding-left: 0
}

.wmrards-grid--spacing-3-xl>[class*=wmrards-col-]:first-child,
.wmrards-grid--spacing-3-xl>[class*=wmrards-col-]:nth-child(3n+1) {
    padding-right: 2rem;
    padding-left: 0
}

.wmrards-grid--spacing-3-xl>[class*=wmrards-col-]:nth-child(3n) {
    padding-right: 0;
    padding-left: 2rem
}

.wmrards-grid--spacing-3-xl>[class*=wmrards-col-]:nth-child(3n-1) {
    padding-right: 1rem;
    padding-left: 1rem
}

.wmrards-grid--spacing-3-xl>[class*=wmrards-col-].wmrards-col-1,
.wmrards-grid--spacing-3-xl>[class*=wmrards-col-].wmrards-col-1-1 {
    padding-right: 0;
    padding-left: 0
}

.wmrards-grid--spacing-4-xl>[class*=wmrards-col-]:first-child,
.wmrards-grid--spacing-4-xl>[class*=wmrards-col-]:nth-child(4n+1) {
    padding-right: 2.25rem;
    padding-left: 0
}

.wmrards-grid--spacing-4-xl>[class*=wmrards-col-]:nth-child(4n) {
    padding-right: 0;
    padding-left: 2.25rem
}

.wmrards-grid--spacing-4-xl>[class*=wmrards-col-]:nth-child(4n-1) {
    padding-right: .75rem;
    padding-left: 1.5rem
}

.wmrards-grid--spacing-4-xl>[class*=wmrards-col-]:nth-child(4n-2) {
    padding-right: 1.5rem;
    padding-left: .75rem
}

.wmrards-grid--spacing-4-xl>[class*=wmrards-col-].wmrards-col-1,
.wmrards-grid--spacing-4-xl>[class*=wmrards-col-].wmrards-col-1-1 {
    padding-right: 0;
    padding-left: 0
}

.wmrards-grid--spacing-5-xl>[class*=wmrards-col-]:first-child,
.wmrards-grid--spacing-5-xl>[class*=wmrards-col-]:nth-child(5n+1) {
    padding-right: 2.4rem;
    padding-left: 0
}

.wmrards-grid--spacing-5-xl>[class*=wmrards-col-]:nth-child(5n) {
    padding-right: 0;
    padding-left: 2.4rem
}

.wmrards-grid--spacing-5-xl>[class*=wmrards-col-]:nth-child(5n-1) {
    padding-right: .6rem;
    padding-left: 1.8rem
}

.wmrards-grid--spacing-5-xl>[class*=wmrards-col-]:nth-child(5n-2) {
    padding-right: 1.2rem;
    padding-left: 1.2rem
}

.wmrards-grid--spacing-5-xl>[class*=wmrards-col-]:nth-child(5n-3) {
    padding-right: 1.8rem;
    padding-left: .6rem
}

.wmrards-grid--spacing-5-xl>[class*=wmrards-col-].wmrards-col-1,
.wmrards-grid--spacing-5-xl>[class*=wmrards-col-].wmrards-col-1-1 {
    padding-right: 0;
    padding-left: 0
}

@media screen and (min-width:35.5em) {

    .wmrards-col-sm-1,
    .wmrards-col-sm-1-1,
    .wmrards-col-sm-1-12,
    .wmrards-col-sm-1-2,
    .wmrards-col-sm-1-24,
    .wmrards-col-sm-1-3,
    .wmrards-col-sm-1-4,
    .wmrards-col-sm-1-5,
    .wmrards-col-sm-1-6,
    .wmrards-col-sm-1-8,
    .wmrards-col-sm-10-24,
    .wmrards-col-sm-11-12,
    .wmrards-col-sm-11-24,
    .wmrards-col-sm-12-24,
    .wmrards-col-sm-13-24,
    .wmrards-col-sm-14-24,
    .wmrards-col-sm-15-24,
    .wmrards-col-sm-16-24,
    .wmrards-col-sm-17-24,
    .wmrards-col-sm-18-24,
    .wmrards-col-sm-19-24,
    .wmrards-col-sm-2-24,
    .wmrards-col-sm-2-3,
    .wmrards-col-sm-2-5,
    .wmrards-col-sm-20-24,
    .wmrards-col-sm-21-24,
    .wmrards-col-sm-22-24,
    .wmrards-col-sm-23-24,
    .wmrards-col-sm-24-24,
    .wmrards-col-sm-3-24,
    .wmrards-col-sm-3-4,
    .wmrards-col-sm-3-5,
    .wmrards-col-sm-3-8,
    .wmrards-col-sm-4-24,
    .wmrards-col-sm-4-5,
    .wmrards-col-sm-5-12,
    .wmrards-col-sm-5-24,
    .wmrards-col-sm-5-5,
    .wmrards-col-sm-5-6,
    .wmrards-col-sm-5-8,
    .wmrards-col-sm-6-24,
    .wmrards-col-sm-7-12,
    .wmrards-col-sm-7-24,
    .wmrards-col-sm-7-8,
    .wmrards-col-sm-8-24,
    .wmrards-col-sm-9-24,
    .wmrards-col-sm-auto {
        display: inline-block;
        zoom: 1;
        letter-spacing: normal;
        word-spacing: normal;
        text-rendering: auto;
        vertical-align: top
    }

    .wmrards-col-sm-auto {
        width: auto
    }

    .wmrards-col-sm-1-24 {
        width: 4.1667%
    }

    .wmrards-col-sm-1-12,
    .wmrards-col-sm-2-24 {
        width: 8.3333%
    }

    .wmrards-col-sm-1-8,
    .wmrards-col-sm-3-24 {
        width: 12.5%
    }

    .wmrards-col-sm-1-6,
    .wmrards-col-sm-4-24 {
        width: 16.6667%
    }

    .wmrards-col-sm-1-5 {
        width: 20%
    }

    .wmrards-col-sm-5-24 {
        width: 20.8333%
    }

    .wmrards-col-sm-1-4,
    .wmrards-col-sm-6-24 {
        width: 25%
    }

    .wmrards-col-sm-7-24 {
        width: 29.1667%
    }

    .wmrards-col-sm-1-3,
    .wmrards-col-sm-8-24 {
        width: 33.3333%
    }

    .wmrards-col-sm-3-8,
    .wmrards-col-sm-9-24 {
        width: 37.5%
    }

    .wmrards-col-sm-2-5 {
        width: 40%
    }

    .wmrards-col-sm-10-24,
    .wmrards-col-sm-5-12 {
        width: 41.6667%
    }

    .wmrards-col-sm-11-24 {
        width: 45.8333%
    }

    .wmrards-col-sm-1-2,
    .wmrards-col-sm-12-24 {
        width: 50%
    }

    .wmrards-col-sm-13-24 {
        width: 54.1667%
    }

    .wmrards-col-sm-14-24,
    .wmrards-col-sm-7-12 {
        width: 58.3333%
    }

    .wmrards-col-sm-3-5 {
        width: 60%
    }

    .wmrards-col-sm-15-24,
    .wmrards-col-sm-5-8 {
        width: 62.5%
    }

    .wmrards-col-sm-16-24,
    .wmrards-col-sm-2-3 {
        width: 66.6667%
    }

    .wmrards-col-sm-17-24 {
        width: 70.8333%
    }

    .wmrards-col-sm-18-24,
    .wmrards-col-sm-3-4 {
        width: 75%
    }

    .wmrards-col-sm-19-24 {
        width: 79.1667%
    }

    .wmrards-col-sm-4-5 {
        width: 80%
    }

    .wmrards-col-sm-20-24,
    .wmrards-col-sm-5-6 {
        width: 83.3333%
    }

    .wmrards-col-sm-21-24,
    .wmrards-col-sm-7-8 {
        width: 87.5%
    }

    .wmrards-col-sm-11-12,
    .wmrards-col-sm-22-24 {
        width: 91.6667%
    }

    .wmrards-col-sm-23-24 {
        width: 95.8333%
    }

    .wmrards-col-sm-1,
    .wmrards-col-sm-1-1,
    .wmrards-col-sm-24-24,
    .wmrards-col-sm-5-5 {
        width: 100%
    }

    .wmrards-grid--spacing-sm-2-xs>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-sm-2-xs>[class*=wmrards-col-]:nth-child(2n+1) {
        padding-right: .125rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-sm-2-xs>[class*=wmrards-col-]:nth-child(2n) {
        padding-right: 0;
        padding-left: .125rem
    }
}

@media screen and (min-width:35.5em) and (min-width:35.5em) {

    .wmrards-grid--spacing-sm-2-xs>[class*=wmrards-col-].wmrards-col-sm-1,
    .wmrards-grid--spacing-sm-2-xs>[class*=wmrards-col-].wmrards-col-sm-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:35.5em) {

    .wmrards-grid--spacing-sm-3-xs>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-sm-3-xs>[class*=wmrards-col-]:nth-child(3n+1) {
        padding-right: .1666666667rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-sm-3-xs>[class*=wmrards-col-]:nth-child(3n) {
        padding-right: 0;
        padding-left: .1666666667rem
    }

    .wmrards-grid--spacing-sm-3-xs>[class*=wmrards-col-]:nth-child(3n-1) {
        padding-right: .0833333333rem;
        padding-left: .0833333333rem
    }
}

@media screen and (min-width:35.5em) and (min-width:35.5em) {

    .wmrards-grid--spacing-sm-3-xs>[class*=wmrards-col-].wmrards-col-sm-1,
    .wmrards-grid--spacing-sm-3-xs>[class*=wmrards-col-].wmrards-col-sm-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:35.5em) {

    .wmrards-grid--spacing-sm-4-xs>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-sm-4-xs>[class*=wmrards-col-]:nth-child(4n+1) {
        padding-right: .1875rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-sm-4-xs>[class*=wmrards-col-]:nth-child(4n) {
        padding-right: 0;
        padding-left: .1875rem
    }

    .wmrards-grid--spacing-sm-4-xs>[class*=wmrards-col-]:nth-child(4n-1) {
        padding-right: .0625rem;
        padding-left: .125rem
    }

    .wmrards-grid--spacing-sm-4-xs>[class*=wmrards-col-]:nth-child(4n-2) {
        padding-right: .125rem;
        padding-left: .0625rem
    }
}

@media screen and (min-width:35.5em) and (min-width:35.5em) {

    .wmrards-grid--spacing-sm-4-xs>[class*=wmrards-col-].wmrards-col-sm-1,
    .wmrards-grid--spacing-sm-4-xs>[class*=wmrards-col-].wmrards-col-sm-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:35.5em) {

    .wmrards-grid--spacing-sm-5-xs>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-sm-5-xs>[class*=wmrards-col-]:nth-child(5n+1) {
        padding-right: .2rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-sm-5-xs>[class*=wmrards-col-]:nth-child(5n) {
        padding-right: 0;
        padding-left: .2rem
    }

    .wmrards-grid--spacing-sm-5-xs>[class*=wmrards-col-]:nth-child(5n-1) {
        padding-right: .05rem;
        padding-left: .15rem
    }

    .wmrards-grid--spacing-sm-5-xs>[class*=wmrards-col-]:nth-child(5n-2) {
        padding-right: .1rem;
        padding-left: .1rem
    }

    .wmrards-grid--spacing-sm-5-xs>[class*=wmrards-col-]:nth-child(5n-3) {
        padding-right: .15rem;
        padding-left: .05rem
    }
}

@media screen and (min-width:35.5em) and (min-width:35.5em) {

    .wmrards-grid--spacing-sm-5-xs>[class*=wmrards-col-].wmrards-col-sm-1,
    .wmrards-grid--spacing-sm-5-xs>[class*=wmrards-col-].wmrards-col-sm-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:35.5em) {

    .wmrards-grid--spacing-sm-2-xsm>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-sm-2-xsm>[class*=wmrards-col-]:nth-child(2n+1) {
        padding-right: .25rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-sm-2-xsm>[class*=wmrards-col-]:nth-child(2n) {
        padding-right: 0;
        padding-left: .25rem
    }
}

@media screen and (min-width:35.5em) and (min-width:35.5em) {

    .wmrards-grid--spacing-sm-2-xsm>[class*=wmrards-col-].wmrards-col-sm-1,
    .wmrards-grid--spacing-sm-2-xsm>[class*=wmrards-col-].wmrards-col-sm-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:35.5em) {

    .wmrards-grid--spacing-sm-3-xsm>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-sm-3-xsm>[class*=wmrards-col-]:nth-child(3n+1) {
        padding-right: .3333333333rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-sm-3-xsm>[class*=wmrards-col-]:nth-child(3n) {
        padding-right: 0;
        padding-left: .3333333333rem
    }

    .wmrards-grid--spacing-sm-3-xsm>[class*=wmrards-col-]:nth-child(3n-1) {
        padding-right: .1666666667rem;
        padding-left: .1666666667rem
    }
}

@media screen and (min-width:35.5em) and (min-width:35.5em) {

    .wmrards-grid--spacing-sm-3-xsm>[class*=wmrards-col-].wmrards-col-sm-1,
    .wmrards-grid--spacing-sm-3-xsm>[class*=wmrards-col-].wmrards-col-sm-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:35.5em) {

    .wmrards-grid--spacing-sm-4-xsm>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-sm-4-xsm>[class*=wmrards-col-]:nth-child(4n+1) {
        padding-right: .375rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-sm-4-xsm>[class*=wmrards-col-]:nth-child(4n) {
        padding-right: 0;
        padding-left: .375rem
    }

    .wmrards-grid--spacing-sm-4-xsm>[class*=wmrards-col-]:nth-child(4n-1) {
        padding-right: .125rem;
        padding-left: .25rem
    }

    .wmrards-grid--spacing-sm-4-xsm>[class*=wmrards-col-]:nth-child(4n-2) {
        padding-right: .25rem;
        padding-left: .125rem
    }
}

@media screen and (min-width:35.5em) and (min-width:35.5em) {

    .wmrards-grid--spacing-sm-4-xsm>[class*=wmrards-col-].wmrards-col-sm-1,
    .wmrards-grid--spacing-sm-4-xsm>[class*=wmrards-col-].wmrards-col-sm-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:35.5em) {

    .wmrards-grid--spacing-sm-5-xsm>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-sm-5-xsm>[class*=wmrards-col-]:nth-child(5n+1) {
        padding-right: .4rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-sm-5-xsm>[class*=wmrards-col-]:nth-child(5n) {
        padding-right: 0;
        padding-left: .4rem
    }

    .wmrards-grid--spacing-sm-5-xsm>[class*=wmrards-col-]:nth-child(5n-1) {
        padding-right: .1rem;
        padding-left: .3rem
    }

    .wmrards-grid--spacing-sm-5-xsm>[class*=wmrards-col-]:nth-child(5n-2) {
        padding-right: .2rem;
        padding-left: .2rem
    }

    .wmrards-grid--spacing-sm-5-xsm>[class*=wmrards-col-]:nth-child(5n-3) {
        padding-right: .3rem;
        padding-left: .1rem
    }
}

@media screen and (min-width:35.5em) and (min-width:35.5em) {

    .wmrards-grid--spacing-sm-5-xsm>[class*=wmrards-col-].wmrards-col-sm-1,
    .wmrards-grid--spacing-sm-5-xsm>[class*=wmrards-col-].wmrards-col-sm-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:35.5em) {

    .wmrards-grid--spacing-sm-2-sm>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-sm-2-sm>[class*=wmrards-col-]:nth-child(2n+1) {
        padding-right: .375rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-sm-2-sm>[class*=wmrards-col-]:nth-child(2n) {
        padding-right: 0;
        padding-left: .375rem
    }
}

@media screen and (min-width:35.5em) and (min-width:35.5em) {

    .wmrards-grid--spacing-sm-2-sm>[class*=wmrards-col-].wmrards-col-sm-1,
    .wmrards-grid--spacing-sm-2-sm>[class*=wmrards-col-].wmrards-col-sm-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:35.5em) {

    .wmrards-grid--spacing-sm-3-sm>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-sm-3-sm>[class*=wmrards-col-]:nth-child(3n+1) {
        padding-right: .5rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-sm-3-sm>[class*=wmrards-col-]:nth-child(3n) {
        padding-right: 0;
        padding-left: .5rem
    }

    .wmrards-grid--spacing-sm-3-sm>[class*=wmrards-col-]:nth-child(3n-1) {
        padding-right: .25rem;
        padding-left: .25rem
    }
}

@media screen and (min-width:35.5em) and (min-width:35.5em) {

    .wmrards-grid--spacing-sm-3-sm>[class*=wmrards-col-].wmrards-col-sm-1,
    .wmrards-grid--spacing-sm-3-sm>[class*=wmrards-col-].wmrards-col-sm-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:35.5em) {

    .wmrards-grid--spacing-sm-4-sm>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-sm-4-sm>[class*=wmrards-col-]:nth-child(4n+1) {
        padding-right: .5625rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-sm-4-sm>[class*=wmrards-col-]:nth-child(4n) {
        padding-right: 0;
        padding-left: .5625rem
    }

    .wmrards-grid--spacing-sm-4-sm>[class*=wmrards-col-]:nth-child(4n-1) {
        padding-right: .1875rem;
        padding-left: .375rem
    }

    .wmrards-grid--spacing-sm-4-sm>[class*=wmrards-col-]:nth-child(4n-2) {
        padding-right: .375rem;
        padding-left: .1875rem
    }
}

@media screen and (min-width:35.5em) and (min-width:35.5em) {

    .wmrards-grid--spacing-sm-4-sm>[class*=wmrards-col-].wmrards-col-sm-1,
    .wmrards-grid--spacing-sm-4-sm>[class*=wmrards-col-].wmrards-col-sm-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:35.5em) {

    .wmrards-grid--spacing-sm-5-sm>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-sm-5-sm>[class*=wmrards-col-]:nth-child(5n+1) {
        padding-right: .6rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-sm-5-sm>[class*=wmrards-col-]:nth-child(5n) {
        padding-right: 0;
        padding-left: .6rem
    }

    .wmrards-grid--spacing-sm-5-sm>[class*=wmrards-col-]:nth-child(5n-1) {
        padding-right: .15rem;
        padding-left: .45rem
    }

    .wmrards-grid--spacing-sm-5-sm>[class*=wmrards-col-]:nth-child(5n-2) {
        padding-right: .3rem;
        padding-left: .3rem
    }

    .wmrards-grid--spacing-sm-5-sm>[class*=wmrards-col-]:nth-child(5n-3) {
        padding-right: .45rem;
        padding-left: .15rem
    }
}

@media screen and (min-width:35.5em) and (min-width:35.5em) {

    .wmrards-grid--spacing-sm-5-sm>[class*=wmrards-col-].wmrards-col-sm-1,
    .wmrards-grid--spacing-sm-5-sm>[class*=wmrards-col-].wmrards-col-sm-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:35.5em) {

    .wmrards-grid--spacing-sm-2-md>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-sm-2-md>[class*=wmrards-col-]:nth-child(2n+1) {
        padding-right: .5rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-sm-2-md>[class*=wmrards-col-]:nth-child(2n) {
        padding-right: 0;
        padding-left: .5rem
    }
}

@media screen and (min-width:35.5em) and (min-width:35.5em) {

    .wmrards-grid--spacing-sm-2-md>[class*=wmrards-col-].wmrards-col-sm-1,
    .wmrards-grid--spacing-sm-2-md>[class*=wmrards-col-].wmrards-col-sm-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:35.5em) {

    .wmrards-grid--spacing-sm-3-md>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-sm-3-md>[class*=wmrards-col-]:nth-child(3n+1) {
        padding-right: .6666666667rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-sm-3-md>[class*=wmrards-col-]:nth-child(3n) {
        padding-right: 0;
        padding-left: .6666666667rem
    }

    .wmrards-grid--spacing-sm-3-md>[class*=wmrards-col-]:nth-child(3n-1) {
        padding-right: .3333333333rem;
        padding-left: .3333333333rem
    }
}

@media screen and (min-width:35.5em) and (min-width:35.5em) {

    .wmrards-grid--spacing-sm-3-md>[class*=wmrards-col-].wmrards-col-sm-1,
    .wmrards-grid--spacing-sm-3-md>[class*=wmrards-col-].wmrards-col-sm-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:35.5em) {

    .wmrards-grid--spacing-sm-4-md>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-sm-4-md>[class*=wmrards-col-]:nth-child(4n+1) {
        padding-right: .75rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-sm-4-md>[class*=wmrards-col-]:nth-child(4n) {
        padding-right: 0;
        padding-left: .75rem
    }

    .wmrards-grid--spacing-sm-4-md>[class*=wmrards-col-]:nth-child(4n-1) {
        padding-right: .25rem;
        padding-left: .5rem
    }

    .wmrards-grid--spacing-sm-4-md>[class*=wmrards-col-]:nth-child(4n-2) {
        padding-right: .5rem;
        padding-left: .25rem
    }
}

@media screen and (min-width:35.5em) and (min-width:35.5em) {

    .wmrards-grid--spacing-sm-4-md>[class*=wmrards-col-].wmrards-col-sm-1,
    .wmrards-grid--spacing-sm-4-md>[class*=wmrards-col-].wmrards-col-sm-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:35.5em) {

    .wmrards-grid--spacing-sm-5-md>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-sm-5-md>[class*=wmrards-col-]:nth-child(5n+1) {
        padding-right: .8rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-sm-5-md>[class*=wmrards-col-]:nth-child(5n) {
        padding-right: 0;
        padding-left: .8rem
    }

    .wmrards-grid--spacing-sm-5-md>[class*=wmrards-col-]:nth-child(5n-1) {
        padding-right: .2rem;
        padding-left: .6rem
    }

    .wmrards-grid--spacing-sm-5-md>[class*=wmrards-col-]:nth-child(5n-2) {
        padding-right: .4rem;
        padding-left: .4rem
    }

    .wmrards-grid--spacing-sm-5-md>[class*=wmrards-col-]:nth-child(5n-3) {
        padding-right: .6rem;
        padding-left: .2rem
    }
}

@media screen and (min-width:35.5em) and (min-width:35.5em) {

    .wmrards-grid--spacing-sm-5-md>[class*=wmrards-col-].wmrards-col-sm-1,
    .wmrards-grid--spacing-sm-5-md>[class*=wmrards-col-].wmrards-col-sm-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:35.5em) {

    .wmrards-grid--spacing-sm-2-lg>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-sm-2-lg>[class*=wmrards-col-]:nth-child(2n+1) {
        padding-right: 1rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-sm-2-lg>[class*=wmrards-col-]:nth-child(2n) {
        padding-right: 0;
        padding-left: 1rem
    }
}

@media screen and (min-width:35.5em) and (min-width:35.5em) {

    .wmrards-grid--spacing-sm-2-lg>[class*=wmrards-col-].wmrards-col-sm-1,
    .wmrards-grid--spacing-sm-2-lg>[class*=wmrards-col-].wmrards-col-sm-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:35.5em) {

    .wmrards-grid--spacing-sm-3-lg>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-sm-3-lg>[class*=wmrards-col-]:nth-child(3n+1) {
        padding-right: 1.3333333333rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-sm-3-lg>[class*=wmrards-col-]:nth-child(3n) {
        padding-right: 0;
        padding-left: 1.3333333333rem
    }

    .wmrards-grid--spacing-sm-3-lg>[class*=wmrards-col-]:nth-child(3n-1) {
        padding-right: .6666666667rem;
        padding-left: .6666666667rem
    }
}

@media screen and (min-width:35.5em) and (min-width:35.5em) {

    .wmrards-grid--spacing-sm-3-lg>[class*=wmrards-col-].wmrards-col-sm-1,
    .wmrards-grid--spacing-sm-3-lg>[class*=wmrards-col-].wmrards-col-sm-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:35.5em) {

    .wmrards-grid--spacing-sm-4-lg>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-sm-4-lg>[class*=wmrards-col-]:nth-child(4n+1) {
        padding-right: 1.5rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-sm-4-lg>[class*=wmrards-col-]:nth-child(4n) {
        padding-right: 0;
        padding-left: 1.5rem
    }

    .wmrards-grid--spacing-sm-4-lg>[class*=wmrards-col-]:nth-child(4n-1) {
        padding-right: .5rem;
        padding-left: 1rem
    }

    .wmrards-grid--spacing-sm-4-lg>[class*=wmrards-col-]:nth-child(4n-2) {
        padding-right: 1rem;
        padding-left: .5rem
    }
}

@media screen and (min-width:35.5em) and (min-width:35.5em) {

    .wmrards-grid--spacing-sm-4-lg>[class*=wmrards-col-].wmrards-col-sm-1,
    .wmrards-grid--spacing-sm-4-lg>[class*=wmrards-col-].wmrards-col-sm-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:35.5em) {

    .wmrards-grid--spacing-sm-5-lg>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-sm-5-lg>[class*=wmrards-col-]:nth-child(5n+1) {
        padding-right: 1.6rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-sm-5-lg>[class*=wmrards-col-]:nth-child(5n) {
        padding-right: 0;
        padding-left: 1.6rem
    }

    .wmrards-grid--spacing-sm-5-lg>[class*=wmrards-col-]:nth-child(5n-1) {
        padding-right: .4rem;
        padding-left: 1.2rem
    }

    .wmrards-grid--spacing-sm-5-lg>[class*=wmrards-col-]:nth-child(5n-2) {
        padding-right: .8rem;
        padding-left: .8rem
    }

    .wmrards-grid--spacing-sm-5-lg>[class*=wmrards-col-]:nth-child(5n-3) {
        padding-right: 1.2rem;
        padding-left: .4rem
    }
}

@media screen and (min-width:35.5em) and (min-width:35.5em) {

    .wmrards-grid--spacing-sm-5-lg>[class*=wmrards-col-].wmrards-col-sm-1,
    .wmrards-grid--spacing-sm-5-lg>[class*=wmrards-col-].wmrards-col-sm-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:35.5em) {

    .wmrards-grid--spacing-sm-2-xl>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-sm-2-xl>[class*=wmrards-col-]:nth-child(2n+1) {
        padding-right: 1.5rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-sm-2-xl>[class*=wmrards-col-]:nth-child(2n) {
        padding-right: 0;
        padding-left: 1.5rem
    }
}

@media screen and (min-width:35.5em) and (min-width:35.5em) {

    .wmrards-grid--spacing-sm-2-xl>[class*=wmrards-col-].wmrards-col-sm-1,
    .wmrards-grid--spacing-sm-2-xl>[class*=wmrards-col-].wmrards-col-sm-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:35.5em) {

    .wmrards-grid--spacing-sm-3-xl>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-sm-3-xl>[class*=wmrards-col-]:nth-child(3n+1) {
        padding-right: 2rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-sm-3-xl>[class*=wmrards-col-]:nth-child(3n) {
        padding-right: 0;
        padding-left: 2rem
    }

    .wmrards-grid--spacing-sm-3-xl>[class*=wmrards-col-]:nth-child(3n-1) {
        padding-right: 1rem;
        padding-left: 1rem
    }
}

@media screen and (min-width:35.5em) and (min-width:35.5em) {

    .wmrards-grid--spacing-sm-3-xl>[class*=wmrards-col-].wmrards-col-sm-1,
    .wmrards-grid--spacing-sm-3-xl>[class*=wmrards-col-].wmrards-col-sm-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:35.5em) {

    .wmrards-grid--spacing-sm-4-xl>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-sm-4-xl>[class*=wmrards-col-]:nth-child(4n+1) {
        padding-right: 2.25rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-sm-4-xl>[class*=wmrards-col-]:nth-child(4n) {
        padding-right: 0;
        padding-left: 2.25rem
    }

    .wmrards-grid--spacing-sm-4-xl>[class*=wmrards-col-]:nth-child(4n-1) {
        padding-right: .75rem;
        padding-left: 1.5rem
    }

    .wmrards-grid--spacing-sm-4-xl>[class*=wmrards-col-]:nth-child(4n-2) {
        padding-right: 1.5rem;
        padding-left: .75rem
    }
}

@media screen and (min-width:35.5em) and (min-width:35.5em) {

    .wmrards-grid--spacing-sm-4-xl>[class*=wmrards-col-].wmrards-col-sm-1,
    .wmrards-grid--spacing-sm-4-xl>[class*=wmrards-col-].wmrards-col-sm-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:35.5em) {

    .wmrards-grid--spacing-sm-5-xl>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-sm-5-xl>[class*=wmrards-col-]:nth-child(5n+1) {
        padding-right: 2.4rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-sm-5-xl>[class*=wmrards-col-]:nth-child(5n) {
        padding-right: 0;
        padding-left: 2.4rem
    }

    .wmrards-grid--spacing-sm-5-xl>[class*=wmrards-col-]:nth-child(5n-1) {
        padding-right: .6rem;
        padding-left: 1.8rem
    }

    .wmrards-grid--spacing-sm-5-xl>[class*=wmrards-col-]:nth-child(5n-2) {
        padding-right: 1.2rem;
        padding-left: 1.2rem
    }

    .wmrards-grid--spacing-sm-5-xl>[class*=wmrards-col-]:nth-child(5n-3) {
        padding-right: 1.8rem;
        padding-left: .6rem
    }
}

@media screen and (min-width:35.5em) and (min-width:35.5em) {

    .wmrards-grid--spacing-sm-5-xl>[class*=wmrards-col-].wmrards-col-sm-1,
    .wmrards-grid--spacing-sm-5-xl>[class*=wmrards-col-].wmrards-col-sm-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:48em) {

    .wmrards-col-md-1,
    .wmrards-col-md-1-1,
    .wmrards-col-md-1-12,
    .wmrards-col-md-1-2,
    .wmrards-col-md-1-24,
    .wmrards-col-md-1-3,
    .wmrards-col-md-1-4,
    .wmrards-col-md-1-5,
    .wmrards-col-md-1-6,
    .wmrards-col-md-1-8,
    .wmrards-col-md-10-24,
    .wmrards-col-md-11-12,
    .wmrards-col-md-11-24,
    .wmrards-col-md-12-24,
    .wmrards-col-md-13-24,
    .wmrards-col-md-14-24,
    .wmrards-col-md-15-24,
    .wmrards-col-md-16-24,
    .wmrards-col-md-17-24,
    .wmrards-col-md-18-24,
    .wmrards-col-md-19-24,
    .wmrards-col-md-2-24,
    .wmrards-col-md-2-3,
    .wmrards-col-md-2-5,
    .wmrards-col-md-20-24,
    .wmrards-col-md-21-24,
    .wmrards-col-md-22-24,
    .wmrards-col-md-23-24,
    .wmrards-col-md-24-24,
    .wmrards-col-md-3-24,
    .wmrards-col-md-3-4,
    .wmrards-col-md-3-5,
    .wmrards-col-md-3-8,
    .wmrards-col-md-4-24,
    .wmrards-col-md-4-5,
    .wmrards-col-md-5-12,
    .wmrards-col-md-5-24,
    .wmrards-col-md-5-5,
    .wmrards-col-md-5-6,
    .wmrards-col-md-5-8,
    .wmrards-col-md-6-24,
    .wmrards-col-md-7-12,
    .wmrards-col-md-7-24,
    .wmrards-col-md-7-8,
    .wmrards-col-md-8-24,
    .wmrards-col-md-9-24,
    .wmrards-col-md-auto {
        display: inline-block;
        zoom: 1;
        letter-spacing: normal;
        word-spacing: normal;
        text-rendering: auto;
        vertical-align: top
    }

    .wmrards-col-md-auto {
        width: auto
    }

    .wmrards-col-md-1-24 {
        width: 4.1667%
    }

    .wmrards-col-md-1-12,
    .wmrards-col-md-2-24 {
        width: 8.3333%
    }

    .wmrards-col-md-1-8,
    .wmrards-col-md-3-24 {
        width: 12.5%
    }

    .wmrards-col-md-1-6,
    .wmrards-col-md-4-24 {
        width: 16.6667%
    }

    .wmrards-col-md-1-5 {
        width: 20%
    }

    .wmrards-col-md-5-24 {
        width: 20.8333%
    }

    .wmrards-col-md-1-4,
    .wmrards-col-md-6-24 {
        width: 25%
    }

    .wmrards-col-md-7-24 {
        width: 29.1667%
    }

    .wmrards-col-md-1-3,
    .wmrards-col-md-8-24 {
        width: 33.3333%
    }

    .wmrards-col-md-3-8,
    .wmrards-col-md-9-24 {
        width: 37.5%
    }

    .wmrards-col-md-2-5 {
        width: 40%
    }

    .wmrards-col-md-10-24,
    .wmrards-col-md-5-12 {
        width: 41.6667%
    }

    .wmrards-col-md-11-24 {
        width: 45.8333%
    }

    .wmrards-col-md-1-2,
    .wmrards-col-md-12-24 {
        width: 50%
    }

    .wmrards-col-md-13-24 {
        width: 54.1667%
    }

    .wmrards-col-md-14-24,
    .wmrards-col-md-7-12 {
        width: 58.3333%
    }

    .wmrards-col-md-3-5 {
        width: 60%
    }

    .wmrards-col-md-15-24,
    .wmrards-col-md-5-8 {
        width: 62.5%
    }

    .wmrards-col-md-16-24,
    .wmrards-col-md-2-3 {
        width: 66.6667%
    }

    .wmrards-col-md-17-24 {
        width: 70.8333%
    }

    .wmrards-col-md-18-24,
    .wmrards-col-md-3-4 {
        width: 75%
    }

    .wmrards-col-md-19-24 {
        width: 79.1667%
    }

    .wmrards-col-md-4-5 {
        width: 80%
    }

    .wmrards-col-md-20-24,
    .wmrards-col-md-5-6 {
        width: 83.3333%
    }

    .wmrards-col-md-21-24,
    .wmrards-col-md-7-8 {
        width: 87.5%
    }

    .wmrards-col-md-11-12,
    .wmrards-col-md-22-24 {
        width: 91.6667%
    }

    .wmrards-col-md-23-24 {
        width: 95.8333%
    }

    .wmrards-col-md-1,
    .wmrards-col-md-1-1,
    .wmrards-col-md-24-24,
    .wmrards-col-md-5-5 {
        width: 100%
    }

    .wmrards-grid--spacing-md-2-xs>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-md-2-xs>[class*=wmrards-col-]:nth-child(2n+1) {
        padding-right: .125rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-md-2-xs>[class*=wmrards-col-]:nth-child(2n) {
        padding-right: 0;
        padding-left: .125rem
    }
}

@media screen and (min-width:48em) and (min-width:48em) {

    .wmrards-grid--spacing-md-2-xs>[class*=wmrards-col-].wmrards-col-md-1,
    .wmrards-grid--spacing-md-2-xs>[class*=wmrards-col-].wmrards-col-md-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:48em) {

    .wmrards-grid--spacing-md-3-xs>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-md-3-xs>[class*=wmrards-col-]:nth-child(3n+1) {
        padding-right: .1666666667rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-md-3-xs>[class*=wmrards-col-]:nth-child(3n) {
        padding-right: 0;
        padding-left: .1666666667rem
    }

    .wmrards-grid--spacing-md-3-xs>[class*=wmrards-col-]:nth-child(3n-1) {
        padding-right: .0833333333rem;
        padding-left: .0833333333rem
    }
}

@media screen and (min-width:48em) and (min-width:48em) {

    .wmrards-grid--spacing-md-3-xs>[class*=wmrards-col-].wmrards-col-md-1,
    .wmrards-grid--spacing-md-3-xs>[class*=wmrards-col-].wmrards-col-md-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:48em) {

    .wmrards-grid--spacing-md-4-xs>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-md-4-xs>[class*=wmrards-col-]:nth-child(4n+1) {
        padding-right: .1875rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-md-4-xs>[class*=wmrards-col-]:nth-child(4n) {
        padding-right: 0;
        padding-left: .1875rem
    }

    .wmrards-grid--spacing-md-4-xs>[class*=wmrards-col-]:nth-child(4n-1) {
        padding-right: .0625rem;
        padding-left: .125rem
    }

    .wmrards-grid--spacing-md-4-xs>[class*=wmrards-col-]:nth-child(4n-2) {
        padding-right: .125rem;
        padding-left: .0625rem
    }
}

@media screen and (min-width:48em) and (min-width:48em) {

    .wmrards-grid--spacing-md-4-xs>[class*=wmrards-col-].wmrards-col-md-1,
    .wmrards-grid--spacing-md-4-xs>[class*=wmrards-col-].wmrards-col-md-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:48em) {

    .wmrards-grid--spacing-md-5-xs>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-md-5-xs>[class*=wmrards-col-]:nth-child(5n+1) {
        padding-right: .2rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-md-5-xs>[class*=wmrards-col-]:nth-child(5n) {
        padding-right: 0;
        padding-left: .2rem
    }

    .wmrards-grid--spacing-md-5-xs>[class*=wmrards-col-]:nth-child(5n-1) {
        padding-right: .05rem;
        padding-left: .15rem
    }

    .wmrards-grid--spacing-md-5-xs>[class*=wmrards-col-]:nth-child(5n-2) {
        padding-right: .1rem;
        padding-left: .1rem
    }

    .wmrards-grid--spacing-md-5-xs>[class*=wmrards-col-]:nth-child(5n-3) {
        padding-right: .15rem;
        padding-left: .05rem
    }
}

@media screen and (min-width:48em) and (min-width:48em) {

    .wmrards-grid--spacing-md-5-xs>[class*=wmrards-col-].wmrards-col-md-1,
    .wmrards-grid--spacing-md-5-xs>[class*=wmrards-col-].wmrards-col-md-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:48em) {

    .wmrards-grid--spacing-md-2-xsm>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-md-2-xsm>[class*=wmrards-col-]:nth-child(2n+1) {
        padding-right: .25rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-md-2-xsm>[class*=wmrards-col-]:nth-child(2n) {
        padding-right: 0;
        padding-left: .25rem
    }
}

@media screen and (min-width:48em) and (min-width:48em) {

    .wmrards-grid--spacing-md-2-xsm>[class*=wmrards-col-].wmrards-col-md-1,
    .wmrards-grid--spacing-md-2-xsm>[class*=wmrards-col-].wmrards-col-md-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:48em) {

    .wmrards-grid--spacing-md-3-xsm>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-md-3-xsm>[class*=wmrards-col-]:nth-child(3n+1) {
        padding-right: .3333333333rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-md-3-xsm>[class*=wmrards-col-]:nth-child(3n) {
        padding-right: 0;
        padding-left: .3333333333rem
    }

    .wmrards-grid--spacing-md-3-xsm>[class*=wmrards-col-]:nth-child(3n-1) {
        padding-right: .1666666667rem;
        padding-left: .1666666667rem
    }
}

@media screen and (min-width:48em) and (min-width:48em) {

    .wmrards-grid--spacing-md-3-xsm>[class*=wmrards-col-].wmrards-col-md-1,
    .wmrards-grid--spacing-md-3-xsm>[class*=wmrards-col-].wmrards-col-md-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:48em) {

    .wmrards-grid--spacing-md-4-xsm>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-md-4-xsm>[class*=wmrards-col-]:nth-child(4n+1) {
        padding-right: .375rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-md-4-xsm>[class*=wmrards-col-]:nth-child(4n) {
        padding-right: 0;
        padding-left: .375rem
    }

    .wmrards-grid--spacing-md-4-xsm>[class*=wmrards-col-]:nth-child(4n-1) {
        padding-right: .125rem;
        padding-left: .25rem
    }

    .wmrards-grid--spacing-md-4-xsm>[class*=wmrards-col-]:nth-child(4n-2) {
        padding-right: .25rem;
        padding-left: .125rem
    }
}

@media screen and (min-width:48em) and (min-width:48em) {

    .wmrards-grid--spacing-md-4-xsm>[class*=wmrards-col-].wmrards-col-md-1,
    .wmrards-grid--spacing-md-4-xsm>[class*=wmrards-col-].wmrards-col-md-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:48em) {

    .wmrards-grid--spacing-md-5-xsm>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-md-5-xsm>[class*=wmrards-col-]:nth-child(5n+1) {
        padding-right: .4rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-md-5-xsm>[class*=wmrards-col-]:nth-child(5n) {
        padding-right: 0;
        padding-left: .4rem
    }

    .wmrards-grid--spacing-md-5-xsm>[class*=wmrards-col-]:nth-child(5n-1) {
        padding-right: .1rem;
        padding-left: .3rem
    }

    .wmrards-grid--spacing-md-5-xsm>[class*=wmrards-col-]:nth-child(5n-2) {
        padding-right: .2rem;
        padding-left: .2rem
    }

    .wmrards-grid--spacing-md-5-xsm>[class*=wmrards-col-]:nth-child(5n-3) {
        padding-right: .3rem;
        padding-left: .1rem
    }
}

@media screen and (min-width:48em) and (min-width:48em) {

    .wmrards-grid--spacing-md-5-xsm>[class*=wmrards-col-].wmrards-col-md-1,
    .wmrards-grid--spacing-md-5-xsm>[class*=wmrards-col-].wmrards-col-md-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:48em) {

    .wmrards-grid--spacing-md-2-sm>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-md-2-sm>[class*=wmrards-col-]:nth-child(2n+1) {
        padding-right: .375rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-md-2-sm>[class*=wmrards-col-]:nth-child(2n) {
        padding-right: 0;
        padding-left: .375rem
    }
}

@media screen and (min-width:48em) and (min-width:48em) {

    .wmrards-grid--spacing-md-2-sm>[class*=wmrards-col-].wmrards-col-md-1,
    .wmrards-grid--spacing-md-2-sm>[class*=wmrards-col-].wmrards-col-md-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:48em) {

    .wmrards-grid--spacing-md-3-sm>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-md-3-sm>[class*=wmrards-col-]:nth-child(3n+1) {
        padding-right: .5rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-md-3-sm>[class*=wmrards-col-]:nth-child(3n) {
        padding-right: 0;
        padding-left: .5rem
    }

    .wmrards-grid--spacing-md-3-sm>[class*=wmrards-col-]:nth-child(3n-1) {
        padding-right: .25rem;
        padding-left: .25rem
    }
}

@media screen and (min-width:48em) and (min-width:48em) {

    .wmrards-grid--spacing-md-3-sm>[class*=wmrards-col-].wmrards-col-md-1,
    .wmrards-grid--spacing-md-3-sm>[class*=wmrards-col-].wmrards-col-md-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:48em) {

    .wmrards-grid--spacing-md-4-sm>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-md-4-sm>[class*=wmrards-col-]:nth-child(4n+1) {
        padding-right: .5625rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-md-4-sm>[class*=wmrards-col-]:nth-child(4n) {
        padding-right: 0;
        padding-left: .5625rem
    }

    .wmrards-grid--spacing-md-4-sm>[class*=wmrards-col-]:nth-child(4n-1) {
        padding-right: .1875rem;
        padding-left: .375rem
    }

    .wmrards-grid--spacing-md-4-sm>[class*=wmrards-col-]:nth-child(4n-2) {
        padding-right: .375rem;
        padding-left: .1875rem
    }
}

@media screen and (min-width:48em) and (min-width:48em) {

    .wmrards-grid--spacing-md-4-sm>[class*=wmrards-col-].wmrards-col-md-1,
    .wmrards-grid--spacing-md-4-sm>[class*=wmrards-col-].wmrards-col-md-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:48em) {

    .wmrards-grid--spacing-md-5-sm>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-md-5-sm>[class*=wmrards-col-]:nth-child(5n+1) {
        padding-right: .6rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-md-5-sm>[class*=wmrards-col-]:nth-child(5n) {
        padding-right: 0;
        padding-left: .6rem
    }

    .wmrards-grid--spacing-md-5-sm>[class*=wmrards-col-]:nth-child(5n-1) {
        padding-right: .15rem;
        padding-left: .45rem
    }

    .wmrards-grid--spacing-md-5-sm>[class*=wmrards-col-]:nth-child(5n-2) {
        padding-right: .3rem;
        padding-left: .3rem
    }

    .wmrards-grid--spacing-md-5-sm>[class*=wmrards-col-]:nth-child(5n-3) {
        padding-right: .45rem;
        padding-left: .15rem
    }
}

@media screen and (min-width:48em) and (min-width:48em) {

    .wmrards-grid--spacing-md-5-sm>[class*=wmrards-col-].wmrards-col-md-1,
    .wmrards-grid--spacing-md-5-sm>[class*=wmrards-col-].wmrards-col-md-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:48em) {

    .wmrards-grid--spacing-md-2-md>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-md-2-md>[class*=wmrards-col-]:nth-child(2n+1) {
        padding-right: .5rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-md-2-md>[class*=wmrards-col-]:nth-child(2n) {
        padding-right: 0;
        padding-left: .5rem
    }
}

@media screen and (min-width:48em) and (min-width:48em) {

    .wmrards-grid--spacing-md-2-md>[class*=wmrards-col-].wmrards-col-md-1,
    .wmrards-grid--spacing-md-2-md>[class*=wmrards-col-].wmrards-col-md-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:48em) {

    .wmrards-grid--spacing-md-3-md>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-md-3-md>[class*=wmrards-col-]:nth-child(3n+1) {
        padding-right: .6666666667rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-md-3-md>[class*=wmrards-col-]:nth-child(3n) {
        padding-right: 0;
        padding-left: .6666666667rem
    }

    .wmrards-grid--spacing-md-3-md>[class*=wmrards-col-]:nth-child(3n-1) {
        padding-right: .3333333333rem;
        padding-left: .3333333333rem
    }
}

@media screen and (min-width:48em) and (min-width:48em) {

    .wmrards-grid--spacing-md-3-md>[class*=wmrards-col-].wmrards-col-md-1,
    .wmrards-grid--spacing-md-3-md>[class*=wmrards-col-].wmrards-col-md-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:48em) {

    .wmrards-grid--spacing-md-4-md>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-md-4-md>[class*=wmrards-col-]:nth-child(4n+1) {
        padding-right: .75rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-md-4-md>[class*=wmrards-col-]:nth-child(4n) {
        padding-right: 0;
        padding-left: .75rem
    }

    .wmrards-grid--spacing-md-4-md>[class*=wmrards-col-]:nth-child(4n-1) {
        padding-right: .25rem;
        padding-left: .5rem
    }

    .wmrards-grid--spacing-md-4-md>[class*=wmrards-col-]:nth-child(4n-2) {
        padding-right: .5rem;
        padding-left: .25rem
    }
}

@media screen and (min-width:48em) and (min-width:48em) {

    .wmrards-grid--spacing-md-4-md>[class*=wmrards-col-].wmrards-col-md-1,
    .wmrards-grid--spacing-md-4-md>[class*=wmrards-col-].wmrards-col-md-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:48em) {

    .wmrards-grid--spacing-md-5-md>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-md-5-md>[class*=wmrards-col-]:nth-child(5n+1) {
        padding-right: .8rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-md-5-md>[class*=wmrards-col-]:nth-child(5n) {
        padding-right: 0;
        padding-left: .8rem
    }

    .wmrards-grid--spacing-md-5-md>[class*=wmrards-col-]:nth-child(5n-1) {
        padding-right: .2rem;
        padding-left: .6rem
    }

    .wmrards-grid--spacing-md-5-md>[class*=wmrards-col-]:nth-child(5n-2) {
        padding-right: .4rem;
        padding-left: .4rem
    }

    .wmrards-grid--spacing-md-5-md>[class*=wmrards-col-]:nth-child(5n-3) {
        padding-right: .6rem;
        padding-left: .2rem
    }
}

@media screen and (min-width:48em) and (min-width:48em) {

    .wmrards-grid--spacing-md-5-md>[class*=wmrards-col-].wmrards-col-md-1,
    .wmrards-grid--spacing-md-5-md>[class*=wmrards-col-].wmrards-col-md-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:48em) {

    .wmrards-grid--spacing-md-2-lg>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-md-2-lg>[class*=wmrards-col-]:nth-child(2n+1) {
        padding-right: 1rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-md-2-lg>[class*=wmrards-col-]:nth-child(2n) {
        padding-right: 0;
        padding-left: 1rem
    }
}

@media screen and (min-width:48em) and (min-width:48em) {

    .wmrards-grid--spacing-md-2-lg>[class*=wmrards-col-].wmrards-col-md-1,
    .wmrards-grid--spacing-md-2-lg>[class*=wmrards-col-].wmrards-col-md-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:48em) {

    .wmrards-grid--spacing-md-3-lg>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-md-3-lg>[class*=wmrards-col-]:nth-child(3n+1) {
        padding-right: 1.3333333333rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-md-3-lg>[class*=wmrards-col-]:nth-child(3n) {
        padding-right: 0;
        padding-left: 1.3333333333rem
    }

    .wmrards-grid--spacing-md-3-lg>[class*=wmrards-col-]:nth-child(3n-1) {
        padding-right: .6666666667rem;
        padding-left: .6666666667rem
    }
}

@media screen and (min-width:48em) and (min-width:48em) {

    .wmrards-grid--spacing-md-3-lg>[class*=wmrards-col-].wmrards-col-md-1,
    .wmrards-grid--spacing-md-3-lg>[class*=wmrards-col-].wmrards-col-md-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:48em) {

    .wmrards-grid--spacing-md-4-lg>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-md-4-lg>[class*=wmrards-col-]:nth-child(4n+1) {
        padding-right: 1.5rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-md-4-lg>[class*=wmrards-col-]:nth-child(4n) {
        padding-right: 0;
        padding-left: 1.5rem
    }

    .wmrards-grid--spacing-md-4-lg>[class*=wmrards-col-]:nth-child(4n-1) {
        padding-right: .5rem;
        padding-left: 1rem
    }

    .wmrards-grid--spacing-md-4-lg>[class*=wmrards-col-]:nth-child(4n-2) {
        padding-right: 1rem;
        padding-left: .5rem
    }
}

@media screen and (min-width:48em) and (min-width:48em) {

    .wmrards-grid--spacing-md-4-lg>[class*=wmrards-col-].wmrards-col-md-1,
    .wmrards-grid--spacing-md-4-lg>[class*=wmrards-col-].wmrards-col-md-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:48em) {

    .wmrards-grid--spacing-md-5-lg>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-md-5-lg>[class*=wmrards-col-]:nth-child(5n+1) {
        padding-right: 1.6rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-md-5-lg>[class*=wmrards-col-]:nth-child(5n) {
        padding-right: 0;
        padding-left: 1.6rem
    }

    .wmrards-grid--spacing-md-5-lg>[class*=wmrards-col-]:nth-child(5n-1) {
        padding-right: .4rem;
        padding-left: 1.2rem
    }

    .wmrards-grid--spacing-md-5-lg>[class*=wmrards-col-]:nth-child(5n-2) {
        padding-right: .8rem;
        padding-left: .8rem
    }

    .wmrards-grid--spacing-md-5-lg>[class*=wmrards-col-]:nth-child(5n-3) {
        padding-right: 1.2rem;
        padding-left: .4rem
    }
}

@media screen and (min-width:48em) and (min-width:48em) {

    .wmrards-grid--spacing-md-5-lg>[class*=wmrards-col-].wmrards-col-md-1,
    .wmrards-grid--spacing-md-5-lg>[class*=wmrards-col-].wmrards-col-md-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:48em) {

    .wmrards-grid--spacing-md-2-xl>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-md-2-xl>[class*=wmrards-col-]:nth-child(2n+1) {
        padding-right: 1.5rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-md-2-xl>[class*=wmrards-col-]:nth-child(2n) {
        padding-right: 0;
        padding-left: 1.5rem
    }
}

@media screen and (min-width:48em) and (min-width:48em) {

    .wmrards-grid--spacing-md-2-xl>[class*=wmrards-col-].wmrards-col-md-1,
    .wmrards-grid--spacing-md-2-xl>[class*=wmrards-col-].wmrards-col-md-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:48em) {

    .wmrards-grid--spacing-md-3-xl>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-md-3-xl>[class*=wmrards-col-]:nth-child(3n+1) {
        padding-right: 2rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-md-3-xl>[class*=wmrards-col-]:nth-child(3n) {
        padding-right: 0;
        padding-left: 2rem
    }

    .wmrards-grid--spacing-md-3-xl>[class*=wmrards-col-]:nth-child(3n-1) {
        padding-right: 1rem;
        padding-left: 1rem
    }
}

@media screen and (min-width:48em) and (min-width:48em) {

    .wmrards-grid--spacing-md-3-xl>[class*=wmrards-col-].wmrards-col-md-1,
    .wmrards-grid--spacing-md-3-xl>[class*=wmrards-col-].wmrards-col-md-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:48em) {

    .wmrards-grid--spacing-md-4-xl>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-md-4-xl>[class*=wmrards-col-]:nth-child(4n+1) {
        padding-right: 2.25rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-md-4-xl>[class*=wmrards-col-]:nth-child(4n) {
        padding-right: 0;
        padding-left: 2.25rem
    }

    .wmrards-grid--spacing-md-4-xl>[class*=wmrards-col-]:nth-child(4n-1) {
        padding-right: .75rem;
        padding-left: 1.5rem
    }

    .wmrards-grid--spacing-md-4-xl>[class*=wmrards-col-]:nth-child(4n-2) {
        padding-right: 1.5rem;
        padding-left: .75rem
    }
}

@media screen and (min-width:48em) and (min-width:48em) {

    .wmrards-grid--spacing-md-4-xl>[class*=wmrards-col-].wmrards-col-md-1,
    .wmrards-grid--spacing-md-4-xl>[class*=wmrards-col-].wmrards-col-md-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:48em) {

    .wmrards-grid--spacing-md-5-xl>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-md-5-xl>[class*=wmrards-col-]:nth-child(5n+1) {
        padding-right: 2.4rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-md-5-xl>[class*=wmrards-col-]:nth-child(5n) {
        padding-right: 0;
        padding-left: 2.4rem
    }

    .wmrards-grid--spacing-md-5-xl>[class*=wmrards-col-]:nth-child(5n-1) {
        padding-right: .6rem;
        padding-left: 1.8rem
    }

    .wmrards-grid--spacing-md-5-xl>[class*=wmrards-col-]:nth-child(5n-2) {
        padding-right: 1.2rem;
        padding-left: 1.2rem
    }

    .wmrards-grid--spacing-md-5-xl>[class*=wmrards-col-]:nth-child(5n-3) {
        padding-right: 1.8rem;
        padding-left: .6rem
    }
}

@media screen and (min-width:48em) and (min-width:48em) {

    .wmrards-grid--spacing-md-5-xl>[class*=wmrards-col-].wmrards-col-md-1,
    .wmrards-grid--spacing-md-5-xl>[class*=wmrards-col-].wmrards-col-md-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:62em) {

    .wmrards-col-lg-1,
    .wmrards-col-lg-1-1,
    .wmrards-col-lg-1-12,
    .wmrards-col-lg-1-2,
    .wmrards-col-lg-1-24,
    .wmrards-col-lg-1-3,
    .wmrards-col-lg-1-4,
    .wmrards-col-lg-1-5,
    .wmrards-col-lg-1-6,
    .wmrards-col-lg-1-8,
    .wmrards-col-lg-10-24,
    .wmrards-col-lg-11-12,
    .wmrards-col-lg-11-24,
    .wmrards-col-lg-12-24,
    .wmrards-col-lg-13-24,
    .wmrards-col-lg-14-24,
    .wmrards-col-lg-15-24,
    .wmrards-col-lg-16-24,
    .wmrards-col-lg-17-24,
    .wmrards-col-lg-18-24,
    .wmrards-col-lg-19-24,
    .wmrards-col-lg-2-24,
    .wmrards-col-lg-2-3,
    .wmrards-col-lg-2-5,
    .wmrards-col-lg-20-24,
    .wmrards-col-lg-21-24,
    .wmrards-col-lg-22-24,
    .wmrards-col-lg-23-24,
    .wmrards-col-lg-24-24,
    .wmrards-col-lg-3-24,
    .wmrards-col-lg-3-4,
    .wmrards-col-lg-3-5,
    .wmrards-col-lg-3-8,
    .wmrards-col-lg-4-24,
    .wmrards-col-lg-4-5,
    .wmrards-col-lg-5-12,
    .wmrards-col-lg-5-24,
    .wmrards-col-lg-5-5,
    .wmrards-col-lg-5-6,
    .wmrards-col-lg-5-8,
    .wmrards-col-lg-6-24,
    .wmrards-col-lg-7-12,
    .wmrards-col-lg-7-24,
    .wmrards-col-lg-7-8,
    .wmrards-col-lg-8-24,
    .wmrards-col-lg-9-24,
    .wmrards-col-lg-auto {
        display: inline-block;
        zoom: 1;
        letter-spacing: normal;
        word-spacing: normal;
        text-rendering: auto;
        vertical-align: top
    }

    .wmrards-col-lg-auto {
        width: auto
    }

    .wmrards-col-lg-1-24 {
        width: 4.1667%
    }

    .wmrards-col-lg-1-12,
    .wmrards-col-lg-2-24 {
        width: 8.3333%
    }

    .wmrards-col-lg-1-8,
    .wmrards-col-lg-3-24 {
        width: 12.5%
    }

    .wmrards-col-lg-1-6,
    .wmrards-col-lg-4-24 {
        width: 16.6667%
    }

    .wmrards-col-lg-1-5 {
        width: 20%
    }

    .wmrards-col-lg-5-24 {
        width: 20.8333%
    }

    .wmrards-col-lg-1-4,
    .wmrards-col-lg-6-24 {
        width: 25%
    }

    .wmrards-col-lg-7-24 {
        width: 29.1667%
    }

    .wmrards-col-lg-1-3,
    .wmrards-col-lg-8-24 {
        width: 33.3333%
    }

    .wmrards-col-lg-3-8,
    .wmrards-col-lg-9-24 {
        width: 37.5%
    }

    .wmrards-col-lg-2-5 {
        width: 40%
    }

    .wmrards-col-lg-10-24,
    .wmrards-col-lg-5-12 {
        width: 41.6667%
    }

    .wmrards-col-lg-11-24 {
        width: 45.8333%
    }

    .wmrards-col-lg-1-2,
    .wmrards-col-lg-12-24 {
        width: 50%
    }

    .wmrards-col-lg-13-24 {
        width: 54.1667%
    }

    .wmrards-col-lg-14-24,
    .wmrards-col-lg-7-12 {
        width: 58.3333%
    }

    .wmrards-col-lg-3-5 {
        width: 60%
    }

    .wmrards-col-lg-15-24,
    .wmrards-col-lg-5-8 {
        width: 62.5%
    }

    .wmrards-col-lg-16-24,
    .wmrards-col-lg-2-3 {
        width: 66.6667%
    }

    .wmrards-col-lg-17-24 {
        width: 70.8333%
    }

    .wmrards-col-lg-18-24,
    .wmrards-col-lg-3-4 {
        width: 75%
    }

    .wmrards-col-lg-19-24 {
        width: 79.1667%
    }

    .wmrards-col-lg-4-5 {
        width: 80%
    }

    .wmrards-col-lg-20-24,
    .wmrards-col-lg-5-6 {
        width: 83.3333%
    }

    .wmrards-col-lg-21-24,
    .wmrards-col-lg-7-8 {
        width: 87.5%
    }

    .wmrards-col-lg-11-12,
    .wmrards-col-lg-22-24 {
        width: 91.6667%
    }

    .wmrards-col-lg-23-24 {
        width: 95.8333%
    }

    .wmrards-col-lg-1,
    .wmrards-col-lg-1-1,
    .wmrards-col-lg-24-24,
    .wmrards-col-lg-5-5 {
        width: 100%
    }

    .wmrards-grid--spacing-lg-2-xs>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-lg-2-xs>[class*=wmrards-col-]:nth-child(2n+1) {
        padding-right: .125rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-lg-2-xs>[class*=wmrards-col-]:nth-child(2n) {
        padding-right: 0;
        padding-left: .125rem
    }
}

@media screen and (min-width:62em) and (min-width:62em) {

    .wmrards-grid--spacing-lg-2-xs>[class*=wmrards-col-].wmrards-col-lg-1,
    .wmrards-grid--spacing-lg-2-xs>[class*=wmrards-col-].wmrards-col-lg-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:62em) {

    .wmrards-grid--spacing-lg-3-xs>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-lg-3-xs>[class*=wmrards-col-]:nth-child(3n+1) {
        padding-right: .1666666667rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-lg-3-xs>[class*=wmrards-col-]:nth-child(3n) {
        padding-right: 0;
        padding-left: .1666666667rem
    }

    .wmrards-grid--spacing-lg-3-xs>[class*=wmrards-col-]:nth-child(3n-1) {
        padding-right: .0833333333rem;
        padding-left: .0833333333rem
    }
}

@media screen and (min-width:62em) and (min-width:62em) {

    .wmrards-grid--spacing-lg-3-xs>[class*=wmrards-col-].wmrards-col-lg-1,
    .wmrards-grid--spacing-lg-3-xs>[class*=wmrards-col-].wmrards-col-lg-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:62em) {

    .wmrards-grid--spacing-lg-4-xs>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-lg-4-xs>[class*=wmrards-col-]:nth-child(4n+1) {
        padding-right: .1875rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-lg-4-xs>[class*=wmrards-col-]:nth-child(4n) {
        padding-right: 0;
        padding-left: .1875rem
    }

    .wmrards-grid--spacing-lg-4-xs>[class*=wmrards-col-]:nth-child(4n-1) {
        padding-right: .0625rem;
        padding-left: .125rem
    }

    .wmrards-grid--spacing-lg-4-xs>[class*=wmrards-col-]:nth-child(4n-2) {
        padding-right: .125rem;
        padding-left: .0625rem
    }
}

@media screen and (min-width:62em) and (min-width:62em) {

    .wmrards-grid--spacing-lg-4-xs>[class*=wmrards-col-].wmrards-col-lg-1,
    .wmrards-grid--spacing-lg-4-xs>[class*=wmrards-col-].wmrards-col-lg-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:62em) {

    .wmrards-grid--spacing-lg-5-xs>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-lg-5-xs>[class*=wmrards-col-]:nth-child(5n+1) {
        padding-right: .2rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-lg-5-xs>[class*=wmrards-col-]:nth-child(5n) {
        padding-right: 0;
        padding-left: .2rem
    }

    .wmrards-grid--spacing-lg-5-xs>[class*=wmrards-col-]:nth-child(5n-1) {
        padding-right: .05rem;
        padding-left: .15rem
    }

    .wmrards-grid--spacing-lg-5-xs>[class*=wmrards-col-]:nth-child(5n-2) {
        padding-right: .1rem;
        padding-left: .1rem
    }

    .wmrards-grid--spacing-lg-5-xs>[class*=wmrards-col-]:nth-child(5n-3) {
        padding-right: .15rem;
        padding-left: .05rem
    }
}

@media screen and (min-width:62em) and (min-width:62em) {

    .wmrards-grid--spacing-lg-5-xs>[class*=wmrards-col-].wmrards-col-lg-1,
    .wmrards-grid--spacing-lg-5-xs>[class*=wmrards-col-].wmrards-col-lg-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:62em) {

    .wmrards-grid--spacing-lg-2-xsm>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-lg-2-xsm>[class*=wmrards-col-]:nth-child(2n+1) {
        padding-right: .25rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-lg-2-xsm>[class*=wmrards-col-]:nth-child(2n) {
        padding-right: 0;
        padding-left: .25rem
    }
}

@media screen and (min-width:62em) and (min-width:62em) {

    .wmrards-grid--spacing-lg-2-xsm>[class*=wmrards-col-].wmrards-col-lg-1,
    .wmrards-grid--spacing-lg-2-xsm>[class*=wmrards-col-].wmrards-col-lg-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:62em) {

    .wmrards-grid--spacing-lg-3-xsm>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-lg-3-xsm>[class*=wmrards-col-]:nth-child(3n+1) {
        padding-right: .3333333333rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-lg-3-xsm>[class*=wmrards-col-]:nth-child(3n) {
        padding-right: 0;
        padding-left: .3333333333rem
    }

    .wmrards-grid--spacing-lg-3-xsm>[class*=wmrards-col-]:nth-child(3n-1) {
        padding-right: .1666666667rem;
        padding-left: .1666666667rem
    }
}

@media screen and (min-width:62em) and (min-width:62em) {

    .wmrards-grid--spacing-lg-3-xsm>[class*=wmrards-col-].wmrards-col-lg-1,
    .wmrards-grid--spacing-lg-3-xsm>[class*=wmrards-col-].wmrards-col-lg-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:62em) {

    .wmrards-grid--spacing-lg-4-xsm>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-lg-4-xsm>[class*=wmrards-col-]:nth-child(4n+1) {
        padding-right: .375rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-lg-4-xsm>[class*=wmrards-col-]:nth-child(4n) {
        padding-right: 0;
        padding-left: .375rem
    }

    .wmrards-grid--spacing-lg-4-xsm>[class*=wmrards-col-]:nth-child(4n-1) {
        padding-right: .125rem;
        padding-left: .25rem
    }

    .wmrards-grid--spacing-lg-4-xsm>[class*=wmrards-col-]:nth-child(4n-2) {
        padding-right: .25rem;
        padding-left: .125rem
    }
}

@media screen and (min-width:62em) and (min-width:62em) {

    .wmrards-grid--spacing-lg-4-xsm>[class*=wmrards-col-].wmrards-col-lg-1,
    .wmrards-grid--spacing-lg-4-xsm>[class*=wmrards-col-].wmrards-col-lg-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:62em) {

    .wmrards-grid--spacing-lg-5-xsm>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-lg-5-xsm>[class*=wmrards-col-]:nth-child(5n+1) {
        padding-right: .4rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-lg-5-xsm>[class*=wmrards-col-]:nth-child(5n) {
        padding-right: 0;
        padding-left: .4rem
    }

    .wmrards-grid--spacing-lg-5-xsm>[class*=wmrards-col-]:nth-child(5n-1) {
        padding-right: .1rem;
        padding-left: .3rem
    }

    .wmrards-grid--spacing-lg-5-xsm>[class*=wmrards-col-]:nth-child(5n-2) {
        padding-right: .2rem;
        padding-left: .2rem
    }

    .wmrards-grid--spacing-lg-5-xsm>[class*=wmrards-col-]:nth-child(5n-3) {
        padding-right: .3rem;
        padding-left: .1rem
    }
}

@media screen and (min-width:62em) and (min-width:62em) {

    .wmrards-grid--spacing-lg-5-xsm>[class*=wmrards-col-].wmrards-col-lg-1,
    .wmrards-grid--spacing-lg-5-xsm>[class*=wmrards-col-].wmrards-col-lg-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:62em) {

    .wmrards-grid--spacing-lg-2-sm>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-lg-2-sm>[class*=wmrards-col-]:nth-child(2n+1) {
        padding-right: .375rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-lg-2-sm>[class*=wmrards-col-]:nth-child(2n) {
        padding-right: 0;
        padding-left: .375rem
    }
}

@media screen and (min-width:62em) and (min-width:62em) {

    .wmrards-grid--spacing-lg-2-sm>[class*=wmrards-col-].wmrards-col-lg-1,
    .wmrards-grid--spacing-lg-2-sm>[class*=wmrards-col-].wmrards-col-lg-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:62em) {

    .wmrards-grid--spacing-lg-3-sm>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-lg-3-sm>[class*=wmrards-col-]:nth-child(3n+1) {
        padding-right: .5rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-lg-3-sm>[class*=wmrards-col-]:nth-child(3n) {
        padding-right: 0;
        padding-left: .5rem
    }

    .wmrards-grid--spacing-lg-3-sm>[class*=wmrards-col-]:nth-child(3n-1) {
        padding-right: .25rem;
        padding-left: .25rem
    }
}

@media screen and (min-width:62em) and (min-width:62em) {

    .wmrards-grid--spacing-lg-3-sm>[class*=wmrards-col-].wmrards-col-lg-1,
    .wmrards-grid--spacing-lg-3-sm>[class*=wmrards-col-].wmrards-col-lg-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:62em) {

    .wmrards-grid--spacing-lg-4-sm>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-lg-4-sm>[class*=wmrards-col-]:nth-child(4n+1) {
        padding-right: .5625rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-lg-4-sm>[class*=wmrards-col-]:nth-child(4n) {
        padding-right: 0;
        padding-left: .5625rem
    }

    .wmrards-grid--spacing-lg-4-sm>[class*=wmrards-col-]:nth-child(4n-1) {
        padding-right: .1875rem;
        padding-left: .375rem
    }

    .wmrards-grid--spacing-lg-4-sm>[class*=wmrards-col-]:nth-child(4n-2) {
        padding-right: .375rem;
        padding-left: .1875rem
    }
}

@media screen and (min-width:62em) and (min-width:62em) {

    .wmrards-grid--spacing-lg-4-sm>[class*=wmrards-col-].wmrards-col-lg-1,
    .wmrards-grid--spacing-lg-4-sm>[class*=wmrards-col-].wmrards-col-lg-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:62em) {

    .wmrards-grid--spacing-lg-5-sm>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-lg-5-sm>[class*=wmrards-col-]:nth-child(5n+1) {
        padding-right: .6rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-lg-5-sm>[class*=wmrards-col-]:nth-child(5n) {
        padding-right: 0;
        padding-left: .6rem
    }

    .wmrards-grid--spacing-lg-5-sm>[class*=wmrards-col-]:nth-child(5n-1) {
        padding-right: .15rem;
        padding-left: .45rem
    }

    .wmrards-grid--spacing-lg-5-sm>[class*=wmrards-col-]:nth-child(5n-2) {
        padding-right: .3rem;
        padding-left: .3rem
    }

    .wmrards-grid--spacing-lg-5-sm>[class*=wmrards-col-]:nth-child(5n-3) {
        padding-right: .45rem;
        padding-left: .15rem
    }
}

@media screen and (min-width:62em) and (min-width:62em) {

    .wmrards-grid--spacing-lg-5-sm>[class*=wmrards-col-].wmrards-col-lg-1,
    .wmrards-grid--spacing-lg-5-sm>[class*=wmrards-col-].wmrards-col-lg-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:62em) {

    .wmrards-grid--spacing-lg-2-md>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-lg-2-md>[class*=wmrards-col-]:nth-child(2n+1) {
        padding-right: .5rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-lg-2-md>[class*=wmrards-col-]:nth-child(2n) {
        padding-right: 0;
        padding-left: .5rem
    }
}

@media screen and (min-width:62em) and (min-width:62em) {

    .wmrards-grid--spacing-lg-2-md>[class*=wmrards-col-].wmrards-col-lg-1,
    .wmrards-grid--spacing-lg-2-md>[class*=wmrards-col-].wmrards-col-lg-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:62em) {

    .wmrards-grid--spacing-lg-3-md>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-lg-3-md>[class*=wmrards-col-]:nth-child(3n+1) {
        padding-right: .6666666667rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-lg-3-md>[class*=wmrards-col-]:nth-child(3n) {
        padding-right: 0;
        padding-left: .6666666667rem
    }

    .wmrards-grid--spacing-lg-3-md>[class*=wmrards-col-]:nth-child(3n-1) {
        padding-right: .3333333333rem;
        padding-left: .3333333333rem
    }
}

@media screen and (min-width:62em) and (min-width:62em) {

    .wmrards-grid--spacing-lg-3-md>[class*=wmrards-col-].wmrards-col-lg-1,
    .wmrards-grid--spacing-lg-3-md>[class*=wmrards-col-].wmrards-col-lg-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:62em) {

    .wmrards-grid--spacing-lg-4-md>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-lg-4-md>[class*=wmrards-col-]:nth-child(4n+1) {
        padding-right: .75rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-lg-4-md>[class*=wmrards-col-]:nth-child(4n) {
        padding-right: 0;
        padding-left: .75rem
    }

    .wmrards-grid--spacing-lg-4-md>[class*=wmrards-col-]:nth-child(4n-1) {
        padding-right: .25rem;
        padding-left: .5rem
    }

    .wmrards-grid--spacing-lg-4-md>[class*=wmrards-col-]:nth-child(4n-2) {
        padding-right: .5rem;
        padding-left: .25rem
    }
}

@media screen and (min-width:62em) and (min-width:62em) {

    .wmrards-grid--spacing-lg-4-md>[class*=wmrards-col-].wmrards-col-lg-1,
    .wmrards-grid--spacing-lg-4-md>[class*=wmrards-col-].wmrards-col-lg-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:62em) {

    .wmrards-grid--spacing-lg-5-md>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-lg-5-md>[class*=wmrards-col-]:nth-child(5n+1) {
        padding-right: .8rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-lg-5-md>[class*=wmrards-col-]:nth-child(5n) {
        padding-right: 0;
        padding-left: .8rem
    }

    .wmrards-grid--spacing-lg-5-md>[class*=wmrards-col-]:nth-child(5n-1) {
        padding-right: .2rem;
        padding-left: .6rem
    }

    .wmrards-grid--spacing-lg-5-md>[class*=wmrards-col-]:nth-child(5n-2) {
        padding-right: .4rem;
        padding-left: .4rem
    }

    .wmrards-grid--spacing-lg-5-md>[class*=wmrards-col-]:nth-child(5n-3) {
        padding-right: .6rem;
        padding-left: .2rem
    }
}

@media screen and (min-width:62em) and (min-width:62em) {

    .wmrards-grid--spacing-lg-5-md>[class*=wmrards-col-].wmrards-col-lg-1,
    .wmrards-grid--spacing-lg-5-md>[class*=wmrards-col-].wmrards-col-lg-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:62em) {

    .wmrards-grid--spacing-lg-2-lg>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-lg-2-lg>[class*=wmrards-col-]:nth-child(2n+1) {
        padding-right: 1rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-lg-2-lg>[class*=wmrards-col-]:nth-child(2n) {
        padding-right: 0;
        padding-left: 1rem
    }
}

@media screen and (min-width:62em) and (min-width:62em) {

    .wmrards-grid--spacing-lg-2-lg>[class*=wmrards-col-].wmrards-col-lg-1,
    .wmrards-grid--spacing-lg-2-lg>[class*=wmrards-col-].wmrards-col-lg-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:62em) {

    .wmrards-grid--spacing-lg-3-lg>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-lg-3-lg>[class*=wmrards-col-]:nth-child(3n+1) {
        padding-right: 1.3333333333rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-lg-3-lg>[class*=wmrards-col-]:nth-child(3n) {
        padding-right: 0;
        padding-left: 1.3333333333rem
    }

    .wmrards-grid--spacing-lg-3-lg>[class*=wmrards-col-]:nth-child(3n-1) {
        padding-right: .6666666667rem;
        padding-left: .6666666667rem
    }
}

@media screen and (min-width:62em) and (min-width:62em) {

    .wmrards-grid--spacing-lg-3-lg>[class*=wmrards-col-].wmrards-col-lg-1,
    .wmrards-grid--spacing-lg-3-lg>[class*=wmrards-col-].wmrards-col-lg-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:62em) {

    .wmrards-grid--spacing-lg-4-lg>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-lg-4-lg>[class*=wmrards-col-]:nth-child(4n+1) {
        padding-right: 1.5rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-lg-4-lg>[class*=wmrards-col-]:nth-child(4n) {
        padding-right: 0;
        padding-left: 1.5rem
    }

    .wmrards-grid--spacing-lg-4-lg>[class*=wmrards-col-]:nth-child(4n-1) {
        padding-right: .5rem;
        padding-left: 1rem
    }

    .wmrards-grid--spacing-lg-4-lg>[class*=wmrards-col-]:nth-child(4n-2) {
        padding-right: 1rem;
        padding-left: .5rem
    }
}

@media screen and (min-width:62em) and (min-width:62em) {

    .wmrards-grid--spacing-lg-4-lg>[class*=wmrards-col-].wmrards-col-lg-1,
    .wmrards-grid--spacing-lg-4-lg>[class*=wmrards-col-].wmrards-col-lg-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:62em) {

    .wmrards-grid--spacing-lg-5-lg>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-lg-5-lg>[class*=wmrards-col-]:nth-child(5n+1) {
        padding-right: 1.6rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-lg-5-lg>[class*=wmrards-col-]:nth-child(5n) {
        padding-right: 0;
        padding-left: 1.6rem
    }

    .wmrards-grid--spacing-lg-5-lg>[class*=wmrards-col-]:nth-child(5n-1) {
        padding-right: .4rem;
        padding-left: 1.2rem
    }

    .wmrards-grid--spacing-lg-5-lg>[class*=wmrards-col-]:nth-child(5n-2) {
        padding-right: .8rem;
        padding-left: .8rem
    }

    .wmrards-grid--spacing-lg-5-lg>[class*=wmrards-col-]:nth-child(5n-3) {
        padding-right: 1.2rem;
        padding-left: .4rem
    }
}

@media screen and (min-width:62em) and (min-width:62em) {

    .wmrards-grid--spacing-lg-5-lg>[class*=wmrards-col-].wmrards-col-lg-1,
    .wmrards-grid--spacing-lg-5-lg>[class*=wmrards-col-].wmrards-col-lg-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:62em) {

    .wmrards-grid--spacing-lg-2-xl>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-lg-2-xl>[class*=wmrards-col-]:nth-child(2n+1) {
        padding-right: 1.5rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-lg-2-xl>[class*=wmrards-col-]:nth-child(2n) {
        padding-right: 0;
        padding-left: 1.5rem
    }
}

@media screen and (min-width:62em) and (min-width:62em) {

    .wmrards-grid--spacing-lg-2-xl>[class*=wmrards-col-].wmrards-col-lg-1,
    .wmrards-grid--spacing-lg-2-xl>[class*=wmrards-col-].wmrards-col-lg-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:62em) {

    .wmrards-grid--spacing-lg-3-xl>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-lg-3-xl>[class*=wmrards-col-]:nth-child(3n+1) {
        padding-right: 2rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-lg-3-xl>[class*=wmrards-col-]:nth-child(3n) {
        padding-right: 0;
        padding-left: 2rem
    }

    .wmrards-grid--spacing-lg-3-xl>[class*=wmrards-col-]:nth-child(3n-1) {
        padding-right: 1rem;
        padding-left: 1rem
    }
}

@media screen and (min-width:62em) and (min-width:62em) {

    .wmrards-grid--spacing-lg-3-xl>[class*=wmrards-col-].wmrards-col-lg-1,
    .wmrards-grid--spacing-lg-3-xl>[class*=wmrards-col-].wmrards-col-lg-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:62em) {

    .wmrards-grid--spacing-lg-4-xl>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-lg-4-xl>[class*=wmrards-col-]:nth-child(4n+1) {
        padding-right: 2.25rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-lg-4-xl>[class*=wmrards-col-]:nth-child(4n) {
        padding-right: 0;
        padding-left: 2.25rem
    }

    .wmrards-grid--spacing-lg-4-xl>[class*=wmrards-col-]:nth-child(4n-1) {
        padding-right: .75rem;
        padding-left: 1.5rem
    }

    .wmrards-grid--spacing-lg-4-xl>[class*=wmrards-col-]:nth-child(4n-2) {
        padding-right: 1.5rem;
        padding-left: .75rem
    }
}

@media screen and (min-width:62em) and (min-width:62em) {

    .wmrards-grid--spacing-lg-4-xl>[class*=wmrards-col-].wmrards-col-lg-1,
    .wmrards-grid--spacing-lg-4-xl>[class*=wmrards-col-].wmrards-col-lg-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:62em) {

    .wmrards-grid--spacing-lg-5-xl>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-lg-5-xl>[class*=wmrards-col-]:nth-child(5n+1) {
        padding-right: 2.4rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-lg-5-xl>[class*=wmrards-col-]:nth-child(5n) {
        padding-right: 0;
        padding-left: 2.4rem
    }

    .wmrards-grid--spacing-lg-5-xl>[class*=wmrards-col-]:nth-child(5n-1) {
        padding-right: .6rem;
        padding-left: 1.8rem
    }

    .wmrards-grid--spacing-lg-5-xl>[class*=wmrards-col-]:nth-child(5n-2) {
        padding-right: 1.2rem;
        padding-left: 1.2rem
    }

    .wmrards-grid--spacing-lg-5-xl>[class*=wmrards-col-]:nth-child(5n-3) {
        padding-right: 1.8rem;
        padding-left: .6rem
    }
}

@media screen and (min-width:62em) and (min-width:62em) {

    .wmrards-grid--spacing-lg-5-xl>[class*=wmrards-col-].wmrards-col-lg-1,
    .wmrards-grid--spacing-lg-5-xl>[class*=wmrards-col-].wmrards-col-lg-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:80em) {

    .wmrards-col-xl-1,
    .wmrards-col-xl-1-1,
    .wmrards-col-xl-1-12,
    .wmrards-col-xl-1-2,
    .wmrards-col-xl-1-24,
    .wmrards-col-xl-1-3,
    .wmrards-col-xl-1-4,
    .wmrards-col-xl-1-5,
    .wmrards-col-xl-1-6,
    .wmrards-col-xl-1-8,
    .wmrards-col-xl-10-24,
    .wmrards-col-xl-11-12,
    .wmrards-col-xl-11-24,
    .wmrards-col-xl-12-24,
    .wmrards-col-xl-13-24,
    .wmrards-col-xl-14-24,
    .wmrards-col-xl-15-24,
    .wmrards-col-xl-16-24,
    .wmrards-col-xl-17-24,
    .wmrards-col-xl-18-24,
    .wmrards-col-xl-19-24,
    .wmrards-col-xl-2-24,
    .wmrards-col-xl-2-3,
    .wmrards-col-xl-2-5,
    .wmrards-col-xl-20-24,
    .wmrards-col-xl-21-24,
    .wmrards-col-xl-22-24,
    .wmrards-col-xl-23-24,
    .wmrards-col-xl-24-24,
    .wmrards-col-xl-3-24,
    .wmrards-col-xl-3-4,
    .wmrards-col-xl-3-5,
    .wmrards-col-xl-3-8,
    .wmrards-col-xl-4-24,
    .wmrards-col-xl-4-5,
    .wmrards-col-xl-5-12,
    .wmrards-col-xl-5-24,
    .wmrards-col-xl-5-5,
    .wmrards-col-xl-5-6,
    .wmrards-col-xl-5-8,
    .wmrards-col-xl-6-24,
    .wmrards-col-xl-7-12,
    .wmrards-col-xl-7-24,
    .wmrards-col-xl-7-8,
    .wmrards-col-xl-8-24,
    .wmrards-col-xl-9-24,
    .wmrards-col-xl-auto {
        display: inline-block;
        zoom: 1;
        letter-spacing: normal;
        word-spacing: normal;
        text-rendering: auto;
        vertical-align: top
    }

    .wmrards-col-xl-auto {
        width: auto
    }

    .wmrards-col-xl-1-24 {
        width: 4.1667%
    }

    .wmrards-col-xl-1-12,
    .wmrards-col-xl-2-24 {
        width: 8.3333%
    }

    .wmrards-col-xl-1-8,
    .wmrards-col-xl-3-24 {
        width: 12.5%
    }

    .wmrards-col-xl-1-6,
    .wmrards-col-xl-4-24 {
        width: 16.6667%
    }

    .wmrards-col-xl-1-5 {
        width: 20%
    }

    .wmrards-col-xl-5-24 {
        width: 20.8333%
    }

    .wmrards-col-xl-1-4,
    .wmrards-col-xl-6-24 {
        width: 25%
    }

    .wmrards-col-xl-7-24 {
        width: 29.1667%
    }

    .wmrards-col-xl-1-3,
    .wmrards-col-xl-8-24 {
        width: 33.3333%
    }

    .wmrards-col-xl-3-8,
    .wmrards-col-xl-9-24 {
        width: 37.5%
    }

    .wmrards-col-xl-2-5 {
        width: 40%
    }

    .wmrards-col-xl-10-24,
    .wmrards-col-xl-5-12 {
        width: 41.6667%
    }

    .wmrards-col-xl-11-24 {
        width: 45.8333%
    }

    .wmrards-col-xl-1-2,
    .wmrards-col-xl-12-24 {
        width: 50%
    }

    .wmrards-col-xl-13-24 {
        width: 54.1667%
    }

    .wmrards-col-xl-14-24,
    .wmrards-col-xl-7-12 {
        width: 58.3333%
    }

    .wmrards-col-xl-3-5 {
        width: 60%
    }

    .wmrards-col-xl-15-24,
    .wmrards-col-xl-5-8 {
        width: 62.5%
    }

    .wmrards-col-xl-16-24,
    .wmrards-col-xl-2-3 {
        width: 66.6667%
    }

    .wmrards-col-xl-17-24 {
        width: 70.8333%
    }

    .wmrards-col-xl-18-24,
    .wmrards-col-xl-3-4 {
        width: 75%
    }

    .wmrards-col-xl-19-24 {
        width: 79.1667%
    }

    .wmrards-col-xl-4-5 {
        width: 80%
    }

    .wmrards-col-xl-20-24,
    .wmrards-col-xl-5-6 {
        width: 83.3333%
    }

    .wmrards-col-xl-21-24,
    .wmrards-col-xl-7-8 {
        width: 87.5%
    }

    .wmrards-col-xl-11-12,
    .wmrards-col-xl-22-24 {
        width: 91.6667%
    }

    .wmrards-col-xl-23-24 {
        width: 95.8333%
    }

    .wmrards-col-xl-1,
    .wmrards-col-xl-1-1,
    .wmrards-col-xl-24-24,
    .wmrards-col-xl-5-5 {
        width: 100%
    }

    .wmrards-grid--spacing-xl-2-xs>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-xl-2-xs>[class*=wmrards-col-]:nth-child(2n+1) {
        padding-right: .125rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-xl-2-xs>[class*=wmrards-col-]:nth-child(2n) {
        padding-right: 0;
        padding-left: .125rem
    }
}

@media screen and (min-width:80em) and (min-width:80em) {

    .wmrards-grid--spacing-xl-2-xs>[class*=wmrards-col-].wmrards-col-xl-1,
    .wmrards-grid--spacing-xl-2-xs>[class*=wmrards-col-].wmrards-col-xl-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:80em) {

    .wmrards-grid--spacing-xl-3-xs>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-xl-3-xs>[class*=wmrards-col-]:nth-child(3n+1) {
        padding-right: .1666666667rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-xl-3-xs>[class*=wmrards-col-]:nth-child(3n) {
        padding-right: 0;
        padding-left: .1666666667rem
    }

    .wmrards-grid--spacing-xl-3-xs>[class*=wmrards-col-]:nth-child(3n-1) {
        padding-right: .0833333333rem;
        padding-left: .0833333333rem
    }
}

@media screen and (min-width:80em) and (min-width:80em) {

    .wmrards-grid--spacing-xl-3-xs>[class*=wmrards-col-].wmrards-col-xl-1,
    .wmrards-grid--spacing-xl-3-xs>[class*=wmrards-col-].wmrards-col-xl-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:80em) {

    .wmrards-grid--spacing-xl-4-xs>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-xl-4-xs>[class*=wmrards-col-]:nth-child(4n+1) {
        padding-right: .1875rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-xl-4-xs>[class*=wmrards-col-]:nth-child(4n) {
        padding-right: 0;
        padding-left: .1875rem
    }

    .wmrards-grid--spacing-xl-4-xs>[class*=wmrards-col-]:nth-child(4n-1) {
        padding-right: .0625rem;
        padding-left: .125rem
    }

    .wmrards-grid--spacing-xl-4-xs>[class*=wmrards-col-]:nth-child(4n-2) {
        padding-right: .125rem;
        padding-left: .0625rem
    }
}

@media screen and (min-width:80em) and (min-width:80em) {

    .wmrards-grid--spacing-xl-4-xs>[class*=wmrards-col-].wmrards-col-xl-1,
    .wmrards-grid--spacing-xl-4-xs>[class*=wmrards-col-].wmrards-col-xl-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:80em) {

    .wmrards-grid--spacing-xl-5-xs>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-xl-5-xs>[class*=wmrards-col-]:nth-child(5n+1) {
        padding-right: .2rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-xl-5-xs>[class*=wmrards-col-]:nth-child(5n) {
        padding-right: 0;
        padding-left: .2rem
    }

    .wmrards-grid--spacing-xl-5-xs>[class*=wmrards-col-]:nth-child(5n-1) {
        padding-right: .05rem;
        padding-left: .15rem
    }

    .wmrards-grid--spacing-xl-5-xs>[class*=wmrards-col-]:nth-child(5n-2) {
        padding-right: .1rem;
        padding-left: .1rem
    }

    .wmrards-grid--spacing-xl-5-xs>[class*=wmrards-col-]:nth-child(5n-3) {
        padding-right: .15rem;
        padding-left: .05rem
    }
}

@media screen and (min-width:80em) and (min-width:80em) {

    .wmrards-grid--spacing-xl-5-xs>[class*=wmrards-col-].wmrards-col-xl-1,
    .wmrards-grid--spacing-xl-5-xs>[class*=wmrards-col-].wmrards-col-xl-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:80em) {

    .wmrards-grid--spacing-xl-2-xsm>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-xl-2-xsm>[class*=wmrards-col-]:nth-child(2n+1) {
        padding-right: .25rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-xl-2-xsm>[class*=wmrards-col-]:nth-child(2n) {
        padding-right: 0;
        padding-left: .25rem
    }
}

@media screen and (min-width:80em) and (min-width:80em) {

    .wmrards-grid--spacing-xl-2-xsm>[class*=wmrards-col-].wmrards-col-xl-1,
    .wmrards-grid--spacing-xl-2-xsm>[class*=wmrards-col-].wmrards-col-xl-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:80em) {

    .wmrards-grid--spacing-xl-3-xsm>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-xl-3-xsm>[class*=wmrards-col-]:nth-child(3n+1) {
        padding-right: .3333333333rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-xl-3-xsm>[class*=wmrards-col-]:nth-child(3n) {
        padding-right: 0;
        padding-left: .3333333333rem
    }

    .wmrards-grid--spacing-xl-3-xsm>[class*=wmrards-col-]:nth-child(3n-1) {
        padding-right: .1666666667rem;
        padding-left: .1666666667rem
    }
}

@media screen and (min-width:80em) and (min-width:80em) {

    .wmrards-grid--spacing-xl-3-xsm>[class*=wmrards-col-].wmrards-col-xl-1,
    .wmrards-grid--spacing-xl-3-xsm>[class*=wmrards-col-].wmrards-col-xl-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:80em) {

    .wmrards-grid--spacing-xl-4-xsm>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-xl-4-xsm>[class*=wmrards-col-]:nth-child(4n+1) {
        padding-right: .375rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-xl-4-xsm>[class*=wmrards-col-]:nth-child(4n) {
        padding-right: 0;
        padding-left: .375rem
    }

    .wmrards-grid--spacing-xl-4-xsm>[class*=wmrards-col-]:nth-child(4n-1) {
        padding-right: .125rem;
        padding-left: .25rem
    }

    .wmrards-grid--spacing-xl-4-xsm>[class*=wmrards-col-]:nth-child(4n-2) {
        padding-right: .25rem;
        padding-left: .125rem
    }
}

@media screen and (min-width:80em) and (min-width:80em) {

    .wmrards-grid--spacing-xl-4-xsm>[class*=wmrards-col-].wmrards-col-xl-1,
    .wmrards-grid--spacing-xl-4-xsm>[class*=wmrards-col-].wmrards-col-xl-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:80em) {

    .wmrards-grid--spacing-xl-5-xsm>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-xl-5-xsm>[class*=wmrards-col-]:nth-child(5n+1) {
        padding-right: .4rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-xl-5-xsm>[class*=wmrards-col-]:nth-child(5n) {
        padding-right: 0;
        padding-left: .4rem
    }

    .wmrards-grid--spacing-xl-5-xsm>[class*=wmrards-col-]:nth-child(5n-1) {
        padding-right: .1rem;
        padding-left: .3rem
    }

    .wmrards-grid--spacing-xl-5-xsm>[class*=wmrards-col-]:nth-child(5n-2) {
        padding-right: .2rem;
        padding-left: .2rem
    }

    .wmrards-grid--spacing-xl-5-xsm>[class*=wmrards-col-]:nth-child(5n-3) {
        padding-right: .3rem;
        padding-left: .1rem
    }
}

@media screen and (min-width:80em) and (min-width:80em) {

    .wmrards-grid--spacing-xl-5-xsm>[class*=wmrards-col-].wmrards-col-xl-1,
    .wmrards-grid--spacing-xl-5-xsm>[class*=wmrards-col-].wmrards-col-xl-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:80em) {

    .wmrards-grid--spacing-xl-2-sm>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-xl-2-sm>[class*=wmrards-col-]:nth-child(2n+1) {
        padding-right: .375rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-xl-2-sm>[class*=wmrards-col-]:nth-child(2n) {
        padding-right: 0;
        padding-left: .375rem
    }
}

@media screen and (min-width:80em) and (min-width:80em) {

    .wmrards-grid--spacing-xl-2-sm>[class*=wmrards-col-].wmrards-col-xl-1,
    .wmrards-grid--spacing-xl-2-sm>[class*=wmrards-col-].wmrards-col-xl-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:80em) {

    .wmrards-grid--spacing-xl-3-sm>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-xl-3-sm>[class*=wmrards-col-]:nth-child(3n+1) {
        padding-right: .5rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-xl-3-sm>[class*=wmrards-col-]:nth-child(3n) {
        padding-right: 0;
        padding-left: .5rem
    }

    .wmrards-grid--spacing-xl-3-sm>[class*=wmrards-col-]:nth-child(3n-1) {
        padding-right: .25rem;
        padding-left: .25rem
    }
}

@media screen and (min-width:80em) and (min-width:80em) {

    .wmrards-grid--spacing-xl-3-sm>[class*=wmrards-col-].wmrards-col-xl-1,
    .wmrards-grid--spacing-xl-3-sm>[class*=wmrards-col-].wmrards-col-xl-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:80em) {

    .wmrards-grid--spacing-xl-4-sm>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-xl-4-sm>[class*=wmrards-col-]:nth-child(4n+1) {
        padding-right: .5625rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-xl-4-sm>[class*=wmrards-col-]:nth-child(4n) {
        padding-right: 0;
        padding-left: .5625rem
    }

    .wmrards-grid--spacing-xl-4-sm>[class*=wmrards-col-]:nth-child(4n-1) {
        padding-right: .1875rem;
        padding-left: .375rem
    }

    .wmrards-grid--spacing-xl-4-sm>[class*=wmrards-col-]:nth-child(4n-2) {
        padding-right: .375rem;
        padding-left: .1875rem
    }
}

@media screen and (min-width:80em) and (min-width:80em) {

    .wmrards-grid--spacing-xl-4-sm>[class*=wmrards-col-].wmrards-col-xl-1,
    .wmrards-grid--spacing-xl-4-sm>[class*=wmrards-col-].wmrards-col-xl-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:80em) {

    .wmrards-grid--spacing-xl-5-sm>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-xl-5-sm>[class*=wmrards-col-]:nth-child(5n+1) {
        padding-right: .6rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-xl-5-sm>[class*=wmrards-col-]:nth-child(5n) {
        padding-right: 0;
        padding-left: .6rem
    }

    .wmrards-grid--spacing-xl-5-sm>[class*=wmrards-col-]:nth-child(5n-1) {
        padding-right: .15rem;
        padding-left: .45rem
    }

    .wmrards-grid--spacing-xl-5-sm>[class*=wmrards-col-]:nth-child(5n-2) {
        padding-right: .3rem;
        padding-left: .3rem
    }

    .wmrards-grid--spacing-xl-5-sm>[class*=wmrards-col-]:nth-child(5n-3) {
        padding-right: .45rem;
        padding-left: .15rem
    }
}

@media screen and (min-width:80em) and (min-width:80em) {

    .wmrards-grid--spacing-xl-5-sm>[class*=wmrards-col-].wmrards-col-xl-1,
    .wmrards-grid--spacing-xl-5-sm>[class*=wmrards-col-].wmrards-col-xl-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:80em) {

    .wmrards-grid--spacing-xl-2-md>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-xl-2-md>[class*=wmrards-col-]:nth-child(2n+1) {
        padding-right: .5rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-xl-2-md>[class*=wmrards-col-]:nth-child(2n) {
        padding-right: 0;
        padding-left: .5rem
    }
}

@media screen and (min-width:80em) and (min-width:80em) {

    .wmrards-grid--spacing-xl-2-md>[class*=wmrards-col-].wmrards-col-xl-1,
    .wmrards-grid--spacing-xl-2-md>[class*=wmrards-col-].wmrards-col-xl-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:80em) {

    .wmrards-grid--spacing-xl-3-md>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-xl-3-md>[class*=wmrards-col-]:nth-child(3n+1) {
        padding-right: .6666666667rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-xl-3-md>[class*=wmrards-col-]:nth-child(3n) {
        padding-right: 0;
        padding-left: .6666666667rem
    }

    .wmrards-grid--spacing-xl-3-md>[class*=wmrards-col-]:nth-child(3n-1) {
        padding-right: .3333333333rem;
        padding-left: .3333333333rem
    }
}

@media screen and (min-width:80em) and (min-width:80em) {

    .wmrards-grid--spacing-xl-3-md>[class*=wmrards-col-].wmrards-col-xl-1,
    .wmrards-grid--spacing-xl-3-md>[class*=wmrards-col-].wmrards-col-xl-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:80em) {

    .wmrards-grid--spacing-xl-4-md>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-xl-4-md>[class*=wmrards-col-]:nth-child(4n+1) {
        padding-right: .75rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-xl-4-md>[class*=wmrards-col-]:nth-child(4n) {
        padding-right: 0;
        padding-left: .75rem
    }

    .wmrards-grid--spacing-xl-4-md>[class*=wmrards-col-]:nth-child(4n-1) {
        padding-right: .25rem;
        padding-left: .5rem
    }

    .wmrards-grid--spacing-xl-4-md>[class*=wmrards-col-]:nth-child(4n-2) {
        padding-right: .5rem;
        padding-left: .25rem
    }
}

@media screen and (min-width:80em) and (min-width:80em) {

    .wmrards-grid--spacing-xl-4-md>[class*=wmrards-col-].wmrards-col-xl-1,
    .wmrards-grid--spacing-xl-4-md>[class*=wmrards-col-].wmrards-col-xl-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:80em) {

    .wmrards-grid--spacing-xl-5-md>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-xl-5-md>[class*=wmrards-col-]:nth-child(5n+1) {
        padding-right: .8rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-xl-5-md>[class*=wmrards-col-]:nth-child(5n) {
        padding-right: 0;
        padding-left: .8rem
    }

    .wmrards-grid--spacing-xl-5-md>[class*=wmrards-col-]:nth-child(5n-1) {
        padding-right: .2rem;
        padding-left: .6rem
    }

    .wmrards-grid--spacing-xl-5-md>[class*=wmrards-col-]:nth-child(5n-2) {
        padding-right: .4rem;
        padding-left: .4rem
    }

    .wmrards-grid--spacing-xl-5-md>[class*=wmrards-col-]:nth-child(5n-3) {
        padding-right: .6rem;
        padding-left: .2rem
    }
}

@media screen and (min-width:80em) and (min-width:80em) {

    .wmrards-grid--spacing-xl-5-md>[class*=wmrards-col-].wmrards-col-xl-1,
    .wmrards-grid--spacing-xl-5-md>[class*=wmrards-col-].wmrards-col-xl-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:80em) {

    .wmrards-grid--spacing-xl-2-lg>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-xl-2-lg>[class*=wmrards-col-]:nth-child(2n+1) {
        padding-right: 1rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-xl-2-lg>[class*=wmrards-col-]:nth-child(2n) {
        padding-right: 0;
        padding-left: 1rem
    }
}

@media screen and (min-width:80em) and (min-width:80em) {

    .wmrards-grid--spacing-xl-2-lg>[class*=wmrards-col-].wmrards-col-xl-1,
    .wmrards-grid--spacing-xl-2-lg>[class*=wmrards-col-].wmrards-col-xl-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:80em) {

    .wmrards-grid--spacing-xl-3-lg>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-xl-3-lg>[class*=wmrards-col-]:nth-child(3n+1) {
        padding-right: 1.3333333333rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-xl-3-lg>[class*=wmrards-col-]:nth-child(3n) {
        padding-right: 0;
        padding-left: 1.3333333333rem
    }

    .wmrards-grid--spacing-xl-3-lg>[class*=wmrards-col-]:nth-child(3n-1) {
        padding-right: .6666666667rem;
        padding-left: .6666666667rem
    }
}

@media screen and (min-width:80em) and (min-width:80em) {

    .wmrards-grid--spacing-xl-3-lg>[class*=wmrards-col-].wmrards-col-xl-1,
    .wmrards-grid--spacing-xl-3-lg>[class*=wmrards-col-].wmrards-col-xl-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:80em) {

    .wmrards-grid--spacing-xl-4-lg>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-xl-4-lg>[class*=wmrards-col-]:nth-child(4n+1) {
        padding-right: 1.5rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-xl-4-lg>[class*=wmrards-col-]:nth-child(4n) {
        padding-right: 0;
        padding-left: 1.5rem
    }

    .wmrards-grid--spacing-xl-4-lg>[class*=wmrards-col-]:nth-child(4n-1) {
        padding-right: .5rem;
        padding-left: 1rem
    }

    .wmrards-grid--spacing-xl-4-lg>[class*=wmrards-col-]:nth-child(4n-2) {
        padding-right: 1rem;
        padding-left: .5rem
    }
}

@media screen and (min-width:80em) and (min-width:80em) {

    .wmrards-grid--spacing-xl-4-lg>[class*=wmrards-col-].wmrards-col-xl-1,
    .wmrards-grid--spacing-xl-4-lg>[class*=wmrards-col-].wmrards-col-xl-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:80em) {

    .wmrards-grid--spacing-xl-5-lg>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-xl-5-lg>[class*=wmrards-col-]:nth-child(5n+1) {
        padding-right: 1.6rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-xl-5-lg>[class*=wmrards-col-]:nth-child(5n) {
        padding-right: 0;
        padding-left: 1.6rem
    }

    .wmrards-grid--spacing-xl-5-lg>[class*=wmrards-col-]:nth-child(5n-1) {
        padding-right: .4rem;
        padding-left: 1.2rem
    }

    .wmrards-grid--spacing-xl-5-lg>[class*=wmrards-col-]:nth-child(5n-2) {
        padding-right: .8rem;
        padding-left: .8rem
    }

    .wmrards-grid--spacing-xl-5-lg>[class*=wmrards-col-]:nth-child(5n-3) {
        padding-right: 1.2rem;
        padding-left: .4rem
    }
}

@media screen and (min-width:80em) and (min-width:80em) {

    .wmrards-grid--spacing-xl-5-lg>[class*=wmrards-col-].wmrards-col-xl-1,
    .wmrards-grid--spacing-xl-5-lg>[class*=wmrards-col-].wmrards-col-xl-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:80em) {

    .wmrards-grid--spacing-xl-2-xl>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-xl-2-xl>[class*=wmrards-col-]:nth-child(2n+1) {
        padding-right: 1.5rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-xl-2-xl>[class*=wmrards-col-]:nth-child(2n) {
        padding-right: 0;
        padding-left: 1.5rem
    }
}

@media screen and (min-width:80em) and (min-width:80em) {

    .wmrards-grid--spacing-xl-2-xl>[class*=wmrards-col-].wmrards-col-xl-1,
    .wmrards-grid--spacing-xl-2-xl>[class*=wmrards-col-].wmrards-col-xl-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:80em) {

    .wmrards-grid--spacing-xl-3-xl>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-xl-3-xl>[class*=wmrards-col-]:nth-child(3n+1) {
        padding-right: 2rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-xl-3-xl>[class*=wmrards-col-]:nth-child(3n) {
        padding-right: 0;
        padding-left: 2rem
    }

    .wmrards-grid--spacing-xl-3-xl>[class*=wmrards-col-]:nth-child(3n-1) {
        padding-right: 1rem;
        padding-left: 1rem
    }
}

@media screen and (min-width:80em) and (min-width:80em) {

    .wmrards-grid--spacing-xl-3-xl>[class*=wmrards-col-].wmrards-col-xl-1,
    .wmrards-grid--spacing-xl-3-xl>[class*=wmrards-col-].wmrards-col-xl-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:80em) {

    .wmrards-grid--spacing-xl-4-xl>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-xl-4-xl>[class*=wmrards-col-]:nth-child(4n+1) {
        padding-right: 2.25rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-xl-4-xl>[class*=wmrards-col-]:nth-child(4n) {
        padding-right: 0;
        padding-left: 2.25rem
    }

    .wmrards-grid--spacing-xl-4-xl>[class*=wmrards-col-]:nth-child(4n-1) {
        padding-right: .75rem;
        padding-left: 1.5rem
    }

    .wmrards-grid--spacing-xl-4-xl>[class*=wmrards-col-]:nth-child(4n-2) {
        padding-right: 1.5rem;
        padding-left: .75rem
    }
}

@media screen and (min-width:80em) and (min-width:80em) {

    .wmrards-grid--spacing-xl-4-xl>[class*=wmrards-col-].wmrards-col-xl-1,
    .wmrards-grid--spacing-xl-4-xl>[class*=wmrards-col-].wmrards-col-xl-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

@media screen and (min-width:80em) {

    .wmrards-grid--spacing-xl-5-xl>[class*=wmrards-col-]:first-child,
    .wmrards-grid--spacing-xl-5-xl>[class*=wmrards-col-]:nth-child(5n+1) {
        padding-right: 2.4rem;
        padding-left: 0
    }

    .wmrards-grid--spacing-xl-5-xl>[class*=wmrards-col-]:nth-child(5n) {
        padding-right: 0;
        padding-left: 2.4rem
    }

    .wmrards-grid--spacing-xl-5-xl>[class*=wmrards-col-]:nth-child(5n-1) {
        padding-right: .6rem;
        padding-left: 1.8rem
    }

    .wmrards-grid--spacing-xl-5-xl>[class*=wmrards-col-]:nth-child(5n-2) {
        padding-right: 1.2rem;
        padding-left: 1.2rem
    }

    .wmrards-grid--spacing-xl-5-xl>[class*=wmrards-col-]:nth-child(5n-3) {
        padding-right: 1.8rem;
        padding-left: .6rem
    }
}

@media screen and (min-width:80em) and (min-width:80em) {

    .wmrards-grid--spacing-xl-5-xl>[class*=wmrards-col-].wmrards-col-xl-1,
    .wmrards-grid--spacing-xl-5-xl>[class*=wmrards-col-].wmrards-col-xl-1-1 {
        padding-right: 0;
        padding-left: 0
    }
}

[class*=wmrards-].wmrards-accordion {
    display: block;
    margin: 15px 0;
    background-color: #fff
}

[class*=wmrards-].wmrards-accordion--with-border {
    border: 1px solid #841922
}

[class*=wmrards-].wmrards-accordion__summary-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding: 1rem;
    border: 0;
    background: 0 0;
    text-align: left;
    cursor: pointer;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 1rem;
    line-height: 1.5rem
}

[class*=wmrards-].wmrards-accordion__summary-wrapper .wmrards-n-icon__svg {
    width: .75rem;
    height: calc(.7855635925rem)
}

[class*=wmrards-].wmrards-accordion__summary-wrapper:active {
    color: #080607
}

[class*=wmrards-].wmrards-accordion__summary {
    display: inline-block;
    width: calc(100% - 32px);
    padding-right: 1rem;
    vertical-align: middle
}

[class*=wmrards-].wmrards-accordion__summary .wmrards-accordion__heading-text,
[class*=wmrards-].wmrards-accordion__summary>h4 {
    margin-bottom: 0
}

[class*=wmrards-].wmrards-accordion__icon {
    width: 32px;
    height: 32px;
    fill: #841922
}

[class*=wmrards-].wmrards-accordion__content {
    padding: 1rem
}

[class*=wmrards-].wmrards-accordion.wmrards-is--open .wmrards-accordion__icon,
[class*=wmrards-].wmrards-accordion__content,
[class*=wmrards-].wmrards-accordion__icon--minimise {
    display: none
}

[class*=wmrards-].wmrards-accordion.wmrards-is--open .wmrards-accordion__content,
[class*=wmrards-].wmrards-accordion.wmrards-is--open .wmrards-accordion__icon--minimise {
    display: block
}

[class*=wmrards-].wmrards-breadcrumb {
    padding: 1rem 0;
    background: 0 0
}

[class*=wmrards-].wmrards-breadcrumb__list {
    margin: 0;
    padding-left: 0;
    list-style: none
}

[class*=wmrards-].wmrards-breadcrumb__list-item {
    display: inline-block;
    margin-bottom: 0
}

[class*=wmrards-].wmrards-breadcrumb__list-item:not(:first-child)::before {
    content: ">";
    display: inline-block;
    height: .8em;
    margin: 0 .25rem;
    color: #84329b
}

[class*=wmrards-].wmrards-breadcrumb__link {
    color: #080607
}

[class*=wmrards-].wmrards-breadcrumb__link--current {
    text-decoration: none
}

[class*=wmrards-].wmrards-breadcrumb__link:hover {
    color: #060405
}

[class*=wmrards-].wmrards-breadcrumb--mobile-app {
    display: none
}

@media screen and (min-width:768px) {
    html body [class*=wmrards-].wmrards-hide-desktop {
        display: none !important
    }

    [class*=wmrards-].wmrards-breadcrumb--mobile-app {
        display: block
    }

    [class*=wmrards-].wmrards-msg-summary__close+.wmrards-msg-summary__header {
        padding-right: 6rem
    }
}

[class*=wmrards-].wmrards-btn {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    position: relative;
    min-height: 50px;
    padding: .25rem .5rem;
    -webkit-transition: background-color .2s ease-in-out, border .2s ease-in-out;
    transition: background-color .2s ease-in-out, border .2s ease-in-out;
    border: 1px solid transparent;
    border-radius: 5px;
    color: #fff;
    background-color: #1d7bbf;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.3rem;
    text-decoration: none;
    cursor: pointer;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

[class*=wmrards-].wmrards-btn:hover {
    background-color: #145686
}

[class*=wmrards-].wmrards-btn.wmrards-is--active,
[class*=wmrards-].wmrards-btn:active,
[class*=wmrards-].wmrards-btn:focus,
[class*=wmrards-].wmrards-btn:target {
    outline: 0;
    background-color: #0f3e60;
    -webkit-appearance: none
}

[class*=wmrards-].wmrards-btn__icon {
    width: 22px;
    height: 22px;
    fill: #fff;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 22px;
    flex: 0 0 22px
}

[class*=wmrards-].wmrards-btn__icon--left {
    margin-right: .25rem
}

[class*=wmrards-].wmrards-btn .wmrards-swift-logo-inline {
    fill: #fff
}

[class*=wmrards-].wmrards-btn__icon--right {
    margin-left: .75rem
}

[class*=wmrards-].wmrards-btn--dark-bg {
    color: #841922;
    background-color: #fff
}

[class*=wmrards-].wmrards-btn--dark-bg .wmrards-btn__icon,
[class*=wmrards-].wmrards-btn--dark-bg .wmrards-swift-logo-inline {
    fill: #841922
}

[class*=wmrards-].wmrards-btn--dark-bg:hover {
    background-color: #f3e8e9
}

[class*=wmrards-].wmrards-btn--dark-bg:active,
[class*=wmrards-].wmrards-btn--dark-bg:focus,
[class*=wmrards-].wmrards-btn--dark-bg:target {
    background-color: #fd0
}

[class*=wmrards-].wmrards-btn--dark-bg.wmrards-is--active {
    background-color: #e6d1d3
}

[class*=wmrards-].wmrards-btn--primary {
    background-color: #841922
}

[class*=wmrards-].wmrards-btn--primary:hover {
    background-color: #5c1218
}

[class*=wmrards-].wmrards-btn--primary.wmrards-is--active,
[class*=wmrards-].wmrards-btn--primary:active,
[class*=wmrards-].wmrards-btn--primary:focus,
[class*=wmrards-].wmrards-btn--primary:target {
    background-color: #420d11
}

[class*=wmrards-].wmrards-btn--secondary {
    border: 1px solid #841922;
    color: #841922;
    background-color: transparent
}

[class*=wmrards-].wmrards-btn--secondary .wmrards-btn__icon,
[class*=wmrards-].wmrards-btn--secondary .wmrards-swift-logo-inline {
    fill: #841922
}

[class*=wmrards-].wmrards-btn--secondary:hover {
    background-color: #f7bec0
}

[class*=wmrards-].wmrards-btn--secondary:active,
[class*=wmrards-].wmrards-btn--secondary:focus,
[class*=wmrards-].wmrards-btn--secondary:target {
    background-color: initial
}

[class*=wmrards-].wmrards-btn--secondary.wmrards-is--active {
    background-color: #f29396
}

[class*=wmrards-].wmrards-btn--secondary.wmrards-btn--disabled,
[class*=wmrards-].wmrards-btn--secondary.wmrards-btn--disabled:disabled {
    border-color: #676869;
    color: #676869;
    background: 0 0
}

[class*=wmrards-].wmrards-btn--secondary.wmrards-btn--disabled .wmrards-btn__icon,
[class*=wmrards-].wmrards-btn--secondary.wmrards-btn--disabled .wmrards-swift-logo-inline,
[class*=wmrards-].wmrards-btn--secondary.wmrards-btn--disabled:disabled .wmrards-btn__icon,
[class*=wmrards-].wmrards-btn--secondary.wmrards-btn--disabled:disabled .wmrards-swift-logo-inline {
    fill: #676869
}

[class*=wmrards-].wmrards-btn--secondary.wmrards-btn--dark-bg {
    border: 0;
    color: #fff;
    background-color: #841922
}

[class*=wmrards-].wmrards-btn--secondary.wmrards-btn--dark-bg .wmrards-btn__icon,
[class*=wmrards-].wmrards-btn--secondary.wmrards-btn--dark-bg .wmrards-swift-logo-inline {
    fill: #fff
}

[class*=wmrards-].wmrards-btn--secondary.wmrards-btn--dark-bg:hover {
    background-color: #903038
}

[class*=wmrards-].wmrards-btn--secondary.wmrards-btn--dark-bg:active,
[class*=wmrards-].wmrards-btn--secondary.wmrards-btn--dark-bg:focus,
[class*=wmrards-].wmrards-btn--secondary.wmrards-btn--dark-bg:target {
    color: #841922;
    background-color: #fd0
}

[class*=wmrards-].wmrards-btn--secondary.wmrards-btn--dark-bg.wmrards-is--active {
    color: #fff;
    background-color: #a95e64
}

[class*=wmrards-].wmrards-btn--destructive {
    background-color: #d4351c
}

[class*=wmrards-].wmrards-btn--destructive:hover {
    background-color: #942514
}

[class*=wmrards-].wmrards-btn--destructive.wmrards-is--active,
[class*=wmrards-].wmrards-btn--destructive:active,
[class*=wmrards-].wmrards-btn--destructive:focus,
[class*=wmrards-].wmrards-btn--destructive:target {
    background-color: #6a1b0e
}

[class*=wmrards-].wmrards-btn--start {
    background-color: #00703c
}

[class*=wmrards-].wmrards-btn--start span {
    border: 1px solid transparent
}

[class*=wmrards-].wmrards-btn--start:hover {
    background-color: #004e2a
}

[class*=wmrards-].wmrards-btn--start.wmrards-is--active,
[class*=wmrards-].wmrards-btn--start:active,
[class*=wmrards-].wmrards-btn--start:focus,
[class*=wmrards-].wmrards-btn--start:target {
    background-color: #00381e
}

[class*=wmrards-].wmrards-btn--mode {
    border: 1px solid #841922;
    color: #841922;
    background-color: #fff
}

[class*=wmrards-].wmrards-btn--mode .wmrards-btn__icon,
[class*=wmrards-].wmrards-btn--mode .wmrards-swift-logo-inline {
    margin-right: .25rem;
    fill: #841922
}

[class*=wmrards-].wmrards-btn--mode:not(.wmrards-text-align-center),
[class*=wmrards-].wmrards-btn--mode:not(.wmrards-text-align-left),
[class*=wmrards-].wmrards-btn--mode:not(.wmrards-text-align-right) {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

[class*=wmrards-].wmrards-btn--mode:not(.wmrards-text-align-center) .wmrards-btn__icon--right,
[class*=wmrards-].wmrards-btn--mode:not(.wmrards-text-align-left) .wmrards-btn__icon--right,
[class*=wmrards-].wmrards-btn--mode:not(.wmrards-text-align-right) .wmrards-btn__icon--right {
    margin-left: auto
}

[class*=wmrards-].wmrards-btn--mode .wmrards-btn__coloured-icon--bus {
    fill: #ea0029
}

[class*=wmrards-].wmrards-btn--mode .wmrards-btn__coloured-icon--rail,
[class*=wmrards-].wmrards-btn--mode .wmrards-btn__coloured-icon--train {
    fill: #e07709
}

[class*=wmrards-].wmrards-btn--mode .wmrards-btn__coloured-icon--metro,
[class*=wmrards-].wmrards-btn--mode .wmrards-btn__coloured-icon--tram {
    fill: #0075c9
}

[class*=wmrards-].wmrards-btn--mode .wmrards-btn__coloured-icon--cycle,
[class*=wmrards-].wmrards-btn--mode .wmrards-btn__coloured-icon--walk {
    fill: #658d1b
}

[class*=wmrards-].wmrards-btn--mode:hover {
    background-color: #f7bec0
}

[class*=wmrards-].wmrards-btn--mode:active,
[class*=wmrards-].wmrards-btn--mode:focus,
[class*=wmrards-].wmrards-btn--mode:target {
    background-color: initial
}

[class*=wmrards-].wmrards-btn--mode.wmrards-is--active {
    background-color: #f29396
}

[class*=wmrards-].wmrards-btn--mode.wmrards-btn--disabled:disabled {
    border-color: #676869;
    color: #676869;
    background: #fff
}

[class*=wmrards-].wmrards-btn--mode.wmrards-btn--disabled:disabled .wmrards-btn__icon,
[class*=wmrards-].wmrards-btn--mode.wmrards-btn--disabled:disabled .wmrards-swift-logo-inline {
    fill: #676869
}

[class*=wmrards-].wmrards-btn--disabled:disabled {
    background-color: #676869;
    cursor: not-allowed
}

[class*=wmrards-].wmrards-btn--disabled:disabled .wmrards-btn__icon,
[class*=wmrards-].wmrards-btn--disabled:disabled .wmrards-swift-logo-inline {
    fill: #fff
}

[class*=wmrards-].wmrards-btn--disabled:disabled.wmrards-is--active,
[class*=wmrards-].wmrards-btn--disabled:disabled:active,
[class*=wmrards-].wmrards-btn--disabled:disabled:focus,
[class*=wmrards-].wmrards-btn--disabled:disabled:target {
    background-color: #676869
}

[class*=wmrards-].wmrards-btn--favourite {
    min-height: 0;
    padding: 0;
    border-radius: 0;
    color: #080607;
    background: 0 0;
    font-weight: 400;
    line-height: 1;
    cursor: pointer;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

[class*=wmrards-].wmrards-btn--favourite .wmrards-btn__icon {
    margin-right: .5rem;
    fill: #84329b
}

[class*=wmrards-].wmrards-btn--favourite.wmrards-is--active,
[class*=wmrards-].wmrards-btn--favourite:active,
[class*=wmrards-].wmrards-btn--favourite:focus,
[class*=wmrards-].wmrards-btn--favourite:hover,
[class*=wmrards-].wmrards-btn--favourite:target {
    background: 0 0
}

[class*=wmrards-].wmrards-btn--block {
    width: 100%
}

[class*=wmrards-].wmrards-btn--align-left {
    text-align: left
}

[class*=wmrards-].wmrards-btn.wmrards-text-align-left {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

[class*=wmrards-].wmrards-btn.wmrards-text-align-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

[class*=wmrards-].wmrards-btn.wmrards-text-align-right {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

[class*=wmrards-].wmrards-btn--link {
    display: inline;
    min-height: 0;
    padding: 0;
    border-radius: 0;
    color: #1a6fac;
    background: 0 0;
    font-weight: 700;
    text-decoration: underline;
    cursor: pointer
}

[class*=wmrards-].wmrards-btn--link.wmrards-is--active,
[class*=wmrards-].wmrards-btn--link:active,
[class*=wmrards-].wmrards-btn--link:focus,
[class*=wmrards-].wmrards-btn--link:hover,
[class*=wmrards-].wmrards-btn--link:target {
    background: 0 0
}

[class*=wmrards-].wmrards-content-card {
    height: auto;
    border-bottom: 5px solid #841922;
    background-color: #fff
}

a[class*=wmrards-].wmrards-content-card {
    display: block;
    color: #080607;
    text-decoration: none
}

a[class*=wmrards-].wmrards-content-card h2 {
    text-decoration: underline
}

a[class*=wmrards-].wmrards-content-card:focus,
a[class*=wmrards-].wmrards-content-card:hover {
    -webkit-box-shadow: inset 0 0 0 1px #841922;
    box-shadow: inset 0 0 0 1px #841922
}

[class*=wmrards-].wmrards-content-card--yellow {
    background-color: #fd0
}

[class*=wmrards-].wmrards-content-card__title {
    margin-bottom: .75rem
}

[class*=wmrards-].wmrards-content-card__list {
    padding: 0;
    list-style-type: none
}

[class*=wmrards-].wmrards-content-card__list-item {
    margin: .75rem 0 0
}

[class*=wmrards-].wmrards-content-card__list-item-btn {
    width: 100%;
    text-align: left
}

@media (max-width:567px) {
    [class*=wmrards-].wmrards-content-card__content {
        margin-top: 1rem
    }
}

[class*=wmrards-].wmrards-content-card__content>:last-child {
    margin-bottom: 0
}

[class*=wmrards-].wmrards-content-card__image {
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

[class*=wmrards-].wmrards-content-card__form-collapse {
    display: none
}

[class*=wmrards-].wmrards-content-card__form.wmrards-is--open [class*=wmrards-].wmrards-content-card__form-collapse {
    display: block
}

[class*=wmrards-] a.wmrards-content-card {
    display: block;
    color: #080607;
    text-decoration: none
}

[class*=wmrards-] a.wmrards-content-card h2 {
    text-decoration: underline
}

[class*=wmrards-] a.wmrards-content-card:focus,
[class*=wmrards-] a.wmrards-content-card:hover {
    -webkit-box-shadow: inset 0 0 0 1px #841922;
    box-shadow: inset 0 0 0 1px #841922
}

[class*=wmrards-].wmrards-details__content {
    margin-top: .25rem;
    padding: .5rem 1rem;
    border-left: 5px solid #f29396
}

[class*=wmrards-].wmrards-details__content>:last-child {
    margin-bottom: 0
}

[class*=wmrards-].wmrards-fe-group {
    margin-bottom: 3rem
}

[class*=wmrards-].wmrards-fe-group--error {
    padding-left: 1rem;
    border-left: 5px solid #d4351c
}

[class*=wmrards-].wmrards-fe-fieldset {
    width: 100%;
    min-width: 0;
    margin: 0;
    padding: 0;
    border: 0
}

[class*=wmrards-].wmrards-fe-fieldset__legend {
    width: 100%;
    min-width: 0;
    margin: 0;
    padding: 0;
    overflow: hidden;
    border: 0
}

[class*=wmrards-].wmrards-fe-label {
    display: block;
    margin-bottom: .75rem;
    font-size: 1rem;
    line-height: 1.5rem;
    color: inherit
}

[class*=wmrards-].wmrards-fe-label .wmrards-n-icon__svg {
    width: .75rem;
    height: calc(.7855635925rem)
}

[class*=wmrards-].wmrards-fe-checkboxes__desc {
    display: block;
    margin-bottom: .75rem
}

[class*=wmrards-].wmrards-fe-checkboxes__container {
    font-size: 1rem;
    line-height: 1.5rem;
    display: block;
    position: relative;
    min-height: 35px;
    margin-bottom: 1rem;
    padding-top: .35rem;
    padding-left: 60px;
    cursor: pointer;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

[class*=wmrards-].wmrards-fe-checkboxes__container .wmrards-n-icon__svg {
    width: .75rem;
    height: calc(.7855635925rem)
}

[class*=wmrards-].wmrards-fe-checkboxes__container:hover .wmrards-fe-checkboxes__checkmark {
    background-color: #f7bec0
}

[class*=wmrards-].wmrards-fe-checkboxes__icon {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 26px;
    height: 23px;
    margin: auto;
    fill: #841922
}

[class*=wmrards-].wmrards-fe-checkboxes__input {
    position: absolute;
    width: 35px;
    height: 35px;
    font-size: 1rem;
    line-height: 1.5rem;
    padding: .75rem;
    border: 1px solid #841922;
    opacity: 0;
    color: #080607
}

[class*=wmrards-].wmrards-fe-checkboxes__input .wmrards-n-icon__svg {
    width: .75rem;
    height: calc(.7855635925rem)
}

[class*=wmrards-].wmrards-fe-checkboxes__input:focus~.wmrards-fe-checkboxes__checkmark {
    background-color: #f7bec0
}

[class*=wmrards-].wmrards-fe-checkboxes__input:checked~.wmrards-fe-checkboxes__checkmark>.wmrards-fe-checkboxes__icon {
    display: block
}

[class*=wmrards-].wmrards-fe-checkboxes__checkmark {
    position: absolute;
    top: 0;
    left: 0;
    width: 35px;
    height: 35px;
    border: 2px solid #841922;
    background: #fff
}

[class*=wmrards-].wmrards-fe-checkbox-buttons__desc {
    display: block;
    margin-bottom: .75rem
}

[class*=wmrards-].wmrards-fe-checkbox-buttons__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

@media (max-width:567px) {
    [class*=wmrards-].wmrards-fe-checkbox-buttons__input+label {
        margin-bottom: .75rem;
        -ms-flex-preferred-size: calc(33.3333333333% - (.75rem* 2)/ 3);
        flex-basis: calc(33.3333333333% - (.75rem* 2)/ 3)
    }

    [class*=wmrards-].wmrards-fe-checkbox-buttons__input+label:not(:nth-of-type(3n+1)) {
        margin-left: .75rem
    }
}

@media (min-width:568px) {
    [class*=wmrards-].wmrards-fe-checkbox-buttons__input+label {
        margin-right: .75rem;
        -ms-flex-preferred-size: calc(14.2857142857% - (.75rem* 6)/ 7);
        flex-basis: calc(14.2857142857% - (.75rem* 6)/ 7);
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1
    }

    [class*=wmrards-].wmrards-fe-checkbox-buttons__input+label:last-child {
        margin-right: 0
    }
}

[class*=wmrards-].wmrards-fe-checkbox-buttons__input:checked+label {
    background-color: #f29396
}

[class*=wmrards-].wmrards-fe-checkbox-buttons__input:focus+label {
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px #fff, 0 0 0 4px #e5272d;
    box-shadow: 0 0 0 2px #fff, 0 0 0 4px #e5272d
}

[class*=wmrards-].wmrards-fe-checkbox-button {
    text-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

[class*=wmrards-].wmrards-fe-date-input {
    display: block;
    width: 100%
}

[class*=wmrards-].wmrards-fe-date-input__day,
[class*=wmrards-].wmrards-fe-date-input__month {
    max-width: 2.7em
}

[class*=wmrards-].wmrards-fe-date-input__year {
    max-width: 4.5em
}

[class*=wmrards-].wmrards-fe-date-input__day,
[class*=wmrards-].wmrards-fe-date-input__month,
[class*=wmrards-].wmrards-fe-date-input__year {
    display: inline-block;
    margin-right: 1rem
}

[class*=wmrards-].wmrards-fe-dropdown__select {
    width: 100%;
    padding: .75rem 56px .75rem .75rem;
    border: 1px solid #841922;
    border-radius: 0;
    background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MCA1MCI+PHJlY3QgeD0iMC41IiB5PSIwLjUiIHdpZHRoPSI0OSIgaGVpZ2h0PSI0OSIgZmlsbD0iIzg0MTkyMiIgc3Ryb2tlPSIjODQxOTIyIi8+PHBhdGggZD0iTTE1LjExLDE5LjQ3MTZIMzQuMTQ5YTEuNDgsMS40OCwwLDAsMSwxLjA0MzQsMi41MjcxbC05LjUxNiw5LjUzNzVhMS40ODE0LDEuNDgxNCwwLDAsMS0yLjA5NDIsMGwtOS41MTYtOS41Mzc1QTEuNDgsMS40OCwwLDAsMSwxNS4xMSwxOS40NzE2WiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==) right/contain no-repeat #fff;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #080607
}

[class*=wmrards-].wmrards-fe-dropdown__select .wmrards-n-icon__svg {
    width: .75rem;
    height: calc(.7855635925rem)
}

[class*=wmrards-].wmrards-fe-error-message {
    display: block;
    margin-bottom: .75rem;
    color: #d4351c;
    font-weight: 700
}

[class*=wmrards-].wmrards-fe-file-upload {
    display: block;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

[class*=wmrards-].wmrards-fe-file-upload__input {
    position: absolute;
    z-index: -1;
    width: .1px;
    height: .1px;
    overflow: hidden;
    opacity: 0
}

[class*=wmrards-].wmrards-fe-file-upload__input:focus~.wmrards-fe-file-upload__label {
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px #fff, 0 0 0 4px #e5272d;
    box-shadow: 0 0 0 2px #fff, 0 0 0 4px #e5272d
}

[class*=wmrards-].wmrards-fe-file-upload__input--file-selected~.wmrards-fe-file-upload__label {
    background-color: #d4351c
}

[class*=wmrards-].wmrards-fe-file-upload__label {
    margin-right: .75rem;
    -ms-flex-negative: 0;
    flex-shrink: 0
}

[class*=wmrards-].wmrards-fe-number-input__container {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap
}

[class*=wmrards-].wmrards-fe-number-input__input {
    font-size: 1rem;
    line-height: 1.5rem;
    width: 100%;
    padding: .75rem;
    border: 1px solid #841922;
    color: #080607;
    text-align: center
}

[class*=wmrards-].wmrards-fe-number-input__input .wmrards-n-icon__svg {
    width: .75rem;
    height: calc(.7855635925rem)
}

[class*=wmrards-].wmrards-fe-number-input__input--error {
    border: 2px solid #d4351c
}

[class*=wmrards-].wmrards-fe-number-input__control {
    width: 50px;
    height: 50px;
    padding: .75rem;
    border: 0;
    border-radius: 6px;
    background-color: #e5272d
}

[class*=wmrards-].wmrards-fe-number-input__control:not([disabled]) {
    cursor: pointer
}

[class*=wmrards-].wmrards-fe-number-input__control:not([disabled]):hover {
    background-color: #b71f24
}

[class*=wmrards-].wmrards-fe-number-input__control svg {
    width: 100%;
    height: 100%;
    fill: #fff
}

[class*=wmrards-].wmrards-fe-number-input__control[disabled] {
    opacity: .5
}

[class*=wmrards-].wmrards-fe-input {
    font-size: 1rem;
    line-height: 1.5rem;
    width: 100%;
    padding: .75rem;
    border: 1px solid #841922;
    color: #080607
}

[class*=wmrards-].wmrards-fe-input .wmrards-n-icon__svg {
    width: .75rem;
    height: calc(.7855635925rem)
}

[class*=wmrards-].wmrards-fe-input--error {
    border: 2px solid #d4351c
}

[class*=wmrards-].wmrards-fe-question {
    margin-top: 3rem;
    font-size: 2rem;
    line-height: 2.225rem;
    margin-bottom: 2rem
}

[class*=wmrards-].wmrards-fe-question .wmrards-n-icon__svg {
    width: 1.5rem;
    height: calc(1.5711271851rem)
}

[class*=wmrards-].wmrards-fe-radios__desc {
    display: block;
    margin-bottom: .75rem
}

[class*=wmrards-].wmrards-fe-radios__container {
    font-size: 1rem;
    line-height: 1.5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    min-height: 35px;
    margin-bottom: 1rem;
    padding-left: 60px;
    cursor: pointer;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

[class*=wmrards-].wmrards-fe-radios__container .wmrards-n-icon__svg {
    width: .75rem;
    height: calc(.7855635925rem)
}

[class*=wmrards-].wmrards-fe-radios__container:hover .wmrards-fe-radios__checkmark {
    background-color: #f7bec0
}

[class*=wmrards-].wmrards-fe-radios--inline .wmrards-fe-radios__container {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-right: 3rem;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

[class*=wmrards-].wmrards-fe-radios__input {
    position: absolute;
    width: 0;
    height: 0;
    font-size: 1rem;
    line-height: 1.5rem;
    opacity: 0
}

[class*=wmrards-].wmrards-fe-radios__input .wmrards-n-icon__svg {
    width: .75rem;
    height: calc(.7855635925rem)
}

[class*=wmrards-].wmrards-fe-radios__input:focus~.wmrards-fe-radios__checkmark {
    background-color: #f7bec0
}

[class*=wmrards-].wmrards-fe-radios__input:checked~.wmrards-fe-radios__checkmark:after {
    display: block
}

[class*=wmrards-].wmrards-fe-radios__input[disabled]~.wmrards-fe-radios__checkmark {
    border: 2px solid #676869;
    background-color: #d1d2d2
}

[class*=wmrards-].wmrards-fe-radios__checkmark {
    position: absolute;
    top: 0;
    left: 0;
    width: 35px;
    height: 35px;
    border: 2px solid #841922;
    border-radius: 35px;
    background: #fff
}

[class*=wmrards-].wmrards-fe-radios__checkmark:after {
    content: "";
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 25px;
    height: 25px;
    margin: auto;
    border-radius: 25px;
    background-color: #841922
}

[class*=wmrards-].wmrards-fe-textarea {
    display: block;
    width: 100%;
    font-size: 1rem;
    line-height: 1.5rem;
    padding: .75rem;
    border: 1px solid #841922;
    color: #080607
}

[class*=wmrards-].wmrards-fe-textarea .wmrards-n-icon__svg {
    width: .75rem;
    height: calc(.7855635925rem)
}

[class*=wmrards-] .wmrards-hub-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    height: 230px;
    color: #fff;
    background: #e5272d
}

[class*=wmrards-] .wmrards-hub-header img:not(.icon) {
    display: inline-block;
    width: 50%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

@media (max-width:768px) {
    [class*=wmrards-] .wmrards-hub-header {
        display: block;
        height: 100%
    }

    [class*=wmrards-] .wmrards-hub-header img:not(.icon) {
        display: none
    }
}

[class*=wmrards-] .wmrards-hub-header-left-pane {
    display: inline-block;
    position: relative;
    width: 50%;
    height: 100%
}

[class*=wmrards-] .wmrards-hub-header-left-pane:after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 59px;
    right: -66px;
    width: 110px;
    height: 110px;
    margin-right: 10px;
    -webkit-transform: rotate(45deg) skew(25deg, 25deg);
    -ms-transform: rotate(45deg) skew(25deg, 25deg);
    transform: rotate(45deg) skew(25deg, 25deg);
    border-radius: 0;
    background-color: #e5272d
}

[class*=wmrards-] .wmrards-hub-header-text-pane {
    position: absolute;
    left: calc((100% - 955px)/ 2);
    max-width: 468px;
    overflow: visible;
    -ms-flex-item-align: center;
    align-self: center
}

@media (max-width:768px) {

    [class*=wmrards-] .wmrards-hub-header-left-pane,
    [class*=wmrards-] .wmrards-hub-header-left-pane:after {
        display: none
    }

    [class*=wmrards-] .wmrards-hub-header-text-pane {
        position: relative;
        width: 95%
    }
}

[class*=wmrards-] .wmrards-hub-header-text-pane .icon {
    margin-bottom: 10px
}

[class*=wmrards-] .wmrards-hub-header-text-pane img {
    height: 46px;
    margin-bottom: 10px
}

@media (max-width:568px) {
    [class*=wmrards-] .wmrards-hub-header-text-pane img {
        max-height: 46px
    }
}

[class*=wmrards-] .wmrards-hub-header-text-pane h1 {
    margin: 5px 0 10px;
    color: #fff
}

[class*=wmrards-] .wmrards-hub-header-text-pane .text {
    max-width: 448px;
    margin: 10px 0 0;
    color: #fff
}

@media (max-width:992px) {
    [class*=wmrards-] .wmrards-hub-header-text-pane {
        left: .75rem;
        padding: 1rem .25rem
    }

    [class*=wmrards-] .wmrards-hub-header-text-pane .text {
        margin-right: 10px
    }
}

[class*=wmrards-].wmrards-inset-text {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: inline-block;
    position: relative;
    padding: .5rem .5rem .5rem 20px;
    border-left: 5px solid #f29396;
    text-align: left;
    white-space: wrap
}

[class*=wmrards-].wmrards-inset-text:active,
[class*=wmrards-].wmrards-inset-text:focus,
[class*=wmrards-].wmrards-inset-text:target {
    outline: 0;
    -webkit-appearance: none
}

[class*=wmrards-].wmrards-inset-text p {
    margin-bottom: .75rem
}

[class*=wmrards-] a:not([class*=wmrards-]),
[class*=wmrards-].wmrards-link {
    color: #1a6fac;
    font-weight: 700;
    text-decoration: underline;
    cursor: pointer
}

[class*=wmrards-] a:not([class*=wmrards-]):visited,
[class*=wmrards-].wmrards-link:visited {
    color: #841922
}

[class*=wmrards-] a:not([class*=wmrards-]):hover,
[class*=wmrards-].wmrards-link:hover {
    color: #114a73
}

[class*=wmrards-].wmrards-link--with-chevron {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex
}

[class*=wmrards-].wmrards-link--with-chevron .wmrards-link__chevron {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 1.5rem;
    flex: 0 0 1.5rem
}

[class*=wmrards-].wmrards-link__chevron {
    width: 1.5rem;
    height: 1.5rem;
    fill: #1a6fac
}

[class*=wmrards-].wmrards-link__chevron--left {
    margin-right: .5rem;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg)
}

[class*=wmrards-].wmrards-link__chevron--right {
    margin-left: .5rem
}

[class*=wmrards-].wmrards-loader {
    width: 100px;
    height: 100px;
    margin: auto;
    -webkit-animation: 1s linear infinite spin;
    animation: 1s linear infinite spin;
    border: 10px solid #f3f2f1;
    border-top: 10px solid #841922;
    border-radius: 50%
}

[class*=wmrards-].wmrards-loader--btn {
    width: 22px;
    height: 22px;
    margin-left: .75rem;
    border-width: 2px;
    border-top-color: #1d7bbf
}

[class*=wmrards-].wmrards-loader--small {
    width: 60px;
    height: 60px;
    border-width: 6px
}

[class*=wmrards-].wmrards-loader--large {
    width: 200px;
    height: 200px;
    border-width: 20px
}

[class*=wmrards-].wmrards-loader__content {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0 0 0 0);
    border: 0
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

[class*=wmrards-].wmrards-n-icon {
    display: inline-block;
    color: currentColor;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    text-decoration: inherit;
    text-transform: capitalize
}

[class*=wmrards-].wmrards-n-icon__svg {
    display: inline-block;
    width: .75rem;
    height: calc(.75rem * 1.0474181234);
    fill: currentColor;
    vertical-align: revert
}

[class*=wmrards-].wmrards-msg-summary {
    position: relative;
    padding: .75rem;
    border: 1px solid #84329b
}

[class*=wmrards-].wmrards-msg-summary__header {
    display: block;
    color: inherit
}

[class*=wmrards-].wmrards-msg-summary__icon {
    width: 35px;
    height: 35px;
    margin-right: .75rem;
    fill: #84329b
}

[class*=wmrards-].wmrards-msg-summary__title {
    width: calc(100% - (35px + .75rem));
    margin: .35rem 0
}

@media all and (max-width:767px) {
    [class*=wmrards-].wmrards-msg-summary__title {
        width: 100%;
        margin: .5rem 0
    }
}

[class*=wmrards-].wmrards-msg-summary__icon,
[class*=wmrards-].wmrards-msg-summary__title {
    display: inline-block;
    color: inherit;
    vertical-align: middle
}

[class*=wmrards-].wmrards-msg-summary__info {
    display: block;
    color: inherit
}

@media all and (min-width:768px) {
    [class*=wmrards-].wmrards-msg-summary__info {
        padding-left: calc(35px + .75rem)
    }
}

[class*=wmrards-].wmrards-msg-summary__close {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    top: .75rem;
    right: .75rem;
    width: 5rem;
    height: 35px;
    margin: 0 .25rem;
    padding: 0;
    border: 0;
    background: 0 0;
    font-size: 1rem;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

[class*=wmrards-].wmrards-msg-summary__close svg {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: .75rem;
    fill: #841922
}

[class*=wmrards-].wmrards-msg-summary--success {
    border-color: #00703c
}

[class*=wmrards-].wmrards-msg-summary--success .wmrards-msg-summary__icon {
    fill: #00703c
}

[class*=wmrards-].wmrards-msg-summary--success-fill {
    border-color: #00703c;
    color: #fff;
    background-color: #00703c
}

[class*=wmrards-].wmrards-msg-summary--success-fill .wmrards-msg-summary__icon {
    fill: #fff
}

[class*=wmrards-].wmrards-msg-summary--success-fill .wmrards-msg-summary__close {
    color: #fff
}

[class*=wmrards-].wmrards-msg-summary--success-fill .wmrards-msg-summary__close svg {
    fill: #fff
}

[class*=wmrards-].wmrards-msg-summary--success-fill-no-icon {
    border-color: #00703c;
    color: #fff;
    background-color: #00703c
}

[class*=wmrards-].wmrards-msg-summary--success-fill-no-icon .wmrards-msg-summary__icon {
    display: none
}

[class*=wmrards-].wmrards-msg-summary--warning {
    border-color: #b75e00
}

[class*=wmrards-].wmrards-msg-summary--warning .wmrards-msg-summary__icon {
    fill: #b75e00
}

[class*=wmrards-].wmrards-msg-summary--error {
    border-color: #d4351c
}

[class*=wmrards-].wmrards-msg-summary--error .wmrards-msg-summary__icon {
    fill: #d4351c
}

[class*=wmrards-].wmrards-msg-help {
    display: block;
    width: 100%;
    padding: .75rem;
    background-color: #f7bec0
}

[class*=wmrards-].wmrards-msg-help__close-btn {
    margin-top: .25rem;
    margin-left: 2rem;
    padding: 0;
    float: right;
    border: 0;
    background: 0 0;
    cursor: pointer
}

[class*=wmrards-].wmrards-msg-help__close-icon {
    display: block;
    width: 25px;
    height: 25px;
    fill: #841922
}

[class*=wmrards-] .wmrards-page-contents {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    margin-bottom: 30px;
    padding: 10px;
    border-bottom: 3px solid #000;
    background: #fff
}

[class*=wmrards-] .wmrards-page-contents h3 {
    margin-top: 0;
    margin-bottom: 10px;
    color: #1d7bbf;
    font-size: 23px;
    font-weight: 700;
    line-height: 24px
}

[class*=wmrards-] .wmrards-page-contents div {
    margin-bottom: 10px;
    padding-left: 10px;
    border-left: 3px solid #841922
}

[class*=wmrards-] .wmrards-page-contents a {
    display: block;
    margin-bottom: 10px;
    text-overflow: ellipsis;
    white-space: wrap
}

[class*=wmrards-] .wmrards-page-contents--with-accordion div {
    margin-bottom: 0;
    padding-left: 0;
    border-left: 0
}

[class*=wmrards-] .wmrards-page-contents--with-accordion .current {
    margin-bottom: 10px;
    padding-left: 10px;
    border-left: 3px solid #841922
}

[class*=wmrards-] .wmrards-page-contents--with-accordion .wmrards-accordion__summary-wrapper {
    padding-top: .5rem;
    padding-right: 0;
    padding-bottom: .5rem
}

[class*=wmrards-] .wmrards-page-contents--with-accordion .wmrards-accordion__summary-wrapper .wmrards-accordion__icon {
    width: 24px;
    height: 24px
}

[class*=wmrards-] .wmrards-page-contents--with-accordion .wmrards-accordion__content {
    padding: 0
}

[class*=wmrards-] .wmrards-page-contents--with-accordion ul li {
    padding-left: 2rem
}

[class*=wmrards-] .wmrards-page-contents--with-accordion ul li.current {
    padding-left: 30px
}

[class*=wmrards-] .wmrards-page-header {
    display: block;
    height: 420px;
    color: #fff;
    background: center/cover no-repeat #000
}

[class*=wmrards-] .wmrards-page-header-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-height: 300px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

[class*=wmrards-] .wmrards-page-header-box {
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 60%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 1rem 1.5rem 1rem 1.75rem;
    background-color: #e5272d;
    vertical-align: middle;
    -ms-flex-item-align: center;
    align-self: center
}

[class*=wmrards-] .wmrards-page-header-box h1 {
    margin: 0 !important;
    padding: 0 !important;
    color: #fff;
    font-size: 2rem !important;
    line-height: 3rem !important
}

@media screen and (max-width:568px) {
    [class*=wmrards-] .wmrards-page-header img {
        display: block;
        width: 100%;
        height: 105px;
        max-height: 105px;
        -o-object-fit: cover;
        object-fit: cover
    }

    [class*=wmrards-] .wmrards-page-header-container {
        padding: 0
    }

    [class*=wmrards-] .wmrards-page-header-box {
        width: 85%
    }

    [class*=wmrards-] .wmrards-page-header-box h1 {
        font-size: 1.75rem !important;
        line-height: 2.5rem !important
    }

    [class*=wmrards-].wmrards-banner-container__phase-wrapper+[class*=wmrards-].wmrards-banner-container__text {
        line-height: 2rem
    }
}

@media screen and (max-width:568px) and (max-width:768px) {
    [class*=wmrards-] .wmrards-page-header-box {
        width: 85%;
        padding: 0
    }
}

[class*=wmrards-] .wmrards-container--main {
    margin-top: 0
}

[class*=wmrards-].wmrards-phase-indicator {
    padding: .25rem;
    border-radius: 4px;
    color: #fff;
    background-color: #e5272d;
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase
}

@media screen and (min-width:768px) {
    [class*=wmrards-].wmrards-phase-indicator {
        padding: .25rem .75rem
    }
}

[class*=wmrards-].wmrards-table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse
}

[class*=wmrards-].wmrards-table__caption {
    font-weight: 700;
    text-align: left
}

[class*=wmrards-].wmrards-table th {
    font-weight: 700;
    text-align: left;
    vertical-align: middle
}

[class*=wmrards-].wmrards-table td,
[class*=wmrards-].wmrards-table th {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 2.625rem;
    padding: .5rem .75rem .5rem 0;
    border-bottom: 1px solid #979797
}

[class*=wmrards-].wmrards-table td {
    vertical-align: middle
}

@media screen and (max-width:768px) {
    html body [class*=wmrards-].wmrards-hide-mobile {
        display: none !important
    }

    [class*=wmrards-].wmrards-table table,
    [class*=wmrards-].wmrards-table tbody,
    [class*=wmrards-].wmrards-table td,
    [class*=wmrards-].wmrards-table th,
    [class*=wmrards-].wmrards-table thead,
    [class*=wmrards-].wmrards-table tr {
        display: block
    }

    [class*=wmrards-].wmrards-table thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px
    }

    [class*=wmrards-].wmrards-table tr {
        padding: .5rem 0;
        border-bottom: 1px solid #979797
    }

    [class*=wmrards-].wmrards-table tr>th {
        padding-bottom: 0
    }

    [class*=wmrards-].wmrards-table tr>td,
    [class*=wmrards-].wmrards-table tr>th {
        position: relative;
        height: auto;
        padding-left: 50%;
        border-bottom: 0;
        text-align: left;
        white-space: normal
    }

    [class*=wmrards-].wmrards-table tr>td:before,
    [class*=wmrards-].wmrards-table tr>th:before {
        content: attr(data-header);
        position: absolute;
        top: 50%;
        left: 0;
        width: 45%;
        padding-right: .75rem;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        font-weight: 700;
        text-align: left;
        white-space: nowrap
    }

    [class*=wmrards-].wmrards-table.wmrards-table--without-header tr>td,
    [class*=wmrards-].wmrards-table.wmrards-table--without-header tr>th {
        padding-left: 0
    }

    [class*=wmrards-].wmrards-table.wmrards-table--without-header tr>td:before,
    [class*=wmrards-].wmrards-table.wmrards-table--without-header tr>th:before {
        content: none
    }
}

[class*=wmrards-].wmrards-warning-text {
    display: block;
    position: relative;
    min-height: 2.5rem;
    padding-top: .5rem;
    padding-left: 3.25rem;
    font-weight: 700;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

[class*=wmrards-].wmrards-warning-text__icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 2.5rem;
    height: 2.5rem;
    margin-right: .75rem;
    fill: #000
}

[class*=wmrards-].wmrards-autocomplete {
    display: inline-block;
    position: relative;
    width: 100%
}

[class*=wmrards-].wmrards-autocomplete .wmrards-autocomplete__icon,
[class*=wmrards-].wmrards-autocomplete .wmrards-loader {
    display: block;
    position: absolute;
    top: calc(.75rem + 1px);
    bottom: calc(.75rem + 1px);
    left: calc(.75rem + 1px);
    width: 22px;
    height: 22px;
    fill: #676869
}

[class*=wmrards-].wmrards-autocomplete__input {
    padding-left: 45px
}

[class*=wmrards-].wmrards-autocomplete__input::-webkit-input-placeholder {
    color: #080607;
    font-weight: 700
}

[class*=wmrards-].wmrards-autocomplete__input::-moz-placeholder {
    color: #080607;
    font-weight: 700
}

[class*=wmrards-].wmrards-autocomplete__input:-ms-input-placeholder {
    color: #080607;
    font-weight: 700
}

[class*=wmrards-].wmrards-autocomplete__input::-ms-input-placeholder {
    color: #080607;
    font-weight: 700
}

[class*=wmrards-].wmrards-autocomplete__input::placeholder {
    color: #080607;
    font-weight: 700
}

[class*=wmrards-].wmrards-autocomplete .wmrards-loader {
    display: none;
    border-width: 3px
}

[class*=wmrards-].wmrards-autocomplete.wmrards-is--loading .wmrards-autocomplete__icon {
    display: none
}

[class*=wmrards-].wmrards-autocomplete.wmrards-is--loading .wmrards-loader {
    display: inline-block
}

[class*=wmrards-] .wmrards-autocomplete-suggestions {
    width: 100%;
    max-height: 300px;
    margin-top: 0;
    padding: 0;
    overflow-y: auto;
    border-bottom: 1px solid #841922;
    background-color: #fff
}

[class*=wmrards-] .wmrards-autocomplete-suggestions__li {
    margin-bottom: 0;
    padding: 10px;
    list-style: none;
    border-right: 1px solid #841922;
    border-bottom: 1px solid #841922;
    border-left: 1px solid #841922;
    cursor: pointer
}

[class*=wmrards-] .wmrards-autocomplete-suggestions__li:last-child {
    border-bottom: 0
}

[class*=wmrards-] .wmrards-autocomplete-suggestions__li:focus,
[class*=wmrards-] .wmrards-autocomplete-suggestions__li:hover {
    background-color: #e6d6eb;
    -webkit-box-shadow: none;
    box-shadow: none
}

[class*=wmrards-] .wmrards-autocomplete-suggestions__li .wmrards-disruption-indicator-medium {
    margin-right: 15px
}

[class*=wmrards-] .wmrards-autocomplete__selected-item {
    width: 100%;
    height: 50px;
    padding-right: 0;
    padding-left: .75rem;
    border: 1px solid #841922;
    -ms-flex-line-pack: center;
    align-content: center
}

[class*=wmrards-] .wmrards-autocomplete__selected-item-summary {
    display: block;
    width: 100%;
    -ms-flex-item-align: center;
    align-self: center
}

@media (min-width:360px) {
    [class*=wmrards-] .wmrards-autocomplete__selected-item-summary {
        max-width: calc(100% - 115px);
        margin-top: 0;
        margin-right: 1rem
    }
}

[class*=wmrards-] .wmrards-autocomplete__selected-item-close {
    padding: .75rem;
    border: 0;
    background: 0 0;
    cursor: pointer
}

[class*=wmrards-] .wmrards-autocomplete__selected-item-close-icon {
    display: block;
    width: 22px;
    height: 22px;
    fill: #e5272d
}

[class*=wmrards-].wmrards-homepage-banner {
    min-height: 325px;
    padding: 2rem 0;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover
}

[class*=wmrards-].wmrards-homepage-banner__title {
    margin-top: 3rem;
    margin-bottom: 1rem;
    font-size: 2rem;
    line-height: 2.225rem;
    display: inline;
    color: #fff;
    background-color: #841922;
    -webkit-box-shadow: 5px 0 0 5px #841922, -5px 0 0 5px #841922;
    box-shadow: 5px 0 0 5px #841922, -5px 0 0 5px #841922
}

[class*=wmrards-].wmrards-homepage-banner__title .wmrards-n-icon__svg {
    width: 1.5rem;
    height: calc(1.5711271851rem)
}

[class*=wmrards-].wmrards-homepage-banner__title-content {
    position: relative;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

[class*=wmrards-].wmrards-banner-container {
    padding: 1rem 0;
    border-bottom: 1px solid #080607
}

[class*=wmrards-].wmrards-banner-container--emergency {
    padding: 1rem;
    border-bottom: 3px solid #d4351c;
    color: #fff;
    background-color: #080607
}

[class*=wmrards-].wmrards-banner-container--emergency *,
[class*=wmrards-].wmrards-banner-container--emergency .wmrards-link:focus,
[class*=wmrards-].wmrards-banner-container--emergency .wmrards-link:hover,
[class*=wmrards-].wmrards-banner-container--emergency .wmrards-link:visited {
    color: currentColor
}

[class*=wmrards-].wmrards-banner-container__phase-wrapper {
    margin-right: .5rem;
    float: left
}

[class*=wmrards-].wmrards-banner-container__text {
    margin: 0;
    padding: 0
}

[class*=wmrards-].wmrards-banner-container__emergency-icon {
    width: 1.5rem;
    height: 1.5rem;
    fill: currentColor
}

[class*=wmrards-].wmrards-banner-container__emergency-icon-error {
    width: 2rem;
    height: 2rem;
    fill: #d4351c
}

[class*=wmrards-].wmrards-banner-container__emergency-close,
[class*=wmrards-].wmrards-banner-container__emergency-link {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

[class*=wmrards-].wmrards-banner-container__emergency-close {
    padding: 0;
    border: 0;
    background-color: transparent;
    font-size: 1rem;
    text-decoration: underline;
    cursor: pointer
}

[class*=wmrards-].wmrards-banner-container__emergency-icon-col {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0
}

@media screen and (min-width:1280px) {
    [class*=wmrards-].wmrards-banner-container__emergency-icon-col {
        padding-top: .25rem;
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1
    }

    [class*=wmrards-].wmrards-banner-container__emergency-title-col {
        padding-left: 1rem
    }
}

[class*=wmrards-].wmrards-banner-container__emergency-title-col {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1
}

[class*=wmrards-].wmrards-banner-container__emergency-link-col {
    margin-top: 1rem;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1
}

[class*=wmrards-].wmrards-banner-container__emergency-close-col {
    text-align: right;
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0
}

@media screen and (min-width:1280px) {
    [class*=wmrards-].wmrards-banner-container__emergency-link-col {
        margin: 0;
        text-align: right
    }

    [class*=wmrards-].wmrards-banner-container__emergency-close-col {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1
    }
}

[class*=wmrards-].wmrards-branded-banner {
    padding: 2rem 0;
    color: #fff;
    background-color: #841922
}

[class*=wmrards-].wmrards-branded-banner .wmrards-breadcrumb {
    padding: 0
}

[class*=wmrards-].wmrards-branded-banner .wmrards-breadcrumb__link,
[class*=wmrards-].wmrards-branded-banner .wmrards-breadcrumb__list-item::before,
[class*=wmrards-].wmrards-branded-banner__title {
    color: #fff
}

[class*=wmrards-].wmrards-branded-banner__title svg {
    width: 1em;
    height: 1em;
    color: inherit;
    fill: currentColor;
    vertical-align: text-top
}

[class*=wmrards-].wmrards-branded-banner__title~*,
[class*=wmrards-].wmrards-branded-banner__title~* * {
    color: #fff
}

[class*=wmrards-].wmrards-branded-banner__modals {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

[class*=wmrards-].wmrards-branded-banner__modal {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: 1.5rem;
    margin-bottom: .75rem;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

[class*=wmrards-].wmrards-branded-banner__icon {
    width: 36px;
    height: 36px;
    margin-right: 10px;
    border-radius: 4px;
    background: #fff
}

[class*=wmrards-].wmrards-branded-banner__icon--bus {
    fill: #ea0029
}

[class*=wmrards-].wmrards-branded-banner__icon--rail,
[class*=wmrards-].wmrards-branded-banner__icon--train {
    fill: #e07709
}

[class*=wmrards-].wmrards-branded-banner__icon--metro,
[class*=wmrards-].wmrards-branded-banner__icon--tram {
    fill: #0075c9
}

[class*=wmrards-].wmrards-branded-banner__icon--cycle,
[class*=wmrards-].wmrards-branded-banner__icon--walk {
    fill: #658d1b
}

[class*=wmrards-].wmrards-branded-banner__icon--p-r,
[class*=wmrards-].wmrards-branded-banner__icon--park-and-ride {
    fill: #841922
}

[class*=wmrards-].wmrards-branded-banner__icon--p-r svg,
[class*=wmrards-].wmrards-branded-banner__icon--park-and-ride svg {
    border: 1px solid #fff
}

[class*=wmrards-].wmrards-branded-banner .wmrards-swift-logo-inline {
    width: 3.6em;
    height: 1.3em;
    margin-top: -.175em;
    fill: currentColor;
    vertical-align: middle
}

[class*=wmrards-].wmrards-cookies-banner {
    display: none
}

[class*=wmrards-].wmrards-cookies-banner__group-buttons>:not(:first-child) {
    margin-top: 1rem
}

[class*=wmrards-].wmrards-contact-details {
    font-style: normal
}

[class*=wmrards-].wmrards-contact-details>:last-child {
    margin-bottom: 0
}

@media all and (min-width:1280px) {
    [class*=wmrards-].wmrards-feedback-loop {
        border-bottom: .75rem solid #1d7bbf
    }
}

[class*=wmrards-].wmrards-feedback-loop .wmrards-btn--link,
[class*=wmrards-].wmrards-feedback-loop .wmrards-link {
    color: #fff
}

[class*=wmrards-].wmrards-feedback-loop .wmrards-container {
    padding: .25rem 1rem;
    overflow: hidden;
    color: #fff;
    background-color: #1d7bbf
}

[class*=wmrards-].wmrards-feedback-loop__useful {
    margin-bottom: 1rem;
    color: #fff
}

[class*=wmrards-].wmrards-feedback-loop__form {
    display: none
}

[class*=wmrards-].wmrards-feedback-loop__form .wmrards-fe-label {
    font-weight: 700
}

[class*=wmrards-].wmrards-feedback-loop .wmrards-is--sent>*,
[class*=wmrards-].wmrards-feedback-loop__sent-msg {
    display: none
}

[class*=wmrards-].wmrards-feedback-loop .wmrards-is--sent>.wmrards-feedback-loop__sent-msg {
    display: block
}

[class*=wmrards-].wmrards-feedback-loop.wmrards-is--open .wmrards-container {
    padding: 1rem;
    border-top: .75rem solid #1d7bbf;
    border-right: 1px solid #1d7bbf;
    border-left: 1px solid #1d7bbf;
    color: initial;
    background-color: #fff
}

[class*=wmrards-].wmrards-feedback-loop.wmrards-is--open .wmrards-btn--link,
[class*=wmrards-].wmrards-feedback-loop.wmrards-is--open .wmrards-link {
    color: #1d7bbf
}

[class*=wmrards-].wmrards-feedback-loop.wmrards-is--open .wmrards-feedback-loop__questions {
    display: none
}

[class*=wmrards-].wmrards-feedback-loop.wmrards-is--open .wmrards-feedback-loop__form {
    display: block
}

@media all and (min-width:768px) {
    [class*=wmrards-] .wmrards-feedback-loop__useful {
        margin: 0;
        float: left
    }

    [class*=wmrards-] .wmrards-feedback-loop__wrong {
        float: right
    }
}

[class*=wmrards-].wmrards-footer {
    padding: 2rem;
    color: #fff;
    background-color: #1e082a;
    -webkit-box-shadow: 0 100vh 0 100vh #1e082a;
    box-shadow: 0 100vh 0 100vh #1e082a
}

[class*=wmrards-].wmrards-footer * {
    color: inherit
}

[class*=wmrards-].wmrards-footer__link.wmrards-link {
    color: #fff
}

[class*=wmrards-].wmrards-footer--mobile-app {
    display: none
}

[class*=wmrards-].wmrards-footer--v2 {
    padding: 2rem .25rem
}

[class*=wmrards-].wmrards-footer--v2 .wmrards-btn {
    width: 100%;
    color: #3c1053;
    background-color: #fff
}

[class*=wmrards-].wmrards-footer--v2 .wmrards-footer__link {
    color: currentColor;
    font-weight: 400;
    text-decoration: none
}

[class*=wmrards-].wmrards-footer--v2 .wmrards-footer__link:hover {
    color: currentColor;
    text-decoration: underline
}

[class*=wmrards-].wmrards-footer .wmrards-footer__heading {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #fff;
    color: #fff
}

@media screen and (max-width:767px) {
    [class*=wmrards-].wmrards-footer .wmrards-footer__updates-col {
        padding-bottom: 1rem
    }

    [class*=wmrards-].wmrards-footer .wmrards-footer__updates-col:not(:last-child) {
        margin-bottom: .5rem;
        border-bottom: 1px solid #fff
    }

    [class*=wmrards-].wmrards-footer .wmrards-footer__updates-col p {
        font-size: 1rem
    }

    [class*=wmrards-].wmrards-footer .wmrards-footer__heading {
        margin-bottom: 1rem;
        font-weight: 700
    }

    [class*=wmrards-].wmrards-footer .wmrards-footer__heading.wmrards-collapse-heading {
        margin: 0;
        padding: .5rem 0;
        border: 0
    }
}

@media screen and (min-width:768px) {
    [class*=wmrards-].wmrards-cookies-banner__group-buttons.wmrards-grid {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap
    }

    [class*=wmrards-].wmrards-cookies-banner__group-buttons>:not(:first-child) {
        margin-top: 0;
        margin-left: 1rem
    }

    [class*=wmrards-].wmrards-footer--mobile-app {
        display: block
    }

    [class*=wmrards-].wmrards-footer .wmrards-footer__heading {
        padding-bottom: 1rem
    }
}

[class*=wmrards-].wmrards-footer .wmrards-footer__menu {
    margin: 0;
    padding: 0
}

@media screen and (max-width:767px) {
    [class*=wmrards-].wmrards-footer .wmrards-footer__menu {
        padding-left: 1rem
    }
}

[class*=wmrards-].wmrards-footer .wmrards-footer__menu li {
    margin-bottom: .75rem;
    list-style: none
}

[class*=wmrards-].wmrards-footer .wmrards-footer__menu li:last-child {
    margin-bottom: 0
}

@media screen and (min-width:768px) {
    [class*=wmrards-].wmrards-footer .wmrards-grid {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between
    }

    [class*=wmrards-].wmrards-footer .wmrards-col-lg-1-3 {
        margin-bottom: 2rem;
        padding: 0 .75rem
    }

    [class*=wmrards-].wmrards-footer .wmrards-col-lg-1-3:first-child {
        margin-left: -.75rem
    }

    [class*=wmrards-].wmrards-footer .wmrards-col-lg-1-3:last-child {
        margin-right: -.75rem
    }
}

[class*=wmrards-].wmrards-footer .wmrards-footer__bottom {
    margin-top: .5rem;
    padding-top: 1rem;
    border-top: 1px solid #fff
}

@media screen and (max-width:767px) {
    [class*=wmrards-].wmrards-footer .wmrards-footer__bottom {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse
    }
}

[class*=wmrards-].wmrards-footer .wmrards-footer__bottom .wmrards-footer__copyright,
[class*=wmrards-].wmrards-footer .wmrards-footer__bottom .wmrards-footer__link {
    margin: 0;
    font-family: "DM Sans", sans-serif;
    font-weight: 700
}

[class*=wmrards-].wmrards-footer .wmrards-footer__bottom-menu {
    margin: 0 0 2rem;
    padding: 0
}

[class*=wmrards-].wmrards-footer .wmrards-footer__bottom-menu li {
    margin-bottom: .75rem;
    list-style: none
}

[class*=wmrards-].wmrards-footer .wmrards-footer__bottom-menu li a {
    display: block;
    white-space: nowrap
}

@media screen and (min-width:768px) {
    [class*=wmrards-].wmrards-footer .wmrards-footer__bottom-menu {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end
    }

    [class*=wmrards-].wmrards-footer .wmrards-footer__bottom-menu li {
        margin-bottom: 0
    }

    [class*=wmrards-].wmrards-footer .wmrards-footer__bottom-menu li:not(:last-child) {
        padding-right: 2rem
    }

    [class*=wmrards-].wmrards-header__logo-link {
        width: auto
    }
}

[class*=wmrards-].wmrards-footer__social-media {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0
}

[class*=wmrards-].wmrards-footer__social-media li {
    display: block
}

[class*=wmrards-].wmrards-footer__social-media li:not(:last-child) a {
    display: block;
    margin-right: 2rem
}

[class*=wmrards-].wmrards-footer__social-media li svg {
    width: 3rem;
    height: 3rem;
    fill: #fff
}

[class*=wmrards-] .wmrards-collapse-heading .wmrards-collapse-heading__indicator {
    display: none
}

@media screen and (max-width:767px) {
    [class*=wmrards-] .wmrards-collapse-heading {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    [class*=wmrards-] .wmrards-collapse-heading .wmrards-collapse-heading__indicator {
        display: block;
        width: 2rem;
        height: 2rem;
        margin-left: .25rem;
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
        -webkit-transition: -webkit-transform .15s cubic-bezier(.215, .61, .355, 1);
        transition: transform .15s cubic-bezier(.215, .61, .355, 1);
        transition: transform .15s cubic-bezier(.215, .61, .355, 1), -webkit-transform .15s cubic-bezier(.215, .61, .355, 1);
        fill: currentColor
    }

    [class*=wmrards-] .wmrards-collapse-heading[aria-expanded=true] .wmrards-collapse-heading__indicator {
        -webkit-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        transform: rotate(-90deg)
    }

    [class*=wmrards-] .wmrards-collapse-panel {
        max-height: 0;
        padding-left: 1rem;
        overflow: hidden;
        -webkit-transition: max-height .25s cubic-bezier(.215, .61, .355, 1);
        transition: max-height .25s cubic-bezier(.215, .61, .355, 1);
        border-left: 1px solid #fff
    }
}

[class*=wmrards-] .wmrards-footer ::-moz-selection {
    color: #fff;
    background: #84329b
}

[class*=wmrards-] .wmrards-footer ::selection {
    color: #fff;
    background: #84329b
}

[class*=wmrards-].wmrards-header__logo {
    max-width: 88px
}

@media all and (min-width:568px) {
    [class*=wmrards-].wmrards-header__title {
        max-width: calc(100% - 200px);
        margin: 0;
        text-align: right
    }
}

@media screen and (max-width:768px) {
    html.mobile-menu-open {
        position: fixed;
        width: 100%;
        height: 100vh;
        overflow: hidden
    }

    html.mobile-menu-open .wmrards-mega-menu {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse
    }

    html.mobile-menu-open .wmrards-mega-menu__search-btn {
        margin-right: .75rem;
        margin-left: 0
    }
}

[class*=wmrards-].wmrards-header {
    display: block;
    padding: 1rem 0;
    background-color: #841922;
    text-align: left
}

[class*=wmrards-].wmrards-header__skip-link {
    display: block;
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 1rem;
    clip: rect(0 0 0 0);
    color: #fff;
    background-color: #1d7bbf
}

[class*=wmrards-].wmrards-header__skip-link:visited {
    color: #fff
}

[class*=wmrards-].wmrards-header__skip-link:focus {
    position: static;
    width: auto;
    height: auto;
    clip: auto;
    -webkit-box-shadow: none;
    box-shadow: none
}

[class*=wmrards-].wmrards-header__logo-link {
    display: inline-block;
    width: 34px;
    height: 34px;
    margin-right: .75rem;
    overflow: hidden;
    vertical-align: middle
}

[class*=wmrards-].wmrards-header__logo {
    width: auto;
    max-width: 160px;
    height: 46px;
    float: left
}

[class*=wmrards-].wmrards-header .wmrards-phase-indicator,
[class*=wmrards-].wmrards-header__vertical-align {
    vertical-align: middle
}

[class*=wmrards-].wmrards-header__title {
    margin-top: 2rem;
    font-size: 1.4375rem;
    line-height: 1.5rem;
    margin-bottom: 0;
    color: #fff
}

[class*=wmrards-].wmrards-header__title .wmrards-n-icon__svg {
    width: 1.078125rem;
    height: calc(1.1292476643rem)
}

@media all and (min-width:568px) {
    [class*=wmrards-].wmrards-header__title {
        max-width: calc(100% - 12.5rem);
        margin: 0;
        text-align: right
    }
}

[class*=wmrards-].wmrards-header__links {
    display: block;
    margin: 0;
    padding: 0;
    float: right
}

[class*=wmrards-].wmrards-header__link {
    display: inline-block;
    margin: 0 .25rem
}

@media screen and (min-width:568px) {
    [class*=wmrards-].wmrards-header__link {
        margin: 0 .75rem
    }
}

[class*=wmrards-].wmrards-header__link .wmrards-link {
    color: #fff
}

[class*=wmrards-].wmrards-header--mobile-app .wmrards-header__main-links {
    display: none
}

@media screen and (min-width:768px) {
    [class*=wmrards-].wmrards-header__logo-link {
        width: auto;
        height: 46px
    }

    [class*=wmrards-].wmrards-header__logo {
        width: 160px;
        height: 46px
    }

    [class*=wmrards-].wmrards-header__links {
        text-align: right
    }

    [class*=wmrards-].wmrards-header--mobile-app .wmrards-header__main-links {
        display: inline-block
    }

    [class*=wmrards-].wmrards-header--mobile-app .wmrards-header__back-btn {
        display: none
    }
}

[class*=wmrards-].wmrards-mega-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    letter-spacing: normal
}

[class*=wmrards-].wmrards-mega-menu__primary-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch
}

[class*=wmrards-].wmrards-mega-menu__primary-menu,
[class*=wmrards-].wmrards-mega-menu__sub-menu,
[class*=wmrards-].wmrards-mega-menu__sub-menu-child-menu {
    margin: 0;
    padding: 0
}

[class*=wmrards-].wmrards-mega-menu__primary-menu-item,
[class*=wmrards-].wmrards-mega-menu__sub-menu-child-item,
[class*=wmrards-].wmrards-mega-menu__sub-menu-item {
    margin: 0;
    list-style: none
}

[class*=wmrards-].wmrards-mega-menu__primary-menu-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border: 0;
    color: currentColor;
    background-color: transparent;
    line-height: 1.5;
    text-decoration: none
}

[class*=wmrards-].wmrards-mega-menu__search-icon {
    width: 1.25rem;
    height: 1.25rem;
    color: #fff;
    fill: currentColor
}

[class*=wmrards-].wmrards-mega-menu__search-close-icon {
    width: 1.5rem;
    height: 1.5rem;
    color: #fff;
    fill: currentColor;
    display: none
}

[class*=wmrards-].wmrards-mega-menu__collapse-toggle,
[class*=wmrards-].wmrards-mega-menu__link-arrow-icon,
[class*=wmrards-].wmrards-mega-menu__link-arrow-icon-btn,
[class*=wmrards-].wmrards-mega-menu__mobile-toggle {
    display: none
}

[class*=wmrards-].wmrards-mega-menu__collapse-toggle:hover,
[class*=wmrards-].wmrards-mega-menu__link-arrow-icon-btn:hover,
[class*=wmrards-].wmrards-mega-menu__link-arrow-icon:hover,
[class*=wmrards-].wmrards-mega-menu__mobile-toggle:hover {
    background: 0 0
}

@media screen and (max-width:767px) {
    [class*=wmrards-].wmrards-mega-menu {
        padding: .25rem 0;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    [class*=wmrards-].wmrards-mega-menu__mobile-toggle {
        display: block;
        z-index: 100;
        height: 2.25rem;
        min-height: 0;
        padding: 0 1.35rem;
        border-color: #fff;
        color: #fff;
        line-height: normal
    }

    [class*=wmrards-].wmrards-mega-menu__close-icon {
        display: none;
        width: 1.5rem;
        height: 1.5rem;
        fill: #fff
    }

    [class*=wmrards-].wmrards-mega-menu__scroll-controller {
        visibility: hidden;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        padding-top: 4rem;
        -webkit-transition: opacity .15s .1s, -webkit-transform .25s cubic-bezier(.215, .61, .355, 1) .1s;
        transition: opacity .15s .1s, transform .25s cubic-bezier(.215, .61, .355, 1) .1s, -webkit-transform .25s cubic-bezier(.215, .61, .355, 1) .1s;
        opacity: 0
    }

    [class*=wmrards-].wmrards-mega-menu__primary-menu {
        height: 100%;
        padding-bottom: 30vh;
        overflow-x: hidden;
        overflow-y: auto;
        background-color: #fff;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }

    [class*=wmrards-].wmrards-mega-menu__primary-menu .wmrards-mega-menu__primary-menu-item,
    [class*=wmrards-].wmrards-mega-menu__primary-menu .wmrards-mega-menu__sub-menu-item {
        border-bottom: 1px solid #f3f2f1
    }

    [class*=wmrards-].wmrards-mega-menu__container {
        position: absolute;
        top: 4rem;
        left: 100%;
        width: 100%;
        height: calc(100% - 4rem);
        padding-bottom: 30vh;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-transition: opacity .15s .1s, -webkit-transform .25s cubic-bezier(.215, .61, .355, 1) .1s;
        transition: opacity .15s .1s, transform .25s cubic-bezier(.215, .61, .355, 1) .1s, -webkit-transform .25s cubic-bezier(.215, .61, .355, 1) .1s;
        opacity: 0;
        background-color: #fff
    }

    [class*=wmrards-].wmrards-mega-menu__container .wmrards-container {
        padding: 0
    }

    [class*=wmrards-].wmrards-mega-menu__primary-menu-link,
    [class*=wmrards-].wmrards-mega-menu__sub-menu-link {
        font-size: 1.125rem;
        line-height: 1.5rem;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin: 0;
        padding: 1rem;
        text-align: left;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    [class*=wmrards-].wmrards-mega-menu__primary-menu-link .wmrards-n-icon__svg,
    [class*=wmrards-].wmrards-mega-menu__sub-menu-link .wmrards-n-icon__svg {
        width: .84375rem;
        height: calc(.8837590416rem)
    }

    [class*=wmrards-].wmrards-mega-menu__primary-menu-link:focus,
    [class*=wmrards-].wmrards-mega-menu__sub-menu-link:focus {
        -webkit-box-shadow: inset 0 0 0 2px #e5272d, inset 0 0 0 4px #fff;
        box-shadow: inset 0 0 0 2px #e5272d, inset 0 0 0 4px #fff
    }

    [class*=wmrards-].wmrards-mega-menu__primary-menu-link {
        width: 100%
    }

    [class*=wmrards-].wmrards-mega-menu__primary-menu-link .wmrards-mega-menu__link-arrow-icon {
        pointer-events: none
    }

    [class*=wmrards-].wmrards-mega-menu__primary-menu-item {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    [class*=wmrards-].wmrards-mega-menu__primary-menu-item.open {
        z-index: 100
    }

    [class*=wmrards-].wmrards-mega-menu__primary-menu-item.open .wmrards-mega-menu__link-arrow-icon-btn {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        position: absolute;
        z-index: 100;
        top: 0;
        left: 0;
        max-width: 60%;
        height: 4rem;
        border: 0;
        color: #fff;
        background-color: #841922;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    [class*=wmrards-].wmrards-mega-menu__primary-menu-item.open .wmrards-mega-menu__link-arrow-icon-btn::after {
        font-size: 1.125rem;
        line-height: 1.5rem;
        content: attr(data-label);
        margin: 0
    }

    /* [class*=wmrards-].wmrards-mega-menu__primary-menu-item.open .wmrards-mega-menu__link-arrow-icon-btn::after .wmrards-n-icon__svg {
        width: .84375rem;
        height: calc(.8837590416rem)
    } */

    [class*=wmrards-].wmrards-mega-menu__primary-menu-item.open .wmrards-mega-menu__link-arrow-icon-btn .wmrards-mega-menu__link-arrow-icon {
        margin-right: .75rem;
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
        color: currentColor
    }

    [class*=wmrards-].wmrards-mega-menu__primary-menu-item.open .wmrards-mega-menu__container {
        -webkit-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
        transform: translateX(-100%);
        opacity: 1
    }

    [class*=wmrards-].wmrards-mega-menu__link-arrow-icon-btn {
        display: block;
        height: 3.5rem;
        padding: .5rem 1rem;
        border: 0;
        background-color: transparent
    }

    [class*=wmrards-].wmrards-mega-menu__link-arrow-icon {
        display: block;
        width: 1.8rem;
        height: 1.8rem;
        -webkit-transition: -webkit-transform .25s cubic-bezier(.215, .61, .355, 1);
        transition: transform .25s cubic-bezier(.215, .61, .355, 1);
        transition: transform .25s cubic-bezier(.215, .61, .355, 1), -webkit-transform .25s cubic-bezier(.215, .61, .355, 1);
        color: #841922;
        fill: currentColor
    }

    [class*=wmrards-].wmrards-mega-menu__sub-menu-item {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    [class*=wmrards-].wmrards-mega-menu__sub-menu-item .wmrards-mega-menu__sub-menu-link {
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1
    }

    [class*=wmrards-].wmrards-mega-menu__sub-menu-item .wmrards-mega-menu__collapse-toggle {
        display: block;
        height: 3.5rem;
        margin-right: .25rem;
        padding: .5rem 1rem;
        border: 0;
        background-color: transparent
    }

    [class*=wmrards-].wmrards-mega-menu__sub-menu-item .wmrards-mega-menu__link-arrow-icon {
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg)
    }

    [class*=wmrards-].wmrards-mega-menu__sub-menu-item .wmrards-mega-menu__sub-menu-child-menu {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0
    }

    [class*=wmrards-].wmrards-mega-menu__sub-menu-link {
        color: #080607;
        text-decoration: none
    }

    [class*=wmrards-].wmrards-mega-menu__sub-menu-link-icon {
        display: none
    }

    [class*=wmrards-].wmrards-mega-menu__sub-menu-child-menu {
        position: relative;
        max-height: 0;
        margin: 0 1rem;
        overflow: hidden;
        -webkit-transition: max-height .25s cubic-bezier(.215, .61, .355, 1);
        transition: max-height .25s cubic-bezier(.215, .61, .355, 1)
    }

    [class*=wmrards-].wmrards-mega-menu__sub-menu-child-menu::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: calc(100% - .75rem);
        border-left: 1px solid #cecdcd
    }

    [class*=wmrards-].wmrards-mega-menu__collapse-toggle.open+.wmrards-mega-menu__sub-menu-child-menu {
        display: block
    }

    [class*=wmrards-].wmrards-mega-menu__collapse-toggle.open .wmrards-mega-menu__link-arrow-icon {
        -webkit-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        transform: rotate(-90deg)
    }

    [class*=wmrards-].wmrards-mega-menu__sub-menu-child-item:not(:last-child) {
        padding-bottom: 1rem
    }

    [class*=wmrards-].wmrards-mega-menu__sub-menu-child-item:last-child {
        margin-bottom: 1rem
    }

    [class*=wmrards-].wmrards-mega-menu__sub-menu-child-link {
        padding: 0 1rem;
        color: #080607;
        font-size: 1rem;
        text-decoration: none
    }

    [class*=wmrards-].wmrards-mega-menu__search-btn {
        position: relative;
        margin-left: .75rem;
        padding-right: .5rem;
        padding-left: .5rem;
        color: #fff
    }

    [class*=wmrards-].wmrards-mega-menu__search-icon {
        display: block;
        width: 1.5rem;
        height: 1.5rem
    }

    [class*=wmrards-].wmrards-header--mega-menu,
    [class*=wmrards-].wmrards-header--mega-menu [class*=wmrards-].wmrards-mega-menu {
        height: 4rem
    }

    [class*=wmrards-].wmrards-header--mega-menu:not(.wmrards-header--mega-menu-open) .wmrards-mega-menu__scroll-controller {
        overflow: hidden
    }

    [class*=wmrards-].wmrards-header--mega-menu-open .wmrards-mega-menu__scroll-controller {
        visibility: visible;
        opacity: 1
    }

    [class*=wmrards-].wmrards-header--mega-menu-open .wmrards-mega-menu__mobile-toggle {
        padding: 0 .5rem;
        border: 0;
        font-size: 0
    }

    [class*=wmrards-].wmrards-header--mega-menu-open .wmrards-mega-menu__mobile-toggle .wmrards-mega-menu__close-icon {
        display: block
    }

    [class*=wmrards-].wmrards-header--mega-menu-submenu-open .wmrards-header__logo-link {
        display: none
    }
}

[class*=wmrards-].wmrards-header--mega-menu {
    position: relative;
    z-index: 15000;
    padding: 0
}

@media screen and (min-width:768px) {
    [class*=wmrards-] [class*=wmrards-].wmrards-mega-menu::after {
        content: "";
        display: block;
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #841922
    }

    [class*=wmrards-] [class*=wmrards-].wmrards-mega-menu::before {
        content: "";
        visibility: hidden;
        position: fixed;
        z-index: -5;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        -webkit-transition: opacity .2s ease-out;
        transition: opacity .2s ease-out;
        opacity: 0;
        background-color: #000;
        pointer-events: none
    }

    [class*=wmrards-] [class*=wmrards-].wmrards-mega-menu.active::before {
        visibility: visible;
        opacity: .5
    }

    [class*=wmrards-] [class*=wmrards-].wmrards-mega-menu__primary-menu-item.active .wmrards-mega-menu__primary-menu-link {
        background-color: #e5272d
    }

    [class*=wmrards-] [class*=wmrards-].wmrards-mega-menu__primary-menu-item.active .wmrards-mega-menu__container {
        visibility: visible;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
        opacity: 1
    }

    [class*=wmrards-] [class*=wmrards-].wmrards-mega-menu__primary-menu-link {
        min-height: 100%;
        margin: 0;
        padding: 1.25rem 1.5rem;
        -webkit-transition: background-color .2s ease-out;
        transition: background-color .2s ease-out;
        color: #fff;
        font-family: "DM Sans", sans-serif;
        font-size: 1rem;
        font-weight: 700;
        cursor: pointer
    }

    [class*=wmrards-] [class*=wmrards-].wmrards-mega-menu__primary-menu-link:focus,
    [class*=wmrards-] [class*=wmrards-].wmrards-mega-menu__primary-menu-link:hover {
        background-color: #e5272d
    }

    [class*=wmrards-] [class*=wmrards-].wmrards-mega-menu__container {
        display: block;
        position: absolute;
        z-index: -2;
        top: 100%;
        left: 0;
        width: 100%;
        -webkit-transition: opacity .15s .1s, -webkit-transform .25s cubic-bezier(.215, .61, .355, 1) .1s;
        transition: opacity .15s .1s, transform .25s cubic-bezier(.215, .61, .355, 1) .1s, -webkit-transform .25s cubic-bezier(.215, .61, .355, 1) .1s;
        background-color: #fff;
        visibility: hidden;
        -webkit-transform: translateY(-2rem);
        -ms-transform: translateY(-2rem);
        transform: translateY(-2rem);
        opacity: 0
    }

    [class*=wmrards-] [class*=wmrards-].wmrards-mega-menu__sub-menu {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        width: 100%;
        padding: 2.5rem 0;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }

    [class*=wmrards-] [class*=wmrards-].wmrards-mega-menu__sub-menu--has-child-menus {
        padding: 2.5rem 0 1.5rem
    }

    [class*=wmrards-] [class*=wmrards-].wmrards-mega-menu__sub-menu--has-child-menus .wmrards-mega-menu__sub-menu-item {
        margin: 0 0 1rem
    }

    [class*=wmrards-] [class*=wmrards-].wmrards-mega-menu__sub-menu--has-child-menus .wmrards-mega-menu__sub-menu-link {
        position: relative;
        margin-bottom: .75rem;
        padding-left: 2rem;
        color: #841922;
        font-size: 1.125rem;
        font-weight: 700;
        text-decoration: none
    }

    [class*=wmrards-] [class*=wmrards-].wmrards-mega-menu__sub-menu--has-child-menus .wmrards-mega-menu__sub-menu-link-icon {
        display: block
    }

    [class*=wmrards-] [class*=wmrards-].wmrards-mega-menu__sub-menu-item {
        margin-bottom: 0;
        padding-right: 1rem;
        padding-left: 2rem;
        list-style: none;
        -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0
    }

    [class*=wmrards-] [class*=wmrards-].wmrards-mega-menu__sub-menu-item:not(:nth-child(4n)) {
        border-right: 1px solid #cecdcd
    }

    [class*=wmrards-] [class*=wmrards-].wmrards-mega-menu__sub-menu-link-icon {
        display: none;
        position: absolute;
        top: .6em;
        left: 0;
        width: 1em;
        height: 1em;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        color: inherit;
        fill: currentColor
    }

    [class*=wmrards-] [class*=wmrards-].wmrards-mega-menu__sub-menu-child-menu {
        padding-left: 2rem
    }

    [class*=wmrards-] [class*=wmrards-].wmrards-mega-menu__sub-menu-child-link,
    [class*=wmrards-] [class*=wmrards-].wmrards-mega-menu__sub-menu-link {
        display: block;
        margin-bottom: .75rem;
        color: #080607;
        text-decoration: none
    }

    [class*=wmrards-] [class*=wmrards-].wmrards-mega-menu__sub-menu-child-link:focus,
    [class*=wmrards-] [class*=wmrards-].wmrards-mega-menu__sub-menu-child-link:hover,
    [class*=wmrards-] [class*=wmrards-].wmrards-mega-menu__sub-menu-link:focus,
    [class*=wmrards-] [class*=wmrards-].wmrards-mega-menu__sub-menu-link:hover {
        text-decoration: underline
    }
}

[class*=wmrards-].wmrards-search-container {
    margin: 0 auto;
    padding: 1rem
}

[class*=wmrards-].wmrards-search-container .wmrards-search-heading {
    margin: 2rem 0;
    font-family: "DM Sans", sans-serif;
    font-weight: 700
}

[class*=wmrards-].wmrards-search-container .wmrards-search-list {
    margin: 0;
    padding: 0
}

[class*=wmrards-].wmrards-search-container .wmrards-search-list__item {
    display: block;
    margin-bottom: .5rem;
    list-style: none
}

[class*=wmrards-].wmrards-search-container .wmrards-link__chevron {
    fill: #e5272d
}

@media screen and (max-width:767px) {
    [class*=wmrards-] [class*=wmrards-].wmrards-mega-menu__search-container {
        visibility: hidden;
        position: absolute;
        top: 100%;
        left: 0;
        overflow: visible;
        opacity: 0
    }

    [class*=wmrards-] [class*=wmrards-].wmrards-header--search-open {
        position: fixed;
        top: 0;
        width: 100%
    }

    [class*=wmrards-] [class*=wmrards-].wmrards-header--search-open .wmrards-mega-menu__search-container {
        visibility: visible;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
        opacity: 1
    }

    [class*=wmrards-] [class*=wmrards-].wmrards-header--search-open .wmrards-mega-menu__search-icon {
        display: none
    }

    [class*=wmrards-] [class*=wmrards-].wmrards-header--search-open .wmrards-mega-menu__search-close-icon {
        display: block
    }

    [class*=wmrards-].wmrards-search-container {
        max-height: calc(100vh - 4rem);
        overflow-y: auto;
        color: #fff;
        background-color: #841922
    }

    [class*=wmrards-].wmrards-search-container [class*=wmrards-].wmrards-search-heading {
        color: currentColor
    }

    [class*=wmrards-].wmrards-search-container [class*=wmrards-].wmrards-search-bar__input {
        border: 0
    }

    [class*=wmrards-].wmrards-search-container [class*=wmrards-].wmrards-search-bar__btn {
        background-color: #e5272d
    }

    [class*=wmrards-].wmrards-search-container [class*=wmrards-].wmrards-link {
        color: #fff
    }
}

@media screen and (min-width:568px) {
    [class*=wmrards-].wmrards-search-container [class*=wmrards-].wmrards-search-list {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }

    [class*=wmrards-].wmrards-search-container [class*=wmrards-].wmrards-search-list__item {
        padding-right: 1em
    }
}

[class*=wmrards-].wmrards-swift-icon {
    display: inline-block;
    width: 3.5em;
    height: 1.35em;
    margin-right: .25em;
    margin-left: .15em;
    color: inherit;
    fill: currentColor;
    line-height: 1.5rem;
    vertical-align: top
}

@media screen and (min-width:768px) {
    [class*=wmrards-].wmrards-search-container {
        max-width: 40rem;
        padding: 2rem 1rem
    }

    [class*=wmrards-].wmrards-search-container [class*=wmrards-].wmrards-link {
        color: #841922;
        text-decoration: none
    }

    [class*=wmrards-].wmrards-search-container [class*=wmrards-].wmrards-link:hover {
        color: #841922;
        text-decoration: underline
    }

    [class*=wmrards-].wmrards-swift-icon {
        width: 3.2em
    }
}

[class*=wmrards-] .demo-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 50vh;
    min-height: 400px;
    background-color: #fad4d5;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

[class*=wmrards-].wmrards-website-code-example .wmrards-mega-menu::before {
    position: absolute
}

[class*=wmrards-].wmrards-search-bar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%
}

[class*=wmrards-].wmrards-search-bar .wmrards-fe-input {
    margin: 0
}

[class*=wmrards-].wmrards-search-bar__btn {
    width: 50px;
    height: 50px;
    padding: .75rem;
    border: 0;
    color: #fff;
    background-color: #841922
}

[class*=wmrards-].wmrards-search-bar__btn svg {
    width: 100%;
    height: 100%;
    fill: currentColor
}

[class*=wmrards-].wmrards-search-filter__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

[class*=wmrards-].wmrards-search-filter__header-title {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1
}

[class*=wmrards-].wmrards-search-filter__close {
    display: block;
    margin-left: 1rem
}

[class*=wmrards-].wmrards-search-filter__close svg {
    display: block;
    width: 2rem;
    height: 2rem;
    fill: #841922
}

[class*=wmrards-].wmrards-search-filter .wmrards-accordion {
    position: relative
}

[class*=wmrards-].wmrards-search-filter .wmrards-accordion+.wmrards-accordion::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    width: calc(100% - 2rem);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    border-top: 1px solid rgba(0, 0, 0, .4)
}

[class*=wmrards-].wmrards-search-filter .wmrards-accordion__content {
    padding-top: .5rem
}

@media screen and (max-width:767px) {
    [class*=wmrards-].wmrards-search-filter {
        visibility: hidden;
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding-bottom: 8rem;
        overflow: auto;
        -webkit-transition: opacity .2s ease-out;
        transition: opacity .2s ease-out;
        opacity: 0;
        background-color: #fff
    }

    [class*=wmrards-].wmrards-search-filter--is-open {
        visibility: visible;
        opacity: 1
    }

    [class*=wmrards-].wmrards-search-filter__header {
        padding: 1rem
    }

    [class*=wmrards-].wmrards-search-filter__header-title {
        font-size: 1.7rem;
        line-height: 1.89125rem;
        margin: 0;
        color: #841922
    }

    [class*=wmrards-].wmrards-search-filter__header-title .wmrards-n-icon__svg {
        width: 1.275rem;
        height: calc(1.3354581073rem)
    }

    [class*=wmrards-].wmrards-search-filter .wmrards-accordion__summary .wmrards-accordion__summary-title {
        margin-bottom: .75rem;
        font-size: 1.4375rem;
        line-height: 1.5rem;
        margin-top: 0
    }

    [class*=wmrards-].wmrards-search-filter .wmrards-accordion__summary .wmrards-accordion__summary-title .wmrards-n-icon__svg {
        width: 1.078125rem;
        height: calc(1.1292476643rem)
    }

    [class*=wmrards-].wmrards-search-filter .wmrards-accordion::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        width: calc(100% - 2rem);
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        border-top: 1px solid rgba(0, 0, 0, .4)
    }

    [class*=wmrards-].wmrards-search-filter__mobile-filter-update {
        visibility: hidden;
        position: fixed;
        z-index: 10;
        bottom: 0;
        width: 100%;
        padding: 1rem;
        -webkit-transition: opacity .2s ease-out;
        transition: opacity .2s ease-out;
        border-top: 1px solid rgba(0, 0, 0, .4);
        opacity: 0;
        background-color: #fff
    }

    [class*=wmrards-].wmrards-search-filter--has-inputs-checked .wmrards-search-filter__mobile-filter-update {
        visibility: visible;
        opacity: 1
    }
}

@media screen and (min-width:768px) {
    [class*=wmrards-].wmrards-search-filter {
        border-bottom: 5px solid #841922
    }

    [class*=wmrards-].wmrards-search-filter__close,
    [class*=wmrards-].wmrards-search-filter__mobile-filter-update {
        display: none
    }

    [class*=wmrards-].wmrards-search-filter .wmrards-search-filter__clear-all {
        display: none;
        position: relative;
        padding: 1rem;
        color: #841922;
        background-color: #fff;
        text-decoration: underline;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    [class*=wmrards-].wmrards-search-filter .wmrards-search-filter__clear-all::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        width: calc(100% - 2rem);
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        border-top: 1px solid rgba(0, 0, 0, .4);
        bottom: 100%
    }

    [class*=wmrards-].wmrards-search-filter .wmrards-search-filter__clear-all svg {
        display: block;
        width: 1rem;
        height: 1rem;
        margin-right: .75rem
    }

    [class*=wmrards-].wmrards-search-filter--has-inputs-checked .wmrards-search-filter__clear-all.wmrards-hide-mobile {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
    }
}

[class*=wmrards-].wmrards-search-result {
    border-bottom: 1px solid #6b6a6a
}

[class*=wmrards-].wmrards-search-result__title {
    color: #1a6fac
}

[class*=wmrards-].wmrards-search-result .wmrards-breadcrumb {
    padding: .5rem 0
}

[class*=wmrards-].wmrards-search-result .wmrards-breadcrumb__list-item {
    margin-bottom: 0
}

[class*=wmrards-].wmrards-search-result__excerpt {
    padding-top: 1rem
}

[class*=wmrards-].wmrards-search-sort {
    margin-bottom: 1rem
}

[class*=wmrards-].wmrards-search-sort .wmrards-fe-label {
    margin-bottom: .75rem
}

@media screen and (max-width:767px) {
    [class*=wmrards-].wmrards-search-sort {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }

    [class*=wmrards-].wmrards-search-sort .wmrards-fe-label,
    [class*=wmrards-].wmrards-search-sort .wmrards-fe-label h3 {
        margin: 0
    }

    [class*=wmrards-].wmrards-search-sort .wmrards-fe-dropdown {
        margin-left: 1rem;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1
    }
}

[class*=wmrards-].wmrards-pagination {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

[class*=wmrards-].wmrards-pagination__nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 1rem;
    padding: 0;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%
}

[class*=wmrards-].wmrards-pagination__item {
    display: none;
    margin: 0;
    padding: 0 .5rem;
    list-style: none
}

[class*=wmrards-].wmrards-pagination__item:first-child,
[class*=wmrards-].wmrards-pagination__item:last-child {
    display: block
}

[class*=wmrards-].wmrards-pagination__item--previous {
    display: block;
    padding-left: .25rem
}

[class*=wmrards-].wmrards-pagination__item--previous:not(:first-child)::before {
    content: "...";
    margin-right: .75rem
}

[class*=wmrards-].wmrards-pagination__item--active {
    display: block
}

[class*=wmrards-].wmrards-pagination__item--active+.wmrards-pagination__item:not(:last-child) {
    display: block;
    padding-right: .25rem
}

[class*=wmrards-].wmrards-pagination__item--active+.wmrards-pagination__item:not(:last-child)::after {
    content: "...";
    margin-left: .75rem
}

[class*=wmrards-].wmrards-pagination__next {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1
}

@media screen and (min-width:768px) {
    [class*=wmrards-].wmrards-pagination__nav {
        margin: 0;
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 46%;
        flex: 1 1 46%
    }

    [class*=wmrards-].wmrards-pagination__next {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2
    }

    [class*=wmrards-].wmrards-pagination__next,
    [class*=wmrards-].wmrards-pagination__prev {
        -webkit-box-flex: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
        -ms-flex-preferred-size: 27%;
        flex-basis: 27%
    }

    [class*=wmrards-].wmrards-pagination__item--active:first-child {
        margin-left: 36.5%
    }

    [class*=wmrards-].wmrards-pagination__item--active:last-child {
        margin-right: 36.5%
    }
}

[class*=wmrards-].wmrards-travel-update {
    padding: .5rem 0;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

[class*=wmrards-].wmrards-travel-update__disruption {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

@media screen and (min-width:568px) {
    [class*=wmrards-].wmrards-travel-update__disruption {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between
    }
}

[class*=wmrards-].wmrards-travel-update__disruption .wmrards-disruption-indicator-medium {
    margin-right: 1rem
}

[class*=wmrards-].wmrards-travel-update__disruption .wmrards-travel-update__disruption-text {
    padding-top: .5rem;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%
}

@media screen and (min-width:392px) {
    [class*=wmrards-].wmrards-travel-update__disruption .wmrards-travel-update__disruption-text {
        padding-top: 0;
        text-align: right;
        -webkit-box-flex: 1;
        -ms-flex: auto;
        flex: auto
    }
}

[class*=wmrards-].wmrards-travel-update--personal {
    position: relative
}

[class*=wmrards-].wmrards-travel-update--personal .wmrards-travel-update__disruption-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 1rem;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%
}

[class*=wmrards-].wmrards-travel-update--personal .wmrards-travel-update__disruption-title .wmrards-travel-update__disruption-detail-toggle {
    padding: 0;
    border: 0;
    background: 0 0
}

[class*=wmrards-].wmrards-travel-update--personal .wmrards-travel-update__disruption-title .wmrards-travel-update__disruption-detail-toggle svg {
    display: block;
    width: 2rem;
    height: 2rem;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    fill: #841922
}

[class*=wmrards-].wmrards-travel-update--personal .wmrards-travel-update__disruption-title .wmrards-travel-update__disruption-detail-toggle[data-show-details=false] svg {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg)
}

[class*=wmrards-].wmrards-travel-update--personal .wmrards-travel-update__disruption {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

[class*=wmrards-].wmrards-travel-update--personal .wmrards-travel-update__disruption:not(:last-child) {
    margin-bottom: 1rem
}

[class*=wmrards-].wmrards-travel-update--personal .wmrards-travel-update__disruption .wmrards-travel-update__disruption-indicator-btn {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-right: 1rem;
    padding: 0;
    border: 0;
    background: 0 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}

[class*=wmrards-].wmrards-travel-update--personal .wmrards-travel-update__disruption .wmrards-travel-update__disruption-indicator-btn .wmrards-disruption-indicator-medium {
    margin: 0
}

[class*=wmrards-].wmrards-travel-update--personal .wmrards-travel-update__disruption .wmrards-travel-update__disruption-text {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    text-align: left;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}

[class*=wmrards-].wmrards-travel-update--personal .wmrards-travel-update__disruption .wmrards-travel-update__disruption-text strong {
    display: block
}

@media screen and (max-width:567px) {
    [class*=wmrards-].wmrards-travel-update--personal {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start
    }

    [class*=wmrards-].wmrards-travel-update--personal .wmrards-travel-update__disruption-detail-toggle[data-collapse-all=true] svg {
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg)
    }

    [class*=wmrards-].wmrards-travel-update--personal .wmrards-travel-update__disruption {
        display: block
    }

    [class*=wmrards-].wmrards-travel-update--personal .wmrards-travel-update__disruption.is-expanded {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%
    }

    [class*=wmrards-].wmrards-travel-update--personal .wmrards-travel-update__disruption-indicator-btn .wmrards-travel-update__disruption-text {
        padding-top: .75rem
    }

    [class*=wmrards-].wmrards-travel-update--personal .wmrards-travel-update__disruption-indicator-btn[aria-expanded=false]~.wmrards-travel-update__disruption-text {
        display: none
    }
}

@media screen and (min-width:568px) {
    [class*=wmrards-].wmrards-travel-update--personal .wmrards-travel-update__disruption-detail-toggle {
        display: none
    }
}

@media screen and (max-width:567px) {
    [class*=wmrards-].wmrards-travel-update--edit .wmrards-travel-update__disruption {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        width: 100%;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }

    [class*=wmrards-].wmrards-travel-update--edit .wmrards-travel-update__disruption-text {
        display: block;
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1
    }
}

[class*=wmrards-].wmrards-travel-update--edit .wmrards-travel-update__disruption-delete {
    width: 2rem;
    height: 2rem;
    border: 0;
    border-radius: 5px;
    background-color: #d4351c;
    cursor: pointer
}

[class*=wmrards-].wmrards-travel-update--edit .wmrards-travel-update__disruption-delete svg {
    width: 100%;
    height: 100%;
    fill: #fff;
    vertical-align: middle
}

[class*=wmrards-].wmrards-travel-mode-page-banner {
    position: relative;
    min-height: 250px;
    color: #fff;
    background-color: #e5272d
}

[class*=wmrards-].wmrards-travel-mode-page-banner .wmrards-travel-mode-page-banner__svg-background {
    fill: #e5272d
}

[class*=wmrards-].wmrards-travel-mode-page-banner__copy {
    max-width: 100%
}

[class*=wmrards-].wmrards-travel-mode-page-banner__copy>* {
    color: #fff
}

[class*=wmrards-].wmrards-travel-mode-page-banner__logos {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

[class*=wmrards-].wmrards-travel-mode-page-banner__logos svg {
    width: 3rem;
    fill: #fff
}

[class*=wmrards-].wmrards-travel-mode-page-banner__logos img,
[class*=wmrards-].wmrards-travel-mode-page-banner__logos svg {
    height: 3rem
}

[class*=wmrards-].wmrards-travel-mode-page-banner__logos img:not(:last-child),
[class*=wmrards-].wmrards-travel-mode-page-banner__logos svg:not(:last-child) {
    margin-right: 2rem
}

[class*=wmrards-].wmrards-travel-mode-page-banner__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 2rem 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

@media screen and (min-width:768px) {
    [class*=wmrards-].wmrards-travel-mode-page-banner__content {
        width: 50%;
        min-height: 300px
    }

    [class*=wmrards-].wmrards-travel-mode-page-banner__image {
        position: absolute;
        z-index: 0;
        top: 0;
        right: 0;
        width: 50%;
        height: 100%;
        overflow: hidden;
        /* background-image: url(/img/component-images/banner/ring-and-ride-banner.png); */
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: cover
    }

    [class*=wmrards-].wmrards-travel-mode-page-banner__svg-background {
        position: absolute;
        z-index: 2;
        top: 0;
        left: -3px;
        height: 100%
    }
}

@media all and (-ms-high-contrast:none) {
    [class*=wmrards-].wmrards-travel-mode-page-banner .wmrards-travel-mode-page-banner__svg-background {
        left: calc(-50% + 30px)
    }
}